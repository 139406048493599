import React, { createContext, useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import ReactGA4 from 'react-ga4';

const GA_LOCAL_STORAGE_KEY = 'ga:clientId';
const AnalyticContext = createContext();

const AnalyticsProvider = ({
  trackingCode,
  isStorageMode,
  customStorage,
  storageKey,
  isNativeApp,
  children,
}) => {
  useEffect(() => {
    const applicationType = isNativeApp ? 'Mobile Application' : 'Website';
    const _storageKey = `${GA_LOCAL_STORAGE_KEY}:${storageKey}`;

    if (trackingCode) {
      // In some platform we cannot use cookie for Google Analytics,
      // so we use customStorage or localStorage instead.
      if (isStorageMode) {
        const initialGAinStorageMode = (_clientId, _storage, _applicationType) => {
          ReactGA4.initialize(trackingCode, {
            gaOptions: {
              storage: 'none',
              clientId: typeof _storageKey === 'string' ? _storageKey : undefined,
            },
          });

          // checkProtocolTask: don't abort if the protocol is not http(s)
          // checkStorageTask: don't expect cookies to be enabled
          ReactGA4.ga('set', { checkProtocolTask: null, checkStorageTask: null });
          ReactGA4.ga(tracker => storage.setItem(_storage, tracker.get('clientId')));
          ReactGA4.set({ dimension1: _applicationType }); // dimension index 1 is 'Application Type'
        };

        const storage = customStorage || localStorage;
        const clientId = storage.getItem(_storageKey);
        if (clientId instanceof Promise) {
          clientId.then(id => initialGAinStorageMode(id, storage, applicationType));
        } else {
          initialGAinStorageMode(clientId, storage, applicationType);
        }
        ReactGA4.send('pageview'); //1st page view
        return;
      }

      ReactGA4.initialize(trackingCode);
      ReactGA4.set({ dimension1: applicationType }); // dimension index 1 is 'Application Type'
      ReactGA4.send('pageview'); //1st page view
      return;
    }
    console.error('Using Analytics with no tracking code');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendEvent = useCallback(
    (eventName, eventParameter) => ReactGA4.gtag('event', eventName, eventParameter),
    [],
  );

  const setProperty = useCallback(
    (propertyName, propertyParameter) => ReactGA4.gtag('set', propertyName, propertyParameter),
    [],
  );

  return (
    <AnalyticContext.Provider value={{ ga4: ReactGA4.gtag, send: sendEvent, set: setProperty }}>
      {children}
    </AnalyticContext.Provider>
  );
};

AnalyticsProvider.propTypes = {
  trackingCode: PropTypes.string.isRequired,
  isStorageMode: PropTypes.bool,
  customStorage: PropTypes.object,
  storageKey: PropTypes.string,
  isNativeApp: PropTypes.bool,
  children: PropTypes.node,
};

const useAnalytics = () => {
  const context = useContext(AnalyticContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }
  return context;
};

const withAnalytics = WrappedComponent => {
  const HOC = props => {
    const context = useAnalytics();
    return <WrappedComponent {...props} analytics={context} />;
  };
  hoistNonReactStatics(HOC, WrappedComponent);
  return HOC;
};

export { AnalyticsProvider, useAnalytics, withAnalytics };
export default AnalyticsProvider;
