import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

import ConfigConsumer from './ConfigConsumer';

const withConfig = WrappedComponent => {
  const HOC = props => {
    const { forwardedRef, ...restProps } = props;
    return (
      <ConfigConsumer>
        {configProps => (
          <WrappedComponent {...restProps} appConfig={configProps} ref={forwardedRef} />
        )}
      </ConfigConsumer>
    );
  };

  hoistNonReactStatics(HOC, WrappedComponent);

  return HOC;
};

export { withConfig };
