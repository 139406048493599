import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { withAnalytic } from '@skooldio/analytics-provider/dist/Analytics/hoc';

import { Icon, ICON_LIST, BackdropProgress } from 'Components/Base';
import ButtonOutlinedIcon from 'Components/ButtonOutlinedIcon';
import ExportCourseCodeReportData from 'Domains/ExportCourseCodeReportData';
import { useAnalytics } from 'Libs/Analytics';
import * as mixpanel from 'config/mixpanel';

const SchoolSummaryExporter = ({
  disabled,
  appliedFilter,
  schoolCode,
  onLogAction,
  myProfileId,
}) => {
  const { send } = useAnalytics();
  const { t } = useTranslation('summary');
  const [loading, setLoading] = useState(false);

  const {
    term: { id: schoolTermId } = {},
    grade: { id: schoolGradeId } = {},
    classNo: { classNo } = {},
    subject: { id: subjectId } = {},
    syllabus: { courseCode } = {},
  } = appliedFilter;

  return (
    <ExportCourseCodeReportData
      schoolTermId={schoolTermId}
      schoolGradeId={schoolGradeId}
      classNo={classNo}
      subjectId={subjectId}
      courseCode={courseCode}
      schoolCode={schoolCode}
    >
      {({ fetchCourseReportData, exportCourseCodeReport }) => {
        return (
          <Fragment>
            <BackdropProgress open={loading} />
            <ButtonOutlinedIcon
              leftIcon={<Icon name={ICON_LIST.EXPORT} />}
              lineHeight={'0.5'}
              textProp={{
                fontWeight: 'semibold',
              }}
              height={'40px'}
              message={t('EXPORT')}
              disabled={disabled || loading}
              onClick={async () => {
                onLogAction({
                  category: 'Export',
                  action: `Click`,
                  label: `Click on course code page`,
                });
                send('export', { on: 'course_code_page' });
                mixpanel.trackEvent('Course Room Export File Click', {
                  distinct_id: myProfileId,
                });
                setLoading(true);
                const { data, error } = await fetchCourseReportData();
                setLoading(false);
                if (!error) {
                  await exportCourseCodeReport({
                    reports: data.foCourseReport.reports,
                    type: 'xlsx',
                  });
                }
              }}
            />
          </Fragment>
        );
      }}
    </ExportCourseCodeReportData>
  );
};

export default withAnalytic(SchoolSummaryExporter);
