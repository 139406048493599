import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text, Flex } from '../Base';
import ButtonOutlined from '../ButtonOutlined';

const ButtonStyle = styled(ButtonOutlined)`
  justify-content: center;
  align-items: center;
`;

const ButtonOutlinedIcon = ({ leftIcon, message, rightIcon, textProp, ...props }) => (
  <ButtonStyle display={'flex'} {...props}>
    {leftIcon && <Flex mr={2}>{leftIcon}</Flex>}
    <Text {...textProp}>{message}</Text>
    {rightIcon && <Flex ml={2}>{rightIcon}</Flex>}
  </ButtonStyle>
);

ButtonOutlinedIcon.propTypes = {
  leftIcon: PropTypes.element,
  message: PropTypes.string,
  rightIcon: PropTypes.element,
};

export default ButtonOutlinedIcon;
