import { useQuery } from '@apollo/react-hooks';

import { getCourseSectionReport } from 'GraphQL/queries/report.query';

const CourseSectionReportData = ({ value, schoolCode, children }) => {
  const { term, grade, subject, syllabus } = value;

  const { loading, error, data, refetch } = useQuery(getCourseSectionReport, {
    variables: {
      schoolTermId: term,
      schoolGradeId: grade,
      subjectId: subject,
      courseCode: syllabus,
      schoolCode,
    },
    skip: !term || !grade || !subject || !syllabus || !schoolCode,
  });

  return children({ loading, error, data, refetch });
};

export default CourseSectionReportData;
