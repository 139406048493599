import { useQuery } from '@apollo/react-hooks';

import { getStudentSubjectsByUserId } from 'GraphQL/queries/modules.query';

const StudentSubjectsData = props => {
  const { userId, gradeId, schoolTermId, children } = props;
  const { loading, error, data } = useQuery(getStudentSubjectsByUserId, {
    variables: {
      userId,
      gradeId,
      schoolTermId,
    },
  });
  return children({ loading, error, data });
};

export default StudentSubjectsData;
