import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';

import { Flex } from 'Components/Base';

const BarWrapper = styled(Flex)`
  padding-left: 4px;
  white-space: nowrap;
  overflow: visible;
  align-items: center;
`;

const THRESHOLD_1 = 80;
const THRESHOLD_2 = 60;

const ProgressBar = ({ progress, thresholdType = 1 }) => {
  const theme = useContext(ThemeContext);

  const getColor = () => {
    if (progress >= THRESHOLD_1) {
      return theme.colors.progressBarPassBg;
    } else if (thresholdType === 2 && progress >= THRESHOLD_2) {
      return theme.colors.warning;
    } else {
      return theme.colors.progressBarFailBg;
    }
  };

  return (
    <Flex height={20} style={{ border: '1px solid black' }}>
      {progress > 0 ? (
        <BarWrapper width={`${progress}%`} backgroundColor={getColor()}>
          {progress} %
        </BarWrapper>
      ) : (
        <Flex style={{ marginLeft: '4px' }}>{progress} %</Flex>
      )}
    </Flex>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  thresholdType: PropTypes.oneOfType(1 | 2),
};

export default ProgressBar;
