import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';

import { Flex, Box, Text, Image } from 'Components/Base';
import Breadcrumb from 'Components/Breadcrumb';
import Loading from 'Components/Loading';
import { getFilterData, convertToBreadCrumb } from 'Containers/AttendanceFilter';
import IndividualUnitReportData from 'Domains/IndividualUnitReportData';
import AttendanceFilterData from 'Domains/AttendanceFilterData';
import Criteria from 'Components/Criteria';

import IndividualUnitAttendanceTable from './IndividualUnitAttendanceTable';

const SchoolUnitReportTableWrapper = styled(Box)`
  position: relative;
  overflow: auto;
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: visible;
  }
`;

const useQueryString = () => {
  return new URLSearchParams(useLocation().search);
};

const IndividualUnitAttendanceTableView = ({
  currentFilter,
  data,
  translation,
  setDisabledFetch,
}) => {
  if (!_.isEmpty(currentFilter)) {
    setDisabledFetch(false);
    if (!_.isEmpty(data)) {
      return <IndividualUnitAttendanceTable data={data.foIndividualUnitReport.reports} />;
    } else {
      return (
        <IndividualUnitAttendanceTable
          data={[]}
          renderEmpty={({ colSpan }) => (
            <tr>
              <td colSpan={colSpan}>
                <Box bg={'tableRowBg'} py={9}>
                  <Text style={{ textAlign: 'center' }}>{translation('NOT_FOUND')}</Text>
                </Box>
              </td>
            </tr>
          )}
        />
      );
    }
  } else {
    return (
      <IndividualUnitAttendanceTable
        data={[]}
        renderEmpty={({ colSpan }) => (
          <tr>
            <td colSpan={colSpan}>
              <Box bg={'tableRowBg'}>
                <Flex flexDirection={'column'} py={9}>
                  <Flex mb={3} mx={'auto'}>
                    <Text>{translation('SELECT_BUTTON')}</Text>
                  </Flex>
                  <Flex mb={3} mx={'auto'}>
                    <Image
                      maxHeight={'40px'}
                      src={'/assets/components/Icons/filter-button-icon.png'}
                      srcSet={
                        '/assets/components/Icons/filter-button-icon.png 1x, /assets/components/Icons/filter-button-icon@2x.png 2x, /assets/components/Icons/filter-button-icon@3x.png 3x'
                      }
                    />
                  </Flex>
                  <Flex mb={3} mx={'auto'}>
                    <Text>{translation('TO_DISPLAYED')}</Text>
                  </Flex>
                </Flex>
              </Box>
            </td>
          </tr>
        )}
      />
    );
  }
};

const IndividualUnit = ({
  isFetchData,
  setIsFetchData,
  setDisabledFetch,
  onFilterApplied,
  schoolCode,
  username,
}) => {
  const { t } = useTranslation('individualUnit');
  const queryString = useQueryString();
  const [currentFilter, setCurrentFilter] = useState({});
  const [isFirstTime, setIsFirstTime] = useState(true);

  const filterValue = {
    term: currentFilter?.term?.id ?? queryString.get('term') ?? null,
    grade: currentFilter?.grade?.id ?? queryString.get('grade') ?? null,
    classNo: currentFilter?.classNo?.classNo ?? queryString.get('class') ?? null,
    subject: currentFilter?.subject?.id ?? queryString.get('subj') ?? null,
    syllabus: currentFilter?.syllabus?.courseCode ?? queryString.get('course') ?? null,
  };

  return (
    <>
      <Box>
        <AttendanceFilterData initialValue={filterValue} schoolCode={schoolCode}>
          {({ list, value, updateValue }) => {
            const { term, grade, classNo, subject, syllabus } = value;
            const { termList, gradeList, classList, subjectList, syllabusList, lessonList } = list;
            if (queryString.toString() !== '' && isFirstTime) {
              const filterData = getFilterData(list, value);

              if (termList && gradeList && classList && subjectList && syllabusList && lessonList) {
                if (term && grade && classNo && subject && syllabus) {
                  onFilterApplied(filterData, filterValue);
                  setCurrentFilter(filterData);
                } else if (term && grade && classNo && subject && syllabus) {
                  onFilterApplied(filterData, filterValue);
                  setCurrentFilter(filterData);
                }
                setIsFirstTime(false);
              }
            }

            return (
              <>
                <Text.Title>{t('STUDENT_INFO')}</Text.Title>
                <Breadcrumb
                  maxItemsPerRow={6}
                  items={convertToBreadCrumb(currentFilter)}
                  p={0}
                  my={2}
                />
                <Flex mt={5} alignItems={'center'}>
                  <Criteria
                    title={t('QUIZ_CRITERIA')}
                    values={[
                      { text: t('GOOD'), color: 'success' },
                      { text: t('NORMAL'), color: 'warning' },
                      { text: t('BAD'), color: 'dangerAlt' },
                    ]}
                  />
                </Flex>
              </>
            );
          }}
        </AttendanceFilterData>
      </Box>
      <SchoolUnitReportTableWrapper>
        <IndividualUnitReportData value={filterValue} schoolCode={schoolCode} username={username}>
          {({ loading, error, data, refetch }) => {
            if (loading) return <Loading />;
            if (error) return null;
            if (isFetchData) {
              refetch();
              setIsFetchData(false);
            }
            return (
              <IndividualUnitAttendanceTableView
                currentFilter={currentFilter}
                data={data}
                translation={t}
                setDisabledFetch={setDisabledFetch}
              />
            );
          }}
        </IndividualUnitReportData>
      </SchoolUnitReportTableWrapper>
    </>
  );
};

IndividualUnit.defaultProps = {
  onFilterApplied: {},
};

export default IndividualUnit;
