import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import UserItem from 'Containers/UserItem';
import BreadcrumbLayout from 'Components/BreadcrumbLayout';
import Breadcrumb from 'Components/Breadcrumb';
import UserModule from 'Containers/UserModule';
import TabContent from 'Components/TabContent';
import ToolbarContainer from 'Components/ToolbarContainer';
import LSPUserData from 'Domains/LSPUserData';

const BreadcrumbComponent = memo(function BreadcrumbComponent({ userId, userCode }) {
  const items = [
    {
      key: 'module',
      title: 'Module',
      path: '/teacher/modules',
    },
    {
      key: userId,
      title: userCode,
      path: `/teacher/modules/${userId}`,
    },
  ];
  return <Breadcrumb items={items} variant={'dark'} />;
});

const TeacherStudentModulePage = props => {
  const { studentId: userId } = useParams();

  return (
    <LSPUserData userId={userId}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return null;
        const {
          foProfile: { code },
        } = data;
        return (
          <BreadcrumbLayout
            breadcrumbComponent={() => <BreadcrumbComponent userId={userId} userCode={code} />}
          >
            <TabContent
              content={
                <ToolbarContainer
                  topToolbar={<UserItem userId={userId} />}
                  topToolbarProps={{ p: 0 }}
                  content={<UserModule userId={userId} />}
                  contentProps={{ p: 0 }}
                />
              }
            />
          </BreadcrumbLayout>
        );
      }}
    </LSPUserData>
  );
};

export default memo(TeacherStudentModulePage);
