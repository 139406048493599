import React from 'react';
import PropTypes from 'prop-types';

import { Box, Text, Flex, ListItem } from 'Components/Base';

export const Element = ({ alphabet, color, percent, name }) => {
  return (
    <ListItem
      icon={
        <Box
          width={34}
          height={34}
          backgroundColor={color}
          style={{ borderRadius: '6px', padding: '7px', textAlign: 'center' }}
        >
          <Text.Title color="white">{alphabet}</Text.Title>
        </Box>
      }
      variant="horizontal"
      containerProps={{ style: { margin: '0px', padding: '0px' } }}
      iconContainerProps={{ style: { marginRight: '8px' } }}
    >
      <Text fontWeight="bold">{percent}%</Text>
      <Text>{name}</Text>
    </ListItem>
  );
};

Element.propTypes = {
  alphabet: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  percent: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const ElementList = ({ data }) => {
  return (
    <Flex justifyContent="center" alignItems="center">
      <Box mr={{ _: 2, md: 5 }}>
        {data.slice(0, 3).map(({ alphabet, color, percent, name }, index) => (
          <Box mb={index !== 2 ? 5 : 0} key={index}>
            <Element alphabet={alphabet} color={color} percent={percent} name={name} />
          </Box>
        ))}
      </Box>
      <Box flexDirection="column" justifyContent="space-around">
        {data.slice(3, 6).map(({ alphabet, color, percent, name }, index) => (
          <Box mb={index !== 2 ? 5 : 0} key={index}>
            <Element alphabet={alphabet} color={color} percent={percent} name={name} />
          </Box>
        ))}
      </Box>
    </Flex>
  );
};

ElementList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ElementList;
