import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

import { List, LIST_TYPE } from '../Base';
import Profile from '../Profile';

const ProfileList = ({ dataList, profileProps }) => {
  const theme = useContext(ThemeContext);

  return (
    <List
      items={dataList}
      renderItem={({ imageSrc, name, description, ...restProps }, index) => (
        <Profile
          key={`${name}_${index}`}
          imageSrc={imageSrc}
          userDisplayName={name}
          userTitle={description}
          {...restProps}
          {...profileProps}
        />
      )}
      columns={[]}
      columnSpacing={`${theme.space[0]}px`}
      rowSpacing={`${theme.space[2]}px`}
      style={{ justifyContent: 'space-around', margin: '0px' }}
      listType={LIST_TYPE.HORIZONTAL_LIST}
    />
  );
};

ProfileList.propTypes = {
  dataList: PropTypes.array.isRequired,
  profileProps: PropTypes.object,
};

ProfileList.defaultProps = {
  profileProps: {},
};

export default ProfileList;
