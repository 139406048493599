export const MODULE_STATUS = {
  INCOMPLETED: 'INCOMPLETED',
  COMPLETED: 'COMPLETED',
};

export const MODULE_STATUS_TH = {
  INCOMPLETED: 'เข้าเรียนไม่ครบ',
  COMPLETED: 'เข้าเรียนครบ',
};

export const QUIZ_MODULE_STATUS_TH = {
  INCOMPLETED: 'เข้าสอบไม่ครบ',
  COMPLETED: 'เข้าสอบครบ',
};

export const MODULE_PROGRESS_STATUS = {
  INCOMPLETED: 'INCOMPLETED',
  COMPLETED: 'COMPLETED',
};

export const PRACQUIZ_STATUS = {
  INCOMPLETED: 'INCOMPLETED',
  PASSED: 'PASSED',
  FAILED: 'FAILED',
};

export const HOMEWORK_STATUS = {
  INCOMPLETED: 'INCOMPLETED',
  PASSED: 'PASSED',
  FAILED: 'FAILED',
};

export const MODULE_TYPE = {
  LESSON: 'LESSON',
  PRACQUIZ: 'PRACQUIZ',
};

export const CONTENT_TYPE = {
  VDO: 'VDO',
  QUIZ: 'QUIZ',
};

export const RAW_CONTENT_TYPE = {
  VDO: 'Video',
  QUIZ: 'Quiz',
};

export const RANK_STATUS = {
  GOOD: 'GOOD',
  NORMAL: 'NORMAL',
  NEED_IMPROVEMENT: 'NEED_IMPROVEMENT',
  UNRANK: 'UNRANK',
};

export const RANK_STATUS_TH = {
  UNRANK: 'รอผลการสอบ',
  NEED_IMPROVEMENT: 'ควรปรับปรุง',
  NORMAL: 'พอใช้',
  GOOD: 'ดี',
};

export const SINGLE_MODULE_STATUS_TH = {
  INCOMPLETED: 'ยังไม่เข้าเรียน',
  COMPLETED: 'เข้าเรียนแล้ว',
};
