import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { withAnalytic } from '@skooldio/analytics-provider/dist/Analytics/hoc';

import { Icon, ICON_LIST, BackdropProgress } from 'Components/Base';
import ButtonOutlinedIcon from 'Components/ButtonOutlinedIcon';
import ExportCourseUnitReportData from 'Domains/ExportCourseUnitReportData';
import { useAnalytics } from 'Libs/Analytics';
import * as mixpanel from 'config/mixpanel';

const CourseUnitExporter = ({ disabled, appliedFilter, schoolCode, onLogAction, myProfileId }) => {
  const { send } = useAnalytics();
  const { t } = useTranslation('courseUnit');
  const [loading, setLoading] = useState(false);

  const {
    term: { id: schoolTermId } = {},
    grade: { id: schoolGradeId } = {},
    classNo: { classNo } = {},
    subject: { id: subjectId } = {},
    syllabus: { courseCode } = {},
  } = appliedFilter;

  return (
    <ExportCourseUnitReportData
      schoolTermId={schoolTermId}
      schoolGradeId={appliedFilter.grade !== `all-grade-selected` ? schoolGradeId : null}
      classNo={appliedFilter.classNo !== `all-class-selected` ? classNo : null}
      subjectId={subjectId}
      courseCode={courseCode}
      schoolCode={schoolCode}
    >
      {({ fetchCourseUnitReportData, exportCourseUnitReport }) => {
        return (
          <Fragment>
            <BackdropProgress open={loading} />
            <ButtonOutlinedIcon
              textProp={{
                fontWeight: 'semibold',
              }}
              leftIcon={<Icon name={ICON_LIST.EXPORT} />}
              lineHeight={'0.5'}
              height={'40px'}
              message={t('EXPORT')}
              disabled={disabled || loading}
              onClick={async () => {
                onLogAction({
                  category: 'Export',
                  action: `Click`,
                  label: `Click on course unit page`,
                });
                send('export', { on: 'course_unit_page' });
                mixpanel.trackEvent('Course Student Export File Click', {
                  distinct_id: myProfileId,
                });
                setLoading(true);
                const { data, error } = await fetchCourseUnitReportData();
                setLoading(false);
                if (!error) {
                  await exportCourseUnitReport({
                    report: data.foCourseUnitReport,
                    type: 'xlsx',
                  });
                }
              }}
            />
          </Fragment>
        );
      }}
    </ExportCourseUnitReportData>
  );
};

export default withAnalytic(CourseUnitExporter);
