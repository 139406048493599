import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import Table from 'Components/Table';
import { Text, Box } from 'Components/Base';

const TextWrapper = styled(Box)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  font-weight: ${({ fontWeight }) => fontWeight ?? 'normal'};
`;

const AttendanceTableStyle = styled(Table)`
  & .MuiTableCell-sizeSmall {
    padding: 5px 10px;
    border-right: 1px solid ${({ theme }) => theme.colors.white};
    border-bottom: none;

    &:nth-last-child(1) {
      border-right: none;
    }
  }

  & .MuiTableRow-head:nth-child(1) {
    & .MuiTableCell-head {
      &:nth-child(n + 5) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.white};
      }
    }
  }

  & .MuiTableRow-head:nth-child(2) {
    & .MuiTableCell-head {
      &:nth-child(1) {
        min-width: 100px;
        width: 100px;
      }
    }
  }

  & .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const TableHead = props => <Text fontWeight={600} {...props} />;
const HeaderColSpanText = styled(TableHead)`
  white-space: nowrap;
`;

export const CourseUnitAttendanceTable = ({
  data: { reports = [], courseName },
  maxHeight,
  ...props
}) => {
  const { t } = useTranslation('courseUnit');
  const history = useHistory();
  const queryString = new URLSearchParams(window.location.search);
  const theme = useContext(ThemeContext);

  const getTextColor = value => {
    if (value > 0) return theme.colors.passText;
    if (value < 0) return theme.colors.failText;
  };

  const columns = [
    {
      id: 'classNo',
      Header: <TableHead>{t('CLASS')}</TableHead>,
      accessor: properties => properties.schoolGrade.shortName + '/' + properties.classNo,
      width: 100,
      Cell: ({ cell: { row } }) => (
        <TextWrapper minWidth={'100px'}>
          {row?.original?.schoolGrade.shortName}/{row?.original?.classNo}
        </TextWrapper>
      ),
    },
    {
      id: 'studentNo',
      Header: <TableHead>{t('NO')}</TableHead>,
      accessor: 'studentNo',
      width: 100,
      Cell: ({ cell: { value } }) => <TextWrapper>{value}</TextWrapper>,
    },
    {
      id: 'username',
      Header: <TableHead>{t('USERNAME')}</TableHead>,
      accessor: 'classroomUsername',
      width: 140,
      Cell: ({ cell: { value } }) => <TextWrapper minWidth={'140px'}>{value}</TextWrapper>,
    },
    {
      id: 'displayName',
      Header: <TableHead>{t('DISPLAY_NAME')}</TableHead>,
      accessor: properties => properties.firstName + ' ' + properties.lastName,
      Cell: ({ cell: { row } }) => (
        <TextWrapper style={{ textAlign: 'left' }}>
          {row?.original?.firstName} {row?.original?.lastName}
        </TextWrapper>
      ),
    },
    {
      id: 'courseUnit',
      Header: <HeaderColSpanText>{t('UNIT', { unitName: courseName ?? '' })}</HeaderColSpanText>,
      columns: [
        {
          id: 'attendanceRate',
          Header: <TableHead>{t('ATTENDANCE_RATE')}</TableHead>,
          accessor: 'attendanceRate',
          disableSortBy: true,
          Cell: ({ cell: { value } }) => (
            <TextWrapper>{`${value.actual}/${value.total}`}</TextWrapper>
          ),
        },
        {
          id: 'examinationRate',
          Header: <TableHead>{t('EXAMINATION_RATE')}</TableHead>,
          accessor: 'examinationRate',
          disableSortBy: true,
          Cell: ({ cell: { value } }) => (
            <TextWrapper>{`${value.actual}/${value.total}`}</TextWrapper>
          ),
        },
        {
          id: 'growthRate',
          Header: <TableHead>{t('GROWTH_RATE')}</TableHead>,
          accessor: 'growthRate',
          disableSortBy: true,
          Cell: ({ cell: { value } }) => {
            if (value !== null) {
              const formatValue = `${_.round(value)}%`;
              return (
                <TextWrapper color={getTextColor(value)} fontWeight={'bold'}>
                  {value > 0 ? `+${formatValue}` : formatValue}
                </TextWrapper>
              );
            } else {
              return null;
            }
          },
        },
      ],
    },
  ];
  return (
    <AttendanceTableStyle
      dense
      stickyHeader
      columns={columns}
      data={reports}
      style={{ maxHeight }}
      getRowProps={rowProps => ({
        style: { cursor: 'pointer' },
        hover: true,
        onClick: () => {
          const username = rowProps?.original?.classroomUsername;
          const classNo = rowProps?.original?.classNo;
          const schoolGradeId = rowProps?.original?.schoolGrade.id;
          const term = queryString.get('term');
          const subject = queryString.get('subj');
          const syllabus = queryString.get('course');
          const classFilter = queryString.get('classFilter') ?? queryString.get('class') ?? null;
          const gradeFilter = queryString.get('gradeFilter') ?? queryString.get('grade') ?? null;
          const destinationPath = `${window.location.pathname}/${username}?term=${term}&grade=${schoolGradeId}&class=${classNo}&subj=${subject}&course=${syllabus}&classFilter=${classFilter}&gradeFilter=${gradeFilter}`;
          history.push(destinationPath);
        },
      })}
      {...props}
    />
  );
};

CourseUnitAttendanceTable.propTypes = {
  data: PropTypes.shape({ reports: PropTypes.array.isRequired, courseName: PropTypes.string }),
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CourseUnitAttendanceTable;
