import { useQuery } from '@apollo/react-hooks';

import { getIndividualUnitReport } from 'GraphQL/queries/report.query';

const IndividualUnitReportData = ({ value, schoolCode, username, children }) => {
  const { term, grade, classNo, subject, syllabus } = value;

  const { loading, error, data, refetch } = useQuery(getIndividualUnitReport, {
    variables: {
      schoolTermId: term,
      schoolGradeId: grade,
      classNo,
      subjectId: subject,
      courseCode: syllabus,
      classroomUsername: username,
      schoolCode,
    },
    skip: !term || !grade || !classNo || !subject || !syllabus || !schoolCode || !username,
  });

  return children({ loading, error, data, refetch });
};

export default IndividualUnitReportData;
