import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.fonts.sans};
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
    color: ${({ theme }) => theme.colors.greyscale.grey1};
    margin: 0;
    padding: 0;
    height: 100%;
  }
`;

export default GlobalStyle;
