import React from 'react';
import PropTypes from 'prop-types';
import { RadarChart, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, Legend } from 'recharts';

const LSPRadarChart = ({ data, axisKey, axisColors, dataKeys, ...restProps }) => {
  return (
    <RadarChart data={data} outerRadius={75} startAngle={120} endAngle={-240} {...restProps}>
      <PolarGrid />
      <PolarRadiusAxis tickCount={12} tick={false} domain={[0, 100]} />
      <PolarAngleAxis
        dataKey={axisKey}
        tick={d => {
          return (
            <g>
              <rect
                x={d.x - 50}
                y={d.y - 4}
                width="100"
                height="6"
                transform={`rotate(${-30 + d.index * 60} ${d.x} ${d.y})`}
                fill={axisColors[d.payload.value]}
              />
              <circle cx={d.x} cy={d.y} r={12} fill={axisColors[d.payload.value]} />
              <text
                x={d.x}
                y={d.y}
                fill="white"
                textAnchor="middle"
                dominantBaseline="central"
                fontSize={14}
              >
                {d.payload.value}
              </text>
            </g>
          );
        }}
      />
      {dataKeys.map(({ name, key, ...restRadarProps }) => (
        <Radar name={name} key={key} dataKey={key} {...restRadarProps} />
      ))}
      <Legend iconType="circle" />
    </RadarChart>
  );
};

LSPRadarChart.propTypes = {
  data: PropTypes.array.isRequired,
  axisKey: PropTypes.string.isRequired,
  axisColors: PropTypes.object.isRequired,
  dataKeys: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

LSPRadarChart.defaultProps = {
  width: 250,
  height: 250,
};

export default LSPRadarChart;
