const customSpaces = {
  NAVBAR_LOGO_HEIGHT: '80px',
  NAVBAR_NAVMENU_HEIGHT: '80px',
};

const spaces = [0, 4, 8, 12, 16, 24, 32, 48, 64, 128, 256, 512];
const aliases = ['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'xxxxl', 'xxxxxl'];

const spacesByAliases = aliases.reduce((result, alias, i) => {
  result[alias] = i;
  return result;
}, {});

const spacesWithUnit = spaces.map(s => `${s}px`);

Object.keys(customSpaces).forEach(key => {
  const value = customSpaces[key];

  spaces[key] = value;
  spacesByAliases[key] = typeof value === 'number' ? `${value}px` : value;
  spacesWithUnit[key] = typeof value === 'number' ? `${value}px` : value;
});

export { spaces, spacesByAliases, spacesWithUnit };
export default spacesByAliases;

// To add more spaces later, add custom aliases to customSpaces:
// CUSTOM_SPACE = 15px
// then call it as <Box p={'CUSTOM_SPACE'} />
