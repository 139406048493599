import React from 'react';
import { Button as SkooldioButton } from '@skooldio/skooldio-ui-components-core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { buttonStyle, layout } from 'styled-system';

const Button = styled(SkooldioButton)`
  border-radius: 4px;
  font-size: 16px;
  font-weight: 300;
  font-family: inherit;
  padding: 10px 16px;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
  ${buttonStyle};
  ${layout};

  ${props =>
    props.disabled &&
    `
    color: ${props.theme.colors.white};
    border-color: ${props.theme.colors.greyscale.grey4};
    background-color: ${props.theme.colors.greyscale.grey4};
    &:hover {
      cursor: default;
      color: ${props.theme.colors.white};
      border-color: ${props.theme.colors.greyscale.grey4};
      background-color: ${props.theme.colors.greyscale.grey4};
    }
    &:active {
      color: ${props.theme.colors.white};
      border-color: ${props.theme.colors.greyscale.grey4};
      background-color: ${props.theme.colors.greyscale.grey4};
    }
  `};
`;

Button.propTypes = {
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  minWidth: 100,
};

export default Button;
