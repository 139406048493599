import React from 'react';
import { Button as SkooldioButton } from '@skooldio/skooldio-ui-components-core';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Icon } from '../Icon';

const StyledToolbarSkooldioButton = styled(SkooldioButton)`
  background-color: Transparent;
  cursor: pointer;
  overflow: hidden;
  border: none;
  outline: none;

  &:hover {
    color: ${({ theme, color, disabled }) =>
      disabled
        ? theme.colors.greyscale.grey3
        : color === 'primary'
        ? theme.colors.primaryDark
        : 'initial'};
  }

  &:disabled {
    cursor: default;
  }
`;

const ToolbarButton = props => {
  const { startIcon, color, iconSize, disabled, ...restProps } = props;
  return (
    <StyledToolbarSkooldioButton
      variant="flat"
      color={disabled ? 'greyscale.grey3' : color}
      disabled={disabled}
      {...restProps}
    >
      {startIcon && <Icon name={startIcon} size={iconSize} />}
    </StyledToolbarSkooldioButton>
  );
};

ToolbarButton.propTypes = {
  children: PropTypes.element,
  startIcon: PropTypes.string,
  color: PropTypes.string,
  iconSize: PropTypes.number,
  disabled: PropTypes.bool,
};

ToolbarButton.defaultProps = {
  color: 'primary',
  iconSize: 14,
  disabled: false,
};

export default ToolbarButton;
