import gql from 'graphql-tag';

export const getSchoolInfo = gql`
  query foSchoolInfo($schoolCode: String!) {
    foSchoolInfo(schoolCode: $schoolCode) {
      id
      name
      code
      moderatorInfo {
        title
        firstName
        lastName
        phoneNumber
        LINEId
      }
    }
  }
`;

export const getAllSchools = gql`
  query foAllSchools {
    foAllSchools {
      id
      name
      code
    }
  }
`;
