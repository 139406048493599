import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'use-media';
import { ThemeContext } from 'styled-components';

import { Flex, Layout } from '../Base';

const TabContent = ({
  content,
  contentProps,
  contentBg = 'greyscale.grey5',
  sidebar,
  sidebarBg = 'white',
}) => {
  const theme = useContext(ThemeContext);
  const isBreakpointsLg = useMedia({ minWidth: theme.breakpoints.lg });
  const sidebarPosition = isBreakpointsLg ? 'right' : 'bottom';
  const conditionalProps = {
    [sidebarPosition]: sidebar,
  };

  return (
    <Flex>
      <Layout
        main={content}
        mainProps={{
          p: sidebar && 5, // Show grey background
          bg: contentBg,
          height: '100%',
          borderRadius: sidebar ? { _: '10px 10px 0px 0px', lg: '10px 0px 0px 10px' } : '10px',
          ...contentProps,
        }}
        rightProps={{
          p: 5,
          bg: sidebarBg,
          height: '100%',
          borderRadius: '0px 10px 10px 0px',
        }}
        bottomProps={{
          p: 5,
          bg: sidebarBg,
          height: '100%',
          borderRadius: '0px 0px 10px 10px',
        }}
        {...conditionalProps}
      />
    </Flex>
  );
};

TabContent.propTypes = {
  content: PropTypes.element,
  contentProps: PropTypes.object,
  contentBg: PropTypes.string,
  sidebar: PropTypes.element,
  sidebarBg: PropTypes.string,
};

export default TabContent;
