import React from 'react';

import { Container } from 'Components/Base';

const BreadcrumbLayout = ({ breadcrumbComponent: BreadcrumbComponent, children }) => {
  return (
    <Container>
      <BreadcrumbComponent />
      <Container>{children}</Container>
    </Container>
  );
};

export default BreadcrumbLayout;
