import React, { useCallback, memo } from 'react';
import { useHistory } from 'react-router-dom';

import TabContent from 'Components/TabContent';
import Breadcrumb from 'Components/Breadcrumb';
import BreadcrumbLayout from 'Components/BreadcrumbLayout';
import LSPUserData from 'Domains/LSPUserData';
import StudentPracQuiz from 'Containers/StudentPracQuiz';
import TeacherProfileBox from 'Containers/TeacherProfileBox';
import { withAuth } from 'Libs/Auth';

const BreadcrumbComponent = memo(function BreadcrumbComponent() {
  const items = [
    {
      key: 'dashboard',
      title: 'Dashboard',
      path: '/student/dashboard',
    },
    {
      key: 'pracquiz',
      title: 'Pracquiz',
      path: '/student/dashboard/pracquiz',
    },
  ];
  return <Breadcrumb items={items} variant={'dark'} />;
});

const StudentPracQuizPage = props => {
  const history = useHistory();
  const {
    auth: {
      myProfile: { id: userId },
    },
  } = props;
  return (
    <BreadcrumbLayout
      breadcrumbComponent={useCallback(
        () => (
          <BreadcrumbComponent />
        ),
        [],
      )}
    >
      <TabContent
        content={
          <StudentPracQuiz userId={userId} onBack={() => history.push('/student/dashboard')} />
        }
        sidebar={
          <LSPUserData userId={userId}>
            {({ loading, error, data }) => {
              if (loading) return null;
              if (error) return null;
              const {
                foProfile: { teacherId },
              } = data;
              return <TeacherProfileBox userId={teacherId} />;
            }}
          </LSPUserData>
        }
      />
    </BreadcrumbLayout>
  );
};

export default memo(withAuth(StudentPracQuizPage));
