import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, branch, renderComponent } from 'recompose';
import { Redirect } from 'react-router-dom';
import { withAnalytic } from '@skooldio/analytics-provider/dist/Analytics/hoc';

import { withAuth, AUTH_STATE } from 'Libs/Auth';
import { withConfig } from 'Libs/Config';
import NavMenu from 'Containers/NavMenu';
import NavMenuItem from 'Components/NavMenuItem';
import { Box, Flex, Image } from 'Components/Base';
import AccountInfo from 'Containers/AccountInfo';

const MenuBlock = styled(Box)`
  position: fixed;
  top: 0;
  z-index: 1;
  height: ${({ theme }) => theme.space.NAVBAR_NAVMENU_HEIGHT};
`;

const ChildrenBox = styled(Flex)`
  padding-top: ${({ theme }) => theme.space.NAVBAR_NAVMENU_HEIGHT};
  height: 100vh;
  flex-direction: column;
  /* background-color: ${({ theme }) => theme.colors.backgroundPage}; */
  overflow: hidden;
`;

const InnerBlock = styled(Flex)`
  flex: 1 0;
  overflow: auto;
`;

const MenusComponent = memo(function MenusComponent({ menus }) {
  return (
    <MenuBlock bg={'primary'} width={1} px={[4, null, '24px', '40px']}>
      <Flex justifyContent={'space-between'} alignItems="center">
        <Flex alignItems="center">
          <NavMenuItem style={{ padding: 0 }} to={'/'}>
            <Image
              flex="0 0 auto"
              src="/assets/components/AppLayout/logo-le.png"
              srcSet="/assets/components/AppLayout/logo-le.png 1x, /assets/components/AppLayout/logo-le@2x.png 2x, /assets/components/AppLayout/logo-le@3x.png 3x"
              width="170px"
            />
          </NavMenuItem>
          <Box mx={[0, null, 3, 5]} style={{ overflow: 'auto' }}>
            <NavMenu menus={menus} />
          </Box>
        </Flex>
        <Box p={0} flex="0 0 auto">
          <AccountInfo />
        </Box>
      </Flex>
    </MenuBlock>
  );
});

const AppLayout = ({ menus, children }) => {
  return (
    <>
      <MenusComponent menus={menus} />
      <ChildrenBox>
        <InnerBlock p={4}>{children}</InnerBlock>
      </ChildrenBox>
    </>
  );
};

AppLayout.propTypes = {
  menus: PropTypes.array,
  school: PropTypes.string,
  name: PropTypes.string,
  tel: PropTypes.string,
  lineId: PropTypes.string,
  children: PropTypes.element,
};

AppLayout.defaultProps = {
  menus: [],
};

export { AppLayout };
export default compose(
  withAuth,
  withConfig,
  withAnalytic,
  branch(
    props => props.auth.authState === AUTH_STATE.NOT_AUTHENTICATE,
    renderComponent(() => {
      const { from } = { from: { pathname: '/login' } };
      return <Redirect to={from} />;
    }),
  ),
)(AppLayout);
