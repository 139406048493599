import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';

import { Text, Icon as BaseIcon, ICON_LIST, Flex } from 'Components/Base';
import Dropdown from 'Components/Dropdown';

import ChangePasswordComponent from './ChangePassword';

const Icon = styled(BaseIcon)`
  &&& {
    margin-right: 0px;
  }
`;

const itemList = [
  {
    key: 1,
    value: 1,
    text: 'เปลี่ยนรหัสผ่าน',
  },
  {
    key: 2,
    value: 2,
    text: 'ออกจากระบบ',
  },
];

const Title = ({ firstname, code }) => (
  <Flex justifyContent="space-evenly">
    <Text>{`${firstname}, ${code}`}</Text>
    <Text ml={2}>
      <Icon name={ICON_LIST.USER} />
    </Text>
  </Flex>
);

const UserMenu = props => {
  const {
    auth: { myProfile, logout },
  } = props;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!myProfile || !myProfile.firstname) {
    return null;
  }
  const { firstname, additional5: code } = myProfile;
  return (
    <Fragment>
      <Dropdown
        title={`${firstname}, ${code}`}
        TitleComponent={<Title firstname={firstname} code={code} />}
        list={itemList}
        defaultValue="title"
        onChange={(value, setValue) => {
          if (value === 1) {
            handleOpen();
          } else if (value === 2) {
            logout();
          }
          setValue('title'); // Reset Dropdown behavior
        }}
      />
      <Modal open={open} onClose={handleClose}>
        <ChangePasswordComponent handleCancel={handleClose} />
      </Modal>
    </Fragment>
  );
};

UserMenu.propTypes = {
  myProfile: PropTypes.shape({
    firstname: PropTypes.string,
    additional5: PropTypes.string,
  }),
  logout: PropTypes.func,
};

UserMenu.defaultProps = {
  myProfile: {},
  logout: () => {},
};

export default UserMenu;
