import mixpanel from 'mixpanel-browser';

const initMixpanel = () => {
  try {
    if (!window.appConfig.mixpanelToken) return false;
    mixpanel.init(window.appConfig.mixpanelToken);
    return true;
  } catch {
    return null;
  }
};

export const setPeople = (distinctId, data = {}) => {
  const isSuccess = initMixpanel();
  if (isSuccess) {
    mixpanel.identify(distinctId);
    mixpanel.people.set(data);
  }
};

export const trackEvent = async (eventName, data = {}) => {
  const isSuccess = initMixpanel();
  if (isSuccess && data?.distinct_id) {
    mixpanel.identify(data.distinct_id);
    mixpanel.track(eventName, data);
  }
};
