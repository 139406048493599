import { useApolloClient } from '@apollo/react-hooks';
import XLSX from 'xlsx';

import { getCourseSectionReport } from '../../GraphQL/queries/report.query';
import { formatSchoolTerm } from '../utils';

export const SHEET_TYPE = {
  ATTENDANCE_RATE: 'attendanceRate',
  EXAMINATION_RATE: 'examinationRate',
  EXAMINATION_AVERAGE_RESULT: 'examinationAverageResult',
};

const SHEET_NAME = {
  attendanceRate: 'ภาพรวมการเข้าเรียน',
  examinationRate: 'ภาพรวมการเข้าสอบ',
  examinationAverageResult: 'ภาพรวมคะแนน',
};

const SHEETS = [
  SHEET_TYPE.EXAMINATION_AVERAGE_RESULT,
  SHEET_TYPE.ATTENDANCE_RATE,
  SHEET_TYPE.EXAMINATION_RATE,
];

export const convertCourseSectionReportToAOA = (courseSectionReport, sheetType) => {
  const {
    schoolGrade: { shortName: schoolGrade },
    classes = [],
  } = courseSectionReport;
  return classes.map(klass => [
    `${schoolGrade}/${klass.classNo}`,
    ...klass.courseSections.map(courseSection => courseSection[sheetType]),
  ]);
};

const formatReportToAOAHeader = courseSectionReport => {
  const {
    course: { courseTitle },
    courseSections = [],
  } = courseSectionReport;

  const courseSectionNames = courseSections.map(courseSection => courseSection.courseSectionTitle);
  return [
    ['', ...Array(courseSections.length).fill(courseTitle)],
    ['ห้อง', ...courseSectionNames],
  ];
};

const createAndFormatWorksheet = formattedReportAOA => {
  const worksheet = XLSX.utils.aoa_to_sheet(formattedReportAOA);
  const cols = [
    { wch: 10 }, // ห้อง
    ...Array(formattedReportAOA[0].length - 1).fill({ wch: 15 }), // หน่วยการเรียนรู้
  ];
  worksheet['!merges'] = [
    { s: { r: 0, c: 1 }, e: { r: 0, c: cols.length - 1 } }, // ชื่อวิชา
  ];
  worksheet['!cols'] = cols;
  return worksheet;
};

const ExportCourseSectionReportData = props => {
  const { schoolTermId, schoolGradeId, subjectId, courseCode, schoolCode } = props;

  const client = useApolloClient();

  const fetchCourseSectionReportData = async () => {
    try {
      const { data } = await client.query({
        query: getCourseSectionReport,
        variables: {
          schoolTermId,
          schoolGradeId,
          subjectId,
          courseCode,
          schoolCode,
        },
      });
      return { data };
    } catch (error) {
      return { error };
    }
  };

  const exportCourseSectionReport = async ({ report: courseSectionReport, type }) => {
    const {
      schoolGrade: { shortName: schoolGrade } = {},
      schoolTerm: { shortName: schoolTerm } = {},
      subject: { name: subject } = {},
      course: { courseTitle } = {},
    } = courseSectionReport;

    const formattedReportAOAHeader = formatReportToAOAHeader(courseSectionReport);
    const workbook = XLSX.utils.book_new();
    SHEETS.forEach(sheetType => {
      const formattedReportAOAData = convertCourseSectionReportToAOA(
        courseSectionReport,
        sheetType,
      );
      const worksheet = createAndFormatWorksheet([
        ...formattedReportAOAHeader,
        ...formattedReportAOAData,
      ]);
      XLSX.utils.book_append_sheet(workbook, worksheet, SHEET_NAME[sheetType]);
    });

    XLSX.writeFile(
      workbook,
      `ภาพรวมระดับชั้น_${formatSchoolTerm(
        schoolTerm,
      )}_${schoolGrade}-ทุกห้อง_${subject}_${courseTitle}.` + (type || 'xlsx'),
    );
  };

  return { fetchCourseSectionReportData, exportCourseSectionReport };
};

export default ExportCourseSectionReportData;
