export const SECTION_TYPE = {
  QUIZ_FILTER_FIRST: 'first',
  QUIZ_FILTER_MAX: 'max',
  QUIZ_FILTER_AVG: 'avg',
};

export const SECTION_TYPE_REVERSE = {
  first: 'QUIZ_FILTER_FIRST',
  max: 'QUIZ_FILTER_MAX',
  avg: 'QUIZ_FILTER_AVG',
};
