import { useQuery } from '@apollo/react-hooks';

import {
  getStudentRIASECByStudentId,
  getTeacherRIASECByStudentId,
} from 'GraphQL/queries/modules.query';

const StudentRIASECData = props => {
  const { userId, children } = props;
  const { loading: studentLoading, error: studentError, data: studentData } = useQuery(
    getStudentRIASECByStudentId,
    {
      variables: { studentId: userId },
    },
  );
  const { loading: teacherLoading, error: teacherError, data: teacherData } = useQuery(
    getTeacherRIASECByStudentId,
    {
      variables: { studentId: userId },
    },
  );
  if (studentData && teacherData) {
    const data = studentData.foStudentRIASEC.map(({ code, value, max }) => {
      return {
        code,
        student: value,
        teacher: teacherData.foTeacherRIASEC.find(({ code: teacherCode }) => teacherCode === code)
          .value,
        max,
      };
    });
    return children({ data });
  }
  return children({
    loading: studentLoading || teacherLoading,
    error: studentError || teacherError,
    data: [],
  });
};

export default StudentRIASECData;
