import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import colors from './colors';

const muiTheme = createMuiTheme({
  palette: {
    primary: { main: colors.primary },
    secondary: { main: colors.secondary },
    error: { main: colors.danger },
  },
  typography: {
    fontFamily: 'inherit',
    useNextVariants: true,
  },
});

export { muiTheme };
export default props => <MuiThemeProvider theme={muiTheme} {...props} />;
