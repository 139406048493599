import React from 'react';
import styled from 'styled-components';

import { Text, Box, Link, List } from 'Components/Base';
import LEAdminSchoolData from 'Domains/LEAdminSchoolData';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:active {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const SchoolListPageWrapper = styled(Box)`
  flex: 1 0;
  display: grid;
  grid: auto auto 1fr / 100%;
  row-gap: 16px;
`;

const SchoolListPage = () => {
  return (
    <SchoolListPageWrapper px={7} py={5}>
      <Text.Title color={'primary'}>เลือกโรงเรียน</Text.Title>
      <LEAdminSchoolData>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return null;
          const { foAllSchools } = data;
          return (
            <List
              style={{ overflow: 'auto' }}
              items={foAllSchools}
              renderItem={({ code, name }, index) => (
                <StyledLink href={`/report/${code}/course-grade`} key={index}>
                  <Text mb={index !== foAllSchools.length - 1 ? 2 : 0}>{`${code} : ${name}`}</Text>
                </StyledLink>
              )}
            />
          );
        }}
      </LEAdminSchoolData>
    </SchoolListPageWrapper>
  );
};

export default SchoolListPage;
