import React, { useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';

import Table from 'Components/Table';
import { Text, Box } from 'Components/Base';
import ProgressBar from 'Components/ProgressBar';
import { COURSE_GRADE_TYPE } from 'Domains/CourseGradeReportData';
import * as mixpanel from 'config/mixpanel';

const TextWrapper = styled(Box)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  font-weight: ${({ fontWeight }) => fontWeight ?? 'normal'};
`;

const AttendanceTableStyle = styled(Table)`
  & .MuiTableContainer-root {
    overflow-x: unset !important;
  }

  & .MuiTableCell-sizeSmall {
    padding: 5px 10px;
    border-right: 1px solid ${({ theme }) => theme.colors.white};
    border-bottom: none;

    &:nth-last-child(1) {
      border-right: none;
    }
  }

  & .MuiTableRow-head:nth-child(1) {
    & .MuiTableCell-head {
      &:nth-child(n + 2) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.white};
      }
    }
  }

  & .MuiTableRow-head:nth-child(2) {
    & .MuiTableCell-head {
      &:nth-child(n + 2) {
        display: table-cell;
        max-width: 0px;
      }
    }
  }

  & .MuiTableRow-root {
    & .MuiTableCell-root:nth-child(1).MuiTableCell-head {
      position: sticky;
      left: 0 !important;
      width: 100px;
      z-index: 3;
    }

    & .MuiTableCell-root:nth-child(1):not(.MuiTableCell-head) {
      position: sticky;
      left: 0 !important;
      width: 100px;
      z-index: 2;
    }
  }

  & .MuiTableRow-root:not(.MuiTableRow-head) {
    cursor: ${({ cursorPointer }) => (cursorPointer ? 'pointer' : 'auto')};
  }

  & .MuiTableRow-root:not(.MuiTableRow-head):nth-child(2n + 1) {
    & .MuiTableCell-root:nth-child(1) {
      background-color: rgb(247, 251, 253) !important;
    }
  }

  & .MuiTableRow-root:not(.MuiTableRow-head):nth-child(2n) {
    & .MuiTableCell-root:nth-child(1) {
      background-color: rgb(235, 245, 250) !important;
    }
  }

  & .MuiTableCell-root.MuiTableCell-body:empty {
    background-color: ${({ theme }) => theme.colors.greyscale.grey4};
  }

  td:nth-child(${props => props.columnHovering}) {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const TableHead = props => <Text fontWeight={600} {...props} />;
const HeaderColSpanText = styled(TableHead)`
  white-space: nowrap;
`;

export const CourseGradeTable = ({
  data: { schoolGrade, classes = [], courses = [], subject },
  maxHeight,
  type,
  setSyllabusId,
  myProfileId,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation('courseGrade');
  const [columnHovering, setColumnHovering] = React.useState(null);
  const isGrowthRateType = type === COURSE_GRADE_TYPE.GROWTH_RATE;

  const getTextColor = value => {
    if (value > 0) return theme.colors.passText;
    if (value < 0) return theme.colors.failText;
  };

  const columns = [
    {
      id: 'classNo',
      Header: <TableHead>{t('CLASS')}</TableHead>,
      accessor: properties => properties?.classNo,
      width: 100,
      Cell: ({ cell: { row } }) => {
        return (
          <TextWrapper width={'100px'}>
            {schoolGrade?.shortName}/{row?.original?.classNo}
          </TextWrapper>
        );
      },
    },
    {
      id: 'courseUnit',
      Header: (
        <HeaderColSpanText>{t('UNIT', { subjectName: subject?.name ?? '' })}</HeaderColSpanText>
      ),
      columns: courses.map((course, idx) => ({
        id: course.courseCode,
        Header: <TableHead>{course.courseName}</TableHead>,
        accessor: properties => properties.courses,
        width: 184,
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          if (value[idx] && value[idx][type] !== null) {
            if (isGrowthRateType) {
              const formatValue = `${_.round(value[idx][type])}%`;
              return (
                <TextWrapper color={getTextColor(value[idx][type])} fontWeight={'bold'}>
                  {value[idx][type] > 0 ? `+${formatValue}` : formatValue}
                </TextWrapper>
              );
            } else {
              return (
                <ProgressBar
                  progress={_.round(value[idx][type], 2)}
                  thresholdType={type === COURSE_GRADE_TYPE.EXAMINATION_AVERAGE_RESULT ? 2 : 1}
                />
              );
            }
          } else {
            return null;
          }
        },
      })),
    },
  ];

  return (
    <AttendanceTableStyle
      dense
      stickyHeader
      columns={columns}
      data={classes}
      style={{ maxHeight, overflowX: 'unset' }}
      columnHovering={columnHovering}
      onMouseOverBodyCell={!isGrowthRateType ? idx => setColumnHovering(idx) : undefined}
      onClickBodyCell={
        !isGrowthRateType
          ? cell => {
              mixpanel.trackEvent('Course Grade Table Click', {
                distinct_id: myProfileId,
              });
              setSyllabusId(cell.column.id);
            }
          : undefined
      }
      cursorPointer={!isGrowthRateType}
      {...props}
    />
  );
};

CourseGradeTable.propTypes = {
  data: PropTypes.shape({ reports: PropTypes.array.isRequired, subject: PropTypes.object }),
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  setSyllabusId: PropTypes.func,
  myProfileId: PropTypes.string,
};

export default CourseGradeTable;
