import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Collapse, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { withAnalytic } from '@skooldio/analytics-provider/dist/Analytics/hoc';

import { Flex, Box, Icon, ICON_LIST, Text, Image } from 'Components/Base';
import Breadcrumb from 'Components/Breadcrumb';
import ButtonIcon from 'Components/ButtonIcon';
import Loading from 'Components/Loading';
import AttendanceFilter, { getFilterData, convertToBreadCrumb } from 'Containers/AttendanceFilter';
import AttendanceFilterData from 'Domains/AttendanceFilterData';
import CourseCodeData from 'Domains/CourseCodeReportData';
import { useAnalytics } from 'Libs/Analytics';
import * as mixpanel from 'config/mixpanel';

import { updatedFilter } from '../../Containers/AttendanceFilter/utils';

import AttendanceTable from './AttendanceTable';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    top: '50px',
    zIndex: 5,
    minWidth: '350px',
    width: '100%',
    maxWidth: '700px',
  },
});

const FilterBlock = styled(Box)`
  border-radius: 15px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
`;

const SchoolSummaryReportTableWrapper = styled(Box)`
  position: relative;
  overflow: auto;
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: visible;
  }
`;

const useQueryString = () => {
  return new URLSearchParams(useLocation().search);
};

const AttendanceTableView = ({ currentFilter, data, translation, setDisabledFetch }) => {
  if (!_.isEmpty(currentFilter)) {
    setDisabledFetch(false);
    if (!_.isEmpty(data)) {
      return <AttendanceTable data={data.foCourseReport.reports} />;
    } else {
      return (
        <AttendanceTable
          data={[]}
          renderEmpty={({ colSpan }) => (
            <tr>
              <td colSpan={colSpan}>
                <Box bg={'tableRowBg'} py={9}>
                  <Text style={{ textAlign: 'center' }}>{translation('NOT_FOUND')}</Text>
                </Box>
              </td>
            </tr>
          )}
        />
      );
    }
  } else {
    return (
      <AttendanceTable
        data={[]}
        renderEmpty={({ colSpan }) => (
          <tr>
            <td colSpan={colSpan}>
              <Box bg={'tableRowBg'}>
                <Flex flexDirection={'column'} py={9}>
                  <Flex mb={3} mx={'auto'}>
                    <Text>{translation('SELECT_BUTTON')}</Text>
                  </Flex>
                  <Flex mb={3} mx={'auto'}>
                    <Image
                      maxHeight={'40px'}
                      src={'/assets/components/Icons/filter-button-icon.png'}
                      srcSet={
                        '/assets/components/Icons/filter-button-icon.png 1x, /assets/components/Icons/filter-button-icon@2x.png 2x, /assets/components/Icons/filter-button-icon@3x.png 3x'
                      }
                    />
                  </Flex>
                  <Flex mb={3} mx={'auto'}>
                    <Text>{translation('TO_DISPLAYED')}</Text>
                  </Flex>
                </Flex>
              </Box>
            </td>
          </tr>
        )}
      />
    );
  }
};

const SchoolSummary = ({
  isFetchData,
  setIsFetchData,
  setDisabledFetch,
  onFilterApplied,
  onLogAction,
  schoolCode,
  myProfileId,
}) => {
  const { send } = useAnalytics();
  const { t } = useTranslation('summary');
  const queryString = useQueryString();
  const currentPath = useLocation().pathname;
  const history = useHistory();
  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({});
  const [isFirstTime, setIsFirstTime] = useState(true);

  const filterValue = {
    term: currentFilter?.term?.id ?? queryString.get('term') ?? null,
    grade: currentFilter?.grade?.id ?? queryString.get('grade') ?? null,
    classNo: currentFilter?.classNo?.classNo ?? queryString.get('class') ?? null,
    subject: currentFilter?.subject?.id ?? queryString.get('subj') ?? null,
    syllabus: currentFilter?.syllabus?.courseCode ?? queryString.get('course') ?? null,
    lesson: currentFilter?.lesson?.sectionCode ?? queryString.get('sect') ?? null,
  };

  const handleChange = () => {
    setOpenFilter(prev => !prev);
  };

  const handleFilter = ({ action, filterData, queryString }) => {
    onLogAction({
      category: 'Filter',
      action: `Click`,
      label: `Click ${action} filter on course code page`,
    });
    send(`${action}_filter`, { on: 'course_code_page' });
    onFilterApplied(filterData);
    setCurrentFilter(filterData);
    setOpenFilter(false);
    if (action === 'submit') {
      mixpanel.trackEvent('Course Room Submit Click', {
        distinct_id: myProfileId,
        'Year Term': filterData?.term?.name,
        Grade: filterData?.grade?.name,
        Room: filterData?.classNo?.classNo,
        Subject: filterData?.subject?.name,
        Course: filterData?.syllabus?.courseName,
        Section: filterData?.lesson?.sectionName,
      });
    }
    history.push(`${currentPath}${queryString}`);
  };

  return (
    <>
      <Box>
        <AttendanceFilterData initialValue={filterValue} schoolCode={schoolCode}>
          {({ list, value, updateValue }) => {
            const { term, grade, classNo, subject, syllabus, lesson } = value;
            const { termList, gradeList, classList, subjectList, syllabusList, lessonList } = list;

            if (queryString.toString() !== '' && isFirstTime) {
              if (
                termList &&
                gradeList &&
                classList &&
                subjectList &&
                syllabusList &&
                ((syllabus && lessonList) || !syllabus)
              ) {
                if (term && grade && classNo && subject && syllabus && lesson) {
                  const filterData = getFilterData(list, value);
                  onFilterApplied(filterData);
                  setCurrentFilter(filterData);
                }
                setIsFirstTime(false);
              }
            } else {
              updatedFilter(list, value, updateValue, false);
            }

            const handleDisableFilterButton = action => {
              const values = Object.values(value);
              return action === 'submit' ? values.some(v => !v) : values.every(v => !v);
            };

            return (
              <Flex alignItems="center" style={{ position: 'relative' }}>
                <ButtonIcon
                  flex="0 0 auto"
                  variant={'primary'}
                  textProp={{
                    fontWeight: 'semibold',
                  }}
                  lineHeight={'0.5'}
                  height={'40px'}
                  leftIcon={<Icon name={ICON_LIST.FILTER} />}
                  message={t('FILTER')}
                  onClick={handleChange}
                />
                <Collapse in={openFilter} className={classes.container}>
                  <FilterBlock width={1} p={7} maxWidth={'700px'} bg={'white'}>
                    <AttendanceFilter
                      list={list}
                      updateValue={updateValue}
                      value={value}
                      isAddLessonsOption={true}
                      isInvalidFilter={handleDisableFilterButton('submit')}
                      onFilterApply={() =>
                        handleFilter({
                          action: 'submit',
                          filterData: getFilterData(list, value),
                          queryString: `?term=${term}&grade=${grade}&class=${classNo}&subj=${subject}&course=${syllabus}${
                            lesson !== null ? `&sect=${lesson}` : ''
                          }`,
                        })
                      }
                      onResetFilter={() =>
                        handleFilter({
                          action: 'reset',
                          filterData: {},
                          queryString: '',
                        })
                      }
                      isDisableResetFilter={handleDisableFilterButton('reset')}
                      translation={t}
                    />
                  </FilterBlock>
                </Collapse>
                <Breadcrumb
                  maxItemsPerRow={3}
                  items={convertToBreadCrumb(currentFilter)}
                  p={0}
                  ml={4}
                />
              </Flex>
            );
          }}
        </AttendanceFilterData>
      </Box>
      <SchoolSummaryReportTableWrapper>
        <CourseCodeData value={filterValue} schoolCode={schoolCode}>
          {({ loading, error, data, refetch }) => {
            if (loading) return <Loading />;
            if (error) return null;
            if (isFetchData) {
              refetch();
              setIsFetchData(false);
            }
            return (
              <AttendanceTableView
                currentFilter={currentFilter}
                data={data}
                translation={t}
                setDisabledFetch={setDisabledFetch}
              />
            );
          }}
        </CourseCodeData>
      </SchoolSummaryReportTableWrapper>
    </>
  );
};

SchoolSummary.defaultProps = {
  onFilterApplied: {},
};

export default withAnalytic(SchoolSummary);
