import ApolloClient from 'apollo-boost';
import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory';

import introspectionQueryResultData from './fragmentTypes';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher, addTypename: false });

function getApolloClient(graphApi) {
  const client = new ApolloClient({
    cache,
    uri: graphApi,
    credentials: 'include',
  });
  return client;
}

export default getApolloClient;
