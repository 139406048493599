import { useQuery } from '@apollo/react-hooks';

import { getStudentModuleProgress } from 'GraphQL/queries/modules.query';

const StudentModuleProgressData = props => {
  const { userId, startDate, endDate, currentDate, children } = props;
  const { loading, error, data } = useQuery(getStudentModuleProgress, {
    variables: { search: { userId, dateRange: [startDate, endDate], currentDate } },
  });
  return children({ loading, error, data });
};

export default StudentModuleProgressData;
