import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import urlJoin from 'url-join';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import buddhistEra from 'dayjs/plugin/buddhistEra';
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(buddhistEra);

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      loadPath: urlJoin(process.env.PUBLIC_PATH || '/', `/locales/{{lng}}/{{ns}}.json`),
    },
    fallbackLng: 'th', // use th if detected lng is not available
    lng: 'th',
    // have a common namespace used around the full app
    ns: ['translations', 'login', 'module'],
    defaultNS: 'translations',
    debug: false,
    saveMissing: true, // send not translated keys to endpoint
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value, format, lng) => {
        dayjs.locale(lng);
        if (value && (value instanceof Date || dayjs(value).isValid())) {
          return dayjs(value).format(format);
        }
        return value;
      },
    },
    react: {
      wait: true,
      useSuspense: false,
    },
    detection: {
      lookupQuerystring: 'lang',
    },
  });

export default i18n;
