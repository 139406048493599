import { useQuery } from '@apollo/react-hooks';

import { getLSPUserById } from 'GraphQL/queries/lspUsers.query';

const LSPUserData = props => {
  const { userId, children } = props;
  const { loading, error, data } = useQuery(getLSPUserById, {
    variables: {
      userId,
    },
  });
  return children({ loading, error, data });
};

export default LSPUserData;
