import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Text, Flex } from 'Components/Base';

const NoMatchBlock = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const NoMatch = ({ onBackClick, ...props }) => {
  return (
    <NoMatchBlock>
      <Flex flexDirection="column" alignItems="center">
        <Flex mb={2}>
          <Text>Error 404 Page not found!</Text>
        </Flex>
        <Button primary onClick={onBackClick}>
          Back to Home
        </Button>
      </Flex>
    </NoMatchBlock>
  );
};

NoMatch.propTypes = {
  onBackClick: PropTypes.func,
};

NoMatch.defaultProps = {
  onBackClick: () => {},
};

export default NoMatch;
