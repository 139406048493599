import React from 'react';
import PropTypes from 'prop-types';

import { Box, Layout } from '../Base';

const Section = ({ title, detail, layoutProps, ...props }) => {
  return (
    <Box flex={'1 0 auto'} {...props}>
      <Layout
        p={4}
        bg={'white'}
        style={{
          borderRadius: '10px',
        }}
        top={title}
        topProps={{
          mb: 3,
        }}
        main={detail}
        {...layoutProps}
      />
    </Box>
  );
};

Section.propTypes = {
  title: PropTypes.element,
  detail: PropTypes.element,
};

export default Section;
