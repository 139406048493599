import React, { useContext } from 'react';
import { withStyles } from '@material-ui/styles';
import { TextField as FormikTextField } from 'formik-material-ui';
import MUITextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';
import { Input, makeStyles } from '@material-ui/core';
import { ThemeContext } from 'styled-components';

import { Flex, Box } from 'Components/Base';

const useStyles = makeStyles({
  root: theme => ({
    backgroundColor: `${theme.colors.greyscale.grey5}`,
    borderRadius: '100px',
    '& > fieldset': {
      borderColor: `${theme.colors.greyscale.grey5}`,
    },
  }),
  input: theme => ({
    padding: '12px 22px',
    // Workaround to override MUI hidden placeholder
    '&::placeholder': {
      opacity: '0.5 !important',
    },
  }),
  readOnly: theme => ({
    '& > fieldset': {
      borderColor: 'transparent !important',
    },
    '& > input': {
      paddingLeft: 0,
      color: 'black',
    },
    '& textarea': {
      paddingLeft: 0,
      color: 'black',
    },
  }),
  multiline: theme => ({
    padding: 0,
  }),
  outlined: theme => ({
    marginBottom: '4px',
    // Override MUI default label style
    position: 'relative',
    transform: 'translate(0, 0) scale(1)',
    // '&$marginDense': {
    //   transform: 'translate(0, 0) scale(1)',
    // },
    // '&$shrink': {
    //   transform: 'translate(0, 0) scale(1)',
    // },
  }),
});
const MaskedInputComponent = ({
  inputRef,
  maskInputProps = {},
  startAdornment,
  endAdornment,
  ...props
}) => {
  const {
    mask = value => Array.from(value, element => /./),
    guide = false,
    showMask = false,
  } = maskInputProps;
  return (
    <Flex alignItems={'center'} width={1}>
      {startAdornment &&
        React.isValidElement(startAdornment) &&
        React.cloneElement(startAdornment, { maxHeight: 30, ml: 2, mr: 2 })}
      <MaskedInput
        {...props}
        mask={mask}
        guide={guide}
        showMask={showMask}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
      />
      {endAdornment &&
        React.isValidElement(endAdornment) &&
        React.cloneElement(endAdornment, { maxHeight: 30, ml: 'auto', mr: 2 })}
    </Flex>
  );
};

const TextFieldBase = ({
  disabled,
  readOnly,
  isFormik = true,
  maxLength,
  minLength,
  field,
  customOnChange,
  customOnBlur,
  onChange,
  onBlur,
  maskInputProps,
  fullWidth,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const classes = useStyles(theme);
  const { onBlur: onFormikBlur, onChange: onFormikChange, ...restField } = field || {};
  const TextField = isFormik ? FormikTextField : MUITextField;
  const handleOnblur = !isFormik ? onBlur : customOnBlur || onFormikBlur;
  const handleOnChange = !isFormik ? onChange : customOnChange || onFormikChange;
  const { startAdornment, endAdornment, ...filterProps } = props;
  const propsBuilder = {
    InputProps: {
      classes: {
        root: readOnly ? classes.readOnly : classes.root,
        input: classes.input,
        multiline: classes.multiline,
      },
      inputComponent: MaskedInputComponent,
      inputProps: {
        maxLength: maxLength,
        minLength: minLength,
        maskInputProps: maskInputProps,
        ...props,
      },
      onBlur: handleOnblur,
      onChange: handleOnChange,
    },
    fullWidth: fullWidth,
    field: restField,
    ...filterProps,
  };
  return (
    <TextField
      variant="outlined"
      disabled={disabled || readOnly}
      InputLabelProps={{
        shrink: false,
        classes: {
          outlined: classes.outlined,
        },
      }}
      {...propsBuilder}
    />
  );
};

export default TextFieldBase;
