import { useQuery } from '@apollo/react-hooks';

import { getSectionReport } from 'GraphQL/queries/report.query';

const SectionReportData = ({ value, schoolCode, children }) => {
  const { term, grade, classNo, subject, syllabus, lesson, quizFilter } = value;

  const { loading, error, data, refetch } = useQuery(getSectionReport, {
    variables: {
      schoolTermId: term,
      schoolGradeId: grade !== `all-grade-selected` ? grade : null,
      classNo: classNo !== `all-class-selected` ? classNo : null,
      subjectId: subject,
      courseCode: syllabus,
      sectionCode: lesson !== `all-lesson-selected` ? lesson : null,
      schoolCode,
      quizFilter,
    },
    skip: !term || !grade || !classNo || !subject || !syllabus || !lesson || !schoolCode,
  });

  return children({ loading, error, data, refetch });
};

export default SectionReportData;
