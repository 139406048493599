import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';

const StyledSlider = styled(Slider)`
  &&& {
    color: ${({ theme, color }) => theme.colors[color]};
    height: 5px;
    cursor: ${({ progressMode }) => (progressMode ? 'default' : 'pointer')};
  }
  .thumb {
    height: 13px;
    width: 13px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 4px solid;
  }
  .track {
    height: 5px;
    border-radius: 4px;
  }
  .rail {
    height: 5px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.greyscale.grey3};
  }
`;

export const SimpleSlider = ({
  minValue,
  maxValue,
  value,
  showValue,
  progressMode,
  ...restProps
}) => {
  return (
    <StyledSlider
      classes={{
        thumb: 'thumb',
        track: 'track',
        rail: 'rail',
      }}
      min={minValue}
      max={maxValue}
      {...(progressMode ? { value, progressMode } : { defaultValue: value })}
      valueLabelDisplay={showValue}
      {...restProps}
    />
  );
};

SimpleSlider.propTypes = {
  color: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  value: PropTypes.number,
  showValue: PropTypes.string, // 'auto' || 'on' || 'off'
  progressMode: PropTypes.bool,
};

SimpleSlider.defaultProps = {
  color: 'inprogress',
  minValue: 0,
  maxValue: 100,
  value: 0,
  showValue: 'auto',
  progressMode: false,
};

export default SimpleSlider;
