import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'use-media';

import {
  Box,
  List,
  LIST_TYPE,
  ListItem,
  Text as BaseText,
  Flex,
  BorderBox,
  Icon,
  ICON_LIST,
} from 'Components/Base';
import Profile from 'Components/Profile';
import LSPUserData from 'Domains/LSPUserData';

const Text = styled(BaseText.Hint)`
  word-break: break-word;
`;

function StudentProfileBox(props) {
  const { userId } = props;
  const theme = useContext(ThemeContext);
  const isBreakpointsMd = useMedia({ minWidth: theme.breakpoints.md });
  const isBreakpointsLg = useMedia({ minWidth: theme.breakpoints.lg });
  const { t } = useTranslation('dashboard');
  return (
    <LSPUserData userId={userId}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return null;
        const {
          foProfile: {
            firstname,
            lastname,
            nickname,
            title,
            avatar,
            email,
            phoneNumber,
            position,
            schoolGrade: { name: schoolGradeName },
          },
        } = data;
        const items = [
          {
            iconName: ICON_LIST.MAIL,
            text: email,
          },
          {
            iconName: ICON_LIST.PHONE,
            text: phoneNumber,
          },
          {
            iconName: ICON_LIST.RANK,
            text: position,
          },
        ];

        return (
          <Flex
            width={{ _: 1, lg: '180px' }}
            flexDirection={{ _: 'column', md: 'row', lg: 'column' }}
            alignItems={'center'}
            height={{ _: '100%', lg: 'unset' }}
          >
            <Profile
              imageSrc={avatar}
              userDisplayName={`${nickname}, ${schoolGradeName}`}
              userTitle={`${title}${firstname} ${lastname}`}
              profileVariant={
                isBreakpointsLg ? Profile.VARIANT.VERTICAL : Profile.VARIANT.HORIZONTAL
              }
              bg={'white'}
              style={
                isBreakpointsLg
                  ? { borderRadius: '0px' }
                  : isBreakpointsMd
                  ? { borderRadius: '10px 0px 0px 10px', height: '100%' }
                  : { borderRadius: '10px' }
              }
              iconContainerProps={{ lg: { mb: 4 } }}
            />
            <BorderBox
              display={{ _: 'none', md: 'block' }}
              borderRadius={{ _: '0px 10px 10px 0px', lg: '0px' }}
              bg={'white'}
            >
              <Text my={3}>{t('PROFILE')}</Text>
              <List
                items={items}
                renderItem={({ iconName, text }, index) => (
                  <ListItem
                    key={index}
                    icon={<Icon name={iconName} size={20} color={theme.colors.primary} />}
                    iconContainerProps={{ mr: 4 }}
                    variant={'horizontal'}
                    containerProps={{
                      flex: '1 0 auto',
                      width: 1,
                      p: 1,
                    }}
                  >
                    <Flex flexDirection={'column'} alignItems={'flex-start'}>
                      <Box maxWidth={'140px'}>
                        <Text>{text}</Text>
                      </Box>
                    </Flex>
                  </ListItem>
                )}
                columns={[]}
                columnSpacing={`${theme.space[0]}px`}
                rowSpacing={'0px'}
                style={{ margin: '0px' }}
                listType={LIST_TYPE.HORIZONTAL_LIST}
              />
            </BorderBox>
          </Flex>
        );
      }}
    </LSPUserData>
  );
}

export default StudentProfileBox;
