import React, { Fragment } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text, Box, Flex, Icon, ICON_LIST } from 'Components/Base';

const Wrapper = styled(Box).attrs(({ variant }) => ({
  color: variant === 'light' ? 'black' : variant === 'dark' ? 'white' : 'initial',
}))`
  a {
    text-decoration: none;
    color: ${({ color }) => color};

    &:last-child {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
  }
`;

const ArrowIcon = () => {
  return (
    <Text fontSize={5} color={'primary'} display={'flex'}>
      <Icon name={ICON_LIST.ARROW_RIGHT} />
    </Text>
  );
};

const BreadcrumbItem = ({ index, title, length }) => {
  return (
    <Flex alignItems={'center'} flex="0 0 auto">
      <Text mr="1">{title}</Text>
      {index === length - 1 ? '' : <ArrowIcon />}
    </Flex>
  );
};

const Breadcrumb = props => {
  const { items, variant, maxItemsPerRow } = props;

  if (maxItemsPerRow && !isNaN(maxItemsPerRow)) {
    const rowItems = _.chunk(items, maxItemsPerRow);
    return (
      <Box>
        {rowItems.map((items, index) => (
          <Wrapper p={2} key={index} variant={variant} {...props}>
            <Flex flexDirection="row" flexWrap="wrap" style={{ gap: '4px' }}>
              {items.map(({ key, title }, index) => (
                <BreadcrumbItem key={key} title={title} index={index} length={items.length} />
              ))}
              {index === rowItems.length - 1 ? '' : <ArrowIcon />}
            </Flex>
          </Wrapper>
        ))}
      </Box>
    );
  }

  return (
    <Wrapper p={3} variant={variant} {...props}>
      <Flex flexDirection="row" style={{ gap: '4px' }}>
        {items.map(({ key, title }, index) => (
          <BreadcrumbItem key={key} title={title} index={index} length={items.length} />
        ))}
      </Flex>
    </Wrapper>
  );
};
Breadcrumb.defaultProps = {
  variant: 'light',
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  variant: PropTypes.oneOf(['light', 'dark']),
  maxItemsPerRow: PropTypes.number,
};

export default Breadcrumb;
