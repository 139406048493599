import React from 'react';
import PropTypes, { string } from 'prop-types';

import { Flex, Text } from 'Components/Base';
import LegendItem from 'Components/LegendItem';

const Criteria = ({ title = '', values = [] }) => {
  return (
    <Flex>
      <Text fontWeight="semibold">{title ? `${title}: ` : ''}</Text>
      {values.map(value => (
        <LegendItem
          key={`criteria_${value.text}`}
          mx={2}
          legendText={value.text}
          legendColor={value.color}
        />
      ))}
    </Flex>
  );
};

Criteria.propTypes = {
  title: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.objectOf({ text: string, color: string })).isRequired,
};

export default Criteria;
