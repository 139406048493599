import React, { useState } from 'react';
import { withRouter, Redirect, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { compose, branch, renderComponent } from 'recompose';
import { useTranslation } from 'react-i18next';

import { withAuth, AUTH_STATE } from 'Libs/Auth';
import { COLOR } from 'Components/Theme';
import { Text, Button, Box, Image, Flex, Icon, ICON_LIST, Form as BaseForm } from 'Components/Base';

const { FormContainer, Form, TextField } = BaseForm;

const FormControl = styled(Form)`
  width: 100%;

  & .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    color: ${({ theme }) => theme.colors.primary};
    margin-left: 22px;
  }

  & input {
    border-radius: 100px;
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.primary};

    &:placeholder {
      color: ${({ theme }) => theme.colors.primary};
    }

    &.MuiOutlinedInput-input:-webkit-autofill {
      border-radius: 100px;
    }
  }
`;

const ResetPasswordView = props => {
  const { t } = useTranslation('login');
  const [apiError, setApiError] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get('token');

  const handleSubmit = (values, actions) => {
    const { password } = values;
    actions.setSubmitting(false);

    props.auth
      .resetPassword(password, token)
      .then(() => {
        setIsCompleted(true);
      })
      .catch(error => {
        setApiError(error.response.data.error);
      });
  };

  const handleInputValidation = values => {
    const { password, verifyPassword } = values;
    const errors = {};
    if (password.length === 0) {
      errors.password = t('PASSWORD_REQUIRED');
    }
    if (password.length < 6) {
      errors.password = t('PASSWORD_LENGTH_REQUIRED');
    }
    if (verifyPassword.length === 0) {
      errors.verifyPassword = t('VERIFY_PASSWORD_REQUIRED');
    }
    if (password !== verifyPassword) {
      errors.verifyPassword = t('VERIFY_PASSWORD_REQUIRED');
    }
    return errors;
  };

  const getFailMessage = error => {
    const { name, statusCode, message } = error;
    if (statusCode === 401) {
      if (name === 'UnauthorizedError') {
        if (message === 'Email has not been verified') {
          return t('ERROR_NOT_VERIFY');
        } else if (message === 'User not found') {
          return t('ERROR_USER_NOT_FOUND');
        }
      }
      return t('ERROR_INCORRECT_AUTHENTICATION');
    } else if (statusCode >= 500) {
      return t('ERROR_SERVER_ERROR');
    }
    return t('ERROR_DEFAULT');
  };
  if (isCompleted) {
    return (
      <Flex p={{ _: 3, md: 5, lg: 8 }} flexDirection={'column'}>
        <Box alignSelf={'center'} mb={{ _: 5, md: 8 }}>
          <Image
            src="/assets/components/AppLayout/logo-light-white.png"
            srcSet="/assets/components/AppLayout/logo-light-white.png 1x, /assets/components/AppLayout/logo-light-white@2x.png 2x, /assets/components/AppLayout/logo-light-white@3x.png 3x"
            width="170"
          />
        </Box>

        <Box alignSelf={'center'} mb={4}>
          <Icon name={ICON_LIST.CHECK} size={33} color={COLOR.primary} />
        </Box>

        <Box alignSelf={'center'} mb={6}>
          <Text.SubTitle color="white">{t('SUCCESSFULLY_RESET_PASSWORD')}</Text.SubTitle>
        </Box>
        <Button
          variant="primary"
          width={'100%'}
          onClick={() => {
            props.history.push('/login');
          }}
        >
          {t('SIGNIN')}
        </Button>
        <Box alignSelf={'center'} mb={9}></Box>
      </Flex>
    );
  }
  return (
    <Flex p={{ _: 3, md: 5, lg: 8 }} flexDirection={'column'}>
      <Box alignSelf={'center'} mb={{ _: 5, md: 8 }}>
        <Image
          src="/assets/components/AppLayout/logo-light-white.png"
          srcSet="/assets/components/AppLayout/logo-light-white.png 1x, /assets/components/AppLayout/logo-light-white@2x.png 2x, /assets/components/AppLayout/logo-light-white@3x.png 3x"
          width="170"
        />
      </Box>

      <Box alignSelf={'center'} mb={7}>
        <Text.SubTitle color="white">{t('RESET_PASSWORD_TITLE')}</Text.SubTitle>
      </Box>

      <FormContainer
        initialValues={{ password: '', verifyPassword: '' }}
        isInitialValid={false}
        validate={handleInputValidation}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange, handleBlur, isValid }) => (
          <FormControl>
            <Flex flexDirection={'column'} width={1}>
              {apiError && (
                <Box pb={2}>
                  <Text color="danger" textAlign="center">
                    <Icon name={Icon.ICON_LIST.WARNING} /> {getFailMessage(apiError)}
                  </Text>
                </Box>
              )}
              <TextField.Input
                name="password"
                type="password"
                maxLength={100}
                label={t('PASSWORD')}
                placeholder={t('PASSWORD_PLACEHOLDER')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              <Box p={4} />
              <TextField.Input
                name="verifyPassword"
                type="password"
                maxLength={100}
                label={t('VERIFY_PASSWORD')}
                placeholder={t('VERIFY_PASSWORD_PLACEHOLDER')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.verifyPassword}
              />
              <Box p={7} />
              <Button
                id="signin-email"
                variant="primary"
                type="submit"
                data-testid="login-form-submit-login"
                disabled={!isValid}
                width={'100%'}
              >
                {t('RESET_PASSWORD')}
              </Button>
            </Flex>
          </FormControl>
        )}
      </FormContainer>
    </Flex>
  );
};

export { ResetPasswordView };
export default compose(
  withAuth,
  withRouter,
  branch(
    props => props.auth.authState === AUTH_STATE.AUTHENTICATED,
    renderComponent(props => {
      const { from } = props.location.state || { from: { pathname: '/' } };
      return <Redirect to={from} />;
    }),
  ),
)(ResetPasswordView);
