import * as Yup from 'yup';
import cardValidator from 'card-validator';

export const EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ENGLISH_THAI_ALPHABET_OR_SPACE = /^([\u0e00-\u0e7e]|[A-Za-z])*$/;
// Start with at least 1 eng-letter, Can follow by space or eng-letter or number , End with eng-letter
export const ENGLISH_ALPHABET_NUMBERIC_SPACE = /^[a-z][a-z0-9\s]*[a-z0-9]$/i;
// match 9 digit | 10 digit | empty string
export const PHONE = /^((\d{9})|(\d{10}))?$/;
export const PASSWORD_MINIMUM = 6;
export const CREDIT_CARD_MASK = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const CREDIT_CARD_EXPIRE_DATE_MASK = (value = '') => {
  switch (value.charAt(0)) {
    case '0':
      return ['0', /[1-9]/, '/', /\d/, /\d/];
    case '1':
      return ['1', /[0-2]/, '/', /\d/, /\d/];
    default:
      return ['0', /\d/, '/', /\d/, /\d/];
  }
};
export const CREDIT_CARD_CVV_MASK = (length = 3) => Array.from({ length }).map(() => /\d/);
export const minLength = (minLength, errorText = `Min Length ${minLength}`) => value => {
  if (value === null || value === undefined) return undefined;
  if (value.length < minLength) return errorText;
  return undefined;
};

export const maxLength = (maxLength, errorText = `Max Length ${maxLength}`) => value => {
  if (value === null || value === undefined) return undefined;
  if (value.length > maxLength) return errorText;
  return undefined;
};

export const isEmail = (errorText = `Please enter correct email format`) => email => {
  if (EMAIL.test(email)) return undefined;
  return errorText;
};

export const isNameValid = (errorText = `Please enter correct name format`) => text => {
  if (ENGLISH_THAI_ALPHABET_OR_SPACE.test(text)) return undefined;
  return errorText;
};

export const isPhoneNumber = (
  errorText = 'Please enter correct phone number format',
) => phoneNumber => {
  if (PHONE.test(phoneNumber)) return undefined;
  return errorText;
};

export const validationChain = (validationArray = []) => value => {
  validationArray.reduce(
    (prevValue, currentValidation) => (prevValue ? prevValue : currentValidation(value)),
    undefined,
  );
};

export const NeedEmailVerificationFormSchema = (
  isRequired,
  errorMessages = {
    firstName: '',
    lastName: '',
    newEmail: { error: '', required: '' },
  },
) => {
  const customRequired = requiredValidation(isRequired);

  Yup.addMethod(Yup.string, 'customRequired', customRequired);

  return Yup.object().shape({
    firstName: Yup.string().customRequired(errorMessages.firstName),
    lastName: Yup.string().customRequired(errorMessages.lastName),
    newEmail: Yup.string()
      .email(errorMessages.newEmail.error)
      .required(errorMessages.newEmail.required),
  });
};

const requiredValidation = isRequired =>
  isRequired ? Yup.mixed().required : Yup.mixed().notRequired;

export const CreditCardSchema = (
  errorMessages = {
    cardnumber: { invalid: '', required: '' },
    ccname: { invalid: '', required: '' },
    'cc-exp': { invalid: '', required: '' },
    cvc: { invalid: '', required: '' },
  },
) => {
  const invalidMessage = key => errorMessages[key].invalid;
  const requiredMessage = key => errorMessages[key].required;

  const isValidCardNumber = (value, cardValidatorObject) => {
    const { card = null, isPotentiallyValid = false, isValid = false } = cardValidatorObject;
    if (card && value) {
      return card.lengths.includes(value.replace(/ /gi, '').length) && isValid;
    }
    return false;
  };

  const isValidCardExpireDate = value => {
    const { isValid } = cardValidator.expirationDate(value);
    return isValid;
  };

  const isValidCardCode = (value, cardValidatorObject) => {
    const { card = null, isPotentiallyValid = false } = cardValidatorObject;
    let isValid = false;
    if (isPotentiallyValid) {
      isValid = true;
    }
    if (card && value) {
      isValid = value.length === card.code.size;
    }
    return isValid;
  };

  return Yup.lazy(({ cardnumber }) => {
    const cardValidatorObject = cardValidator.number(cardnumber);
    return Yup.object().shape({
      cardnumber: Yup.string()
        .required(requiredMessage('cardnumber'))
        .test('isValidCardNumber', invalidMessage('cardnumber'), value =>
          isValidCardNumber(value, cardValidatorObject),
        ),
      ccname: Yup.string()
        .required(requiredMessage('ccname'))
        .matches(ENGLISH_ALPHABET_NUMBERIC_SPACE, invalidMessage('ccname')),
      'cc-exp': Yup.string()
        .required(requiredMessage('cc-exp'))
        .test('isValidCardExpireDate', invalidMessage('cc-exp'), value =>
          isValidCardExpireDate(value),
        ),
      cvc: Yup.string()
        .required(requiredMessage('cvc'))
        .test(
          'isValidCardCode',
          cardValidatorObject.isPotentiallyValid
            ? invalidMessage('cvc')
            : invalidMessage('cardnumber'),
          value => isValidCardCode(value, cardValidatorObject),
        ),
    });
  });
};

export const PersonSchema = (
  isRequired,
  errorMessages = {
    fname: { invalid: '', required: '' },
    lname: { invalid: '', required: '' },
    email: { invalid: '', required: '' },
    phone: { invalid: '', required: '' },
  },
) => {
  const customRequired = requiredValidation(isRequired);
  Yup.addMethod(Yup.string, 'customRequired', customRequired);

  const invalidMessage = key => errorMessages[key].invalid;
  const requiredMessage = key => errorMessages[key].required;

  return Yup.object().shape({
    fname: Yup.string()
      .matches(ENGLISH_THAI_ALPHABET_OR_SPACE, invalidMessage('fname'))
      .customRequired(requiredMessage('fname')),
    lname: Yup.string()
      .matches(ENGLISH_THAI_ALPHABET_OR_SPACE, invalidMessage('lname'))
      .customRequired(requiredMessage('lname')),
    email: Yup.string()
      .email(invalidMessage('email'))
      .customRequired(requiredMessage('email')),
    phone: Yup.string()
      .matches(PHONE, invalidMessage('phone'))
      .customRequired(requiredMessage('phone')),
  });
};

export const AttendeesSchema = (
  isRequired,
  errorMessages = {
    fname: { invalid: '', required: '' },
    lname: { invalid: '', required: '' },
    email: { invalid: '', required: '' },
    phone: { invalid: '', required: '' },
    required: '',
    minimum: '',
  },
  number,
) => {
  return Yup.object().shape({
    attendees: Yup.array()
      .of(PersonSchema(isRequired, errorMessages))
      .required(errorMessages.required)
      .min(number, errorMessages.minimum),
  });
};

export const WorkshopIndividualSchema = (
  errorMessages = {
    fname: { invalid: '', required: '' },
    lname: { invalid: '', required: '' },
    email: { invalid: '', required: '' },
    phone: { invalid: '', required: '' },
    required: '',
    minimum: '',
  },
  number,
  isContactRequired,
  isAttendeesRequired,
) => {
  return PersonSchema(isContactRequired, errorMessages).concat(
    AttendeesSchema(isAttendeesRequired, errorMessages, number),
  );
};

export const BusinessAddressSchema = (
  isRequired,
  errorMessages = {
    company: '',
    address: '',
    branch: '',
    taxId: {
      required: '',
      invalid: '',
    },
  },
) => {
  const customRequired = requiredValidation(isRequired);

  Yup.addMethod(Yup.string, 'customRequired', customRequired);

  return Yup.object().shape({
    company: Yup.string().customRequired(errorMessages.company),
    address: Yup.string().customRequired(errorMessages.address),
    taxId: Yup.string()
      .min(13, errorMessages.taxId.invalid)
      .max(13, errorMessages.taxId.invalid)
      .customRequired(errorMessages.taxId.required),
    branch: Yup.string().customRequired(errorMessages.branch),
  });
};

export const BusinessBillingSchema = (
  isBusinessAddressRequired = true,
  isContactRequired = true,
  errorMessages = {
    fname: { invalid: '', required: '' },
    lname: { invalid: '', required: '' },
    email: { invalid: '', required: '' },
    phone: { invalid: '', required: '' },
    company: '',
    address: '',
    branch: '',
    taxId: {
      required: '',
      invalid: '',
    },
  },
) => {
  return BusinessAddressSchema(isBusinessAddressRequired, errorMessages).concat(
    PersonSchema(isContactRequired, errorMessages),
  );
};

export const OnlineOrganizationSchema = (
  errorMessages,
  isBusinessAddressRequired = true,
  isContactRequired = true,
  isStudentRequired = false,
) => {
  return Yup.object()
    .shape({ student: PersonSchema(isStudentRequired, errorMessages) })
    .concat(BusinessBillingSchema(isBusinessAddressRequired, isContactRequired, errorMessages));
};

export const WorkshopOrganizationSchema = (
  errorMessages,
  number,
  isBusinessAddressRequired = true,
  isContactRequired = true,
  isAttendeesRequired = false,
) => {
  return BusinessBillingSchema(isBusinessAddressRequired, isContactRequired, errorMessages).concat(
    AttendeesSchema(isAttendeesRequired, errorMessages, number),
  );
};

export const NameSchema = (isRequired, errorMessages) => {
  const customRequired = requiredValidation(isRequired);
  Yup.addMethod(Yup.string, 'customRequired', customRequired);

  const requiredMessage = key => errorMessages[key].required;

  return Yup.object().shape({
    firstName: Yup.string().customRequired(requiredMessage('firstName')),
    lastName: Yup.string().customRequired(requiredMessage('lastName')),
  });
};

export const ChangePasswordSchema = errorMessages => {
  const requiredMessage = key => errorMessages[key].required;
  return Yup.object().shape({
    oldPassword: Yup.string().required(requiredMessage('oldPassword')),
    newPassword: Yup.string()
      .min(PASSWORD_MINIMUM, requiredMessage('newPassword'))
      .required(requiredMessage('newPassword')),
    newPasswordVerify: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], errorMessages.newPasswordVerify.notMatch)
      .required(requiredMessage('newPasswordVerify')),
  });
};

/**
 * Transform Yup ValidationError to a more usable object
 */
const yupToFormErrors = yupError => {
  let errors = {};
  if (yupError.inner) {
    if (yupError.inner.length === 0) {
      errors[yupError.path] = yupError.message;
      return errors;
    }
    for (let err of yupError.inner) {
      if (!err[err.path]) {
        errors[err.path] = err.message;
      }
    }
  }
  return errors;
};

export const yupValidate = (values, schema) => {
  try {
    schema.validateSync(values);
  } catch (err) {
    if (err.name === 'ValidationError') {
      return yupToFormErrors(err);
    }
  }
  return {};
};
