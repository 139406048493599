import React from 'react';
import PropTypes from 'prop-types';

import { Box, Layout } from '../Base';

const ToolbarContainer = ({
  topToolbar,
  topToolbarProps,
  content,
  contentProps,
  bottomStatusBar,
  bottomStatusBarProps,
}) => {
  return (
    <Box>
      <Layout
        bg={'white'}
        style={{
          borderRadius: '10px',
        }}
        top={topToolbar}
        topProps={{
          py: 3,
          px: 8,
          style: {
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          },
          ...topToolbarProps,
        }}
        main={content}
        mainProps={
          bottomStatusBar
            ? {
                py: 3,
                px: 8,
                ...contentProps,
              }
            : {
                py: 3,
                px: 8,
                style: {
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                },
                ...contentProps,
              }
        }
        bottom={bottomStatusBar}
        bottomProps={{
          py: 3,
          px: 5,
          style: {
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
          },
          ...bottomStatusBarProps,
        }}
      />
    </Box>
  );
};

ToolbarContainer.propTypes = {
  topToolbar: PropTypes.element,
  topToolbarProps: PropTypes.object,
  content: PropTypes.element,
  contentProps: PropTypes.object,
  bottomStatusBar: PropTypes.element,
  bottomStatusBarProps: PropTypes.object,
};

export default ToolbarContainer;
