import { useQuery } from '@apollo/react-hooks';

import { getAllSchools } from 'GraphQL/queries/leSchools.query';

const LEAdminSchoolData = props => {
  const { children } = props;
  const { loading, error, data } = useQuery(getAllSchools);
  return children({ loading, error, data });
};

export default LEAdminSchoolData;
