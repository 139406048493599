import { getFilterData } from 'Containers/AttendanceFilter';
export const updatedFilter = (
  optionList,
  filterValues,
  onUpdateValue,
  { ignoreClass = false, ignoreLesson = false },
) => {
  const filterData = getFilterData(optionList, filterValues);
  if (
    !filterData.term &&
    (filterData.grade ||
      filterData.classNo ||
      filterData.subject ||
      filterData.syllabus ||
      filterData.lesson)
  ) {
    onUpdateValue({
      term: null,
      grade: null,
      classNo: !ignoreClass ? null : filterData.classNo,
      subject: null,
      syllabus: null,
      lesson: !ignoreLesson ? null : filterData.lesson,
    });
  } else if (
    !filterData.grade &&
    (filterData.classNo || filterData.subject || filterData.syllabus || filterData.lesson)
  ) {
    onUpdateValue({
      ...filterValues,
      grade: null,
      classNo: !ignoreClass ? null : filterData.classNo,
      subject: null,
      syllabus: null,
      lesson: !ignoreLesson ? null : filterData.lesson,
    });
  } else if (
    !filterData.classNo &&
    !ignoreClass &&
    (filterData.subject || filterData.syllabus || filterData.lesson)
  ) {
    onUpdateValue({
      ...filterValues,
      classNo: !ignoreClass ? null : filterData.classNo,
      subject: null,
      syllabus: null,
      lesson: !ignoreLesson ? null : filterData.lesson,
    });
  } else if (!filterData.subject && (filterData.syllabus || filterData.lesson)) {
    onUpdateValue({
      ...filterValues,
      subject: null,
      syllabus: null,
      lesson: !ignoreLesson ? null : filterData.lesson,
    });
  } else if (!filterData.syllabus && filterData.lesson) {
    onUpdateValue({
      ...filterValues,
      syllabus: null,
      lesson: !ignoreLesson ? null : filterData.lesson,
    });
  }
};
