import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import {
  getSchoolTerms,
  getGrades,
  getClassNos,
  getSubjects,
  getSyllabuses,
  getLessons,
} from 'GraphQL/queries/attendanceFilter.query';

const AttendanceFilterData = props => {
  const { children, initialValue, schoolCode, isShowClassFilter = true } = props;

  const [term, setTerm] = useState(null);
  const [grade, setGrade] = useState(null);
  const [classNo, setClassNo] = useState(null);
  const [subject, setSubject] = useState(null);
  const [syllabus, setSyllabus] = useState(null);
  const [lesson, setLesson] = useState(null);

  useEffect(() => {
    if (initialValue) {
      updateValue(initialValue);
    }
  }, [initialValue]);

  const updateValue = ({ term, grade, classNo, subject, syllabus, lesson }) => {
    setTerm(term);
    setGrade(grade);
    setClassNo(classNo);
    setSubject(subject);
    setSyllabus(syllabus);
    setLesson(lesson);
  };

  const { data: termList } = useQuery(getSchoolTerms, {
    variables: { schoolCode },
    skip: !schoolCode,
    notifyOnNetworkStatusChange: true,
  });
  const { data: gradeList } = useQuery(getGrades, {
    variables: { schoolTermId: term, schoolCode },
    skip: !term || !schoolCode,
    notifyOnNetworkStatusChange: true,
  });

  const { data: classList } = useQuery(getClassNos, {
    variables: {
      schoolTermId: term,
      schoolGradeId: grade !== `all-grade-selected` ? grade : null,
      schoolCode,
    },
    skip: !term || !grade || !schoolCode,
    notifyOnNetworkStatusChange: true,
  });

  const { loading: subjectLoading, error: subjectError, data: subjectList } = useQuery(
    getSubjects,
    {
      variables: {
        schoolTermId: term,
        schoolGradeId: grade !== `all-grade-selected` ? grade : null,
        classNo: classNo !== `all-class-selected` && isShowClassFilter ? classNo : null,
        schoolCode,
      },
      skip: !term || !grade || !classNo || !schoolCode,
      notifyOnNetworkStatusChange: true,
    },
  );

  const { loading: syllabusLoading, error: syllabusError, data: syllabusList } = useQuery(
    getSyllabuses,
    {
      variables: {
        schoolTermId: term,
        schoolGradeId: grade !== `all-grade-selected` ? grade : null,
        classNo: classNo !== `all-class-selected` && isShowClassFilter ? classNo : null,
        subjectId: subject,
        schoolCode,
      },
      skip: !term || !grade || !classNo || !subject || !schoolCode,
      notifyOnNetworkStatusChange: true,
    },
  );

  const { data: lessonList } = useQuery(getLessons, {
    variables: {
      courseCode: syllabus,
    },
    skip: !syllabus,
    notifyOnNetworkStatusChange: true,
  });

  return children({
    updateValue,
    value: { term, grade, classNo, subject, syllabus, lesson },
    list: {
      termList,
      gradeList,
      classList,
      subjectList,
      syllabusList,
      lessonList,
    },
  });
};

export default AttendanceFilterData;
