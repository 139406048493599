import { useQuery } from '@apollo/react-hooks';

import { getStudentPracQuizProgressByUserId } from 'GraphQL/queries/modules.query';

const StudentPracQuizProgressData = props => {
  const { userId, startDate, endDate, children } = props;
  const { loading, error, data } = useQuery(getStudentPracQuizProgressByUserId, {
    variables: { search: { userId, dateRange: [startDate, endDate] } },
  });
  return children({ loading, error, data });
};

export default StudentPracQuizProgressData;
