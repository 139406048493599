import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/react-hooks';
import { I18nextProvider } from 'react-i18next';

import RouteApp from 'Routes';
import store from 'Redux/store';
import AuthProvider from 'Libs/Auth';
import AnalyticsProvider, { UniversalAnalyticsProvider } from 'Libs/Analytics';
import ConfigProvider, { withConfig } from 'Libs/Config';

import getApolloClient from './GraphQL';
import GlobalStyle from './Components/Theme/GlobalStyle';
import i18n from './config/i18n';

const App = withConfig(({ appConfig: { measurementId, gaId, userApi, theme, graphApi } }) => (
  <AnalyticsProvider trackingCode={measurementId} debug>
    <UniversalAnalyticsProvider trackingCode={gaId} debug>
      <ApolloProvider client={getApolloClient(graphApi)}>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <AuthProvider userApi={userApi}>
              <ThemeProvider theme={theme} style={{ height: '100%' }}>
                <GlobalStyle />
                <RouteApp />
              </ThemeProvider>
            </AuthProvider>
          </Provider>
        </I18nextProvider>
      </ApolloProvider>
    </UniversalAnalyticsProvider>
  </AnalyticsProvider>
));

const AppwithConfig = () => (
  <ConfigProvider>
    <App />
  </ConfigProvider>
);

export default AppwithConfig;
