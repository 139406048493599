import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

import AuthConsumer from './AuthConsumer';

const withAuth = WrappedComponent => {
  const HOC = props => {
    const { forwardedRef, ...restProps } = props;
    return (
      <AuthConsumer>
        {authProps => <WrappedComponent {...restProps} auth={authProps} ref={forwardedRef} />}
      </AuthConsumer>
    );
  };

  hoistNonReactStatics(HOC, WrappedComponent);

  return HOC;
};

export { withAuth };
