import { useQuery } from '@apollo/react-hooks';

import { getStudentPracQuizByUserId } from 'GraphQL/queries/modules.query';

const PracQuizTableData = props => {
  const { userId, startDate, endDate, searchText, children } = props;
  const { loading, error, data } = useQuery(getStudentPracQuizByUserId, {
    variables: {
      search: {
        userId,
        dateRange: [startDate, endDate],
        // searchText, // TODO: Implement Client Filter after having data
      },
    },
  });
  return children({ loading, error, data });
};

export default PracQuizTableData;
