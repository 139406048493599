import { useQuery } from '@apollo/react-hooks';

import { getCourseGradeReport } from '../../GraphQL/queries/report.query';

const CourseGradeReportData = ({ value, schoolCode, children }) => {
  const { term, grade, subject } = value;

  const { loading, error, data, refetch } = useQuery(getCourseGradeReport, {
    variables: {
      schoolTermId: term,
      schoolGradeId: grade,
      subjectId: subject,
      schoolCode,
    },
    skip: !term || !grade || !subject || !schoolCode,
  });

  return children({ loading, error, data, refetch });
};

export default CourseGradeReportData;
