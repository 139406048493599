import { useQuery } from '@apollo/react-hooks';

import { getCurrentSchoolYear } from 'GraphQL/queries/modules.query';

const CurrentSchoolYearData = props => {
  const { children } = props;
  const { loading, error, data } = useQuery(getCurrentSchoolYear);
  return children({ loading, error, data });
};

export default CurrentSchoolYearData;
