import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose, mapProps } from 'recompose';
import moment from 'moment';
import { withAnalytic } from '@skooldio/analytics-provider/dist/Analytics/hoc';
import styled from 'styled-components';

import { Text, Box, Flex, Icon, ICON_LIST } from 'Components/Base';
import StudentSummary from 'Containers/StudentSummary';
import StudentSummaryExporter from 'Containers/StudentSummaryExporter';
import { getSchoolCode } from 'Domains/LESchoolData/Routing';
import ButtonOutlinedIcon from 'Components/ButtonOutlinedIcon';
import { useAnalytics } from 'Libs/Analytics';

const getUpdateTime = () => {
  return moment().format('DD/MM/YYYY HH:mm');
};

const StudentSummaryPageWrapper = styled(Box)`
  border-radius: 10px;
  flex: 1 0;
  display: grid;
  grid: auto auto 1fr / 100%;
  row-gap: 16px;
`;

const StudentSummaryPage = ({ schoolCode, onLogAction, auth: { myProfile }, ...props }) => {
  const { send } = useAnalytics();
  const { t } = useTranslation('studentSummary');
  const [isFetchData, setIsFetchData] = useState(false);
  const [disabledFetch, setDisabledFetch] = useState(true);
  const [appliedFilter, setAppliedFilter] = useState({});

  const handleFilterApplied = filter => setAppliedFilter(filter);

  return (
    <StudentSummaryPageWrapper px={[0, null, 2, 7]} py={[0, null, 3, 5]} bg={'white'}>
      <Box>
        <Flex alignItems={'center'} justifyContent={'space-between'}>
          <Text.Title>{t('TITLE')}</Text.Title>
          <Flex>
            <StudentSummaryExporter
              disabled={disabledFetch}
              appliedFilter={appliedFilter}
              schoolCode={schoolCode}
              myProfileId={myProfile.id}
            />
            <ButtonOutlinedIcon
              ml={2}
              textProp={{
                fontWeight: 'semibold',
              }}
              lineHeight={'0.5'}
              height={'40px'}
              leftIcon={<Icon name={ICON_LIST.REFRESH} />}
              message={t('UPDATE')}
              disabled={disabledFetch}
              onClick={() => {
                onLogAction({
                  category: 'Fatch data',
                  action: `Click`,
                  label: `Click on section page`,
                });
                send('update', { on: 'section_page' });
                setIsFetchData(true);
              }}
            />
          </Flex>
        </Flex>
        <Flex justifyContent={'flex-end'} height={'16px'}>
          <Text.Hint
            mt={1}
            color={'timeUpdate'}
            textAlign={'right'}
            display={disabledFetch ? 'none' : 'block'}
          >
            {t('UPDATE_DESCRIPTION_TIME')} {getUpdateTime()}
          </Text.Hint>
        </Flex>
      </Box>

      <StudentSummary
        isFetchData={isFetchData}
        setIsFetchData={setIsFetchData}
        setDisabledFetch={setDisabledFetch}
        onFilterApplied={handleFilterApplied}
        schoolCode={schoolCode}
        myProfileId={myProfile.id}
      />
    </StudentSummaryPageWrapper>
  );
};

export default compose(mapProps(getSchoolCode), withAnalytic)(StudentSummaryPage);
