export const isNil = val => val === undefined || val === null;

export const extractGraphQLError = error => {
  const { message } = error;
  const firstBracket = message.indexOf('{');
  const lastBracket = message.lastIndexOf('}') + 1;
  if (firstBracket >= 0 && lastBracket >= 0) {
    const JSONMessage = JSON.parse(
      message.substring(firstBracket, lastBracket).replace(/(\w+):/g, `"$1":`),
    );
    return JSONMessage;
  } else {
    const { graphQLErrors } = error;
    return graphQLErrors[0];
  }
};
