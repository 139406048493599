import React, { useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { compose, branch, renderComponent } from 'recompose';
import { useTranslation } from 'react-i18next';

import { withAuth, AUTH_STATE } from 'Libs/Auth';
import { COLOR } from 'Components/Theme';
import {
  Text,
  Button,
  Box,
  Image,
  Flex,
  Icon,
  ICON_LIST,
  Form as BaseForm,
  Link as BaseLink,
} from 'Components/Base';

const Link = styled(BaseLink)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:active {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const ForgetPasswordView = props => {
  const { t } = useTranslation('login');

  return (
    <Flex p={{ _: 3, md: 5, lg: 8 }} flexDirection={'column'}>
      <Box alignSelf={'center'} mb={{ _: 5, md: 8 }}>
        <Image
          src="/assets/components/AppLoginLayout/logo-le.png"
          srcSet="/assets/components/AppLoginLayout/logo-le.png 1x, /assets/components/AppLoginLayout/logo-le@2x.png 2x, /assets/components/AppLoginLayout/logo-le@3x.png 3x"
          width="170"
        />
      </Box>

      <Box alignSelf={'center'} mb={4}>
        <Text.SubTitle color="primary">{t('FORGET_PASSWORD_TITLE')}</Text.SubTitle>
      </Box>
      <Box alignSelf={'center'} mb={7}>
        <Text color="greyscale.grey2">{t('FORGET_PASSWORD_SUBTITLE')}</Text>
      </Box>
      <Box p={4} />
      <Link href={'/login'}>
        <Button href={'/login'} variant="primary" width={'100%'}>
          {t('BACK_TO_LOGIN_PAGE')}
        </Button>
      </Link>
    </Flex>
  );
};

export { ForgetPasswordView };
export default compose(
  withAuth,
  withRouter,
  branch(
    props => props.auth.authState === AUTH_STATE.AUTHENTICATED,
    renderComponent(props => {
      const { from } = props.location.state || { from: { pathname: '/' } };
      return <Redirect to={from} />;
    }),
  ),
)(ForgetPasswordView);
