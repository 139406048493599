import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';

import { Icon, ICON_LIST } from 'Components/Base';
import { COLOR } from 'Components/Theme';

const GreySearchInput = styled(TextField)`
  flex: 1;
  &&& {
    margin: 0px;
    padding: 0px;
  }
  .search-input {
    border-radius: 100px;
    background-color: ${({ theme }) => theme.colors.greyscale.grey4};
    color: ${({ theme }) => theme.colors.greyscale.grey1};
    padding-left: 15px;
    padding-right: 29px;
  }
  .search-outline {
    border: 0px;
  }

  input {
    font-family: ${({ theme }) => theme.fonts.sans};

    &::placeholder {
      font-family: ${({ theme }) => theme.fonts.sans};
    }
  }
`;

const SearchInput = ({ placeholder, onChange, ...restProps }) => {
  const debounceOnChange = _.debounce(onChange, 500);
  return (
    <GreySearchInput
      placeholder={placeholder}
      variant="outlined"
      onChange={e => debounceOnChange(e.target.value)}
      margin="dense"
      InputProps={{
        classes: {
          root: 'search-input',
          notchedOutline: 'search-outline',
        },
        endAdornment: <Icon name={ICON_LIST.SEARCH} size={20} color={COLOR.primary} />,
      }}
      {...restProps}
    />
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

SearchInput.defaultProps = {
  placeholder: '',
  onChange: () => {},
};

export default SearchInput;
