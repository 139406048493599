import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '../Base';

const TagItem = ({ tagColor = 'greyscale.grey3', children, ...props }) => {
  return (
    <Flex bg={tagColor} py={2} px={4} style={{ borderRadius: '100px' }} {...props}>
      {children}
    </Flex>
  );
};

TagItem.propTypes = {
  tagColor: PropTypes.string,
  children: PropTypes.element,
};

export default TagItem;
