import React, { useCallback, memo } from 'react';

import BreadcrumbLayout from 'Components/BreadcrumbLayout';
import Breadcrumb from 'Components/Breadcrumb';
import TabContent from 'Components/TabContent';
import TeacherStudents from 'Containers/TeacherStudents';
import { withAuth } from 'Libs/Auth';

const BreadcrumbComponent = memo(function BreadcrumbComponent() {
  const items = [
    {
      key: 'dashboard',
      title: 'Dashboard',
      path: '/teacher/dashboard',
    },
  ];
  return <Breadcrumb items={items} variant={'dark'} />;
});

const TeacherDashboardPage = props => {
  const {
    auth: {
      myProfile: { id: userId },
    },
  } = props;
  return (
    <BreadcrumbLayout
      breadcrumbComponent={useCallback(
        () => (
          <BreadcrumbComponent />
        ),
        [],
      )}
    >
      <TabContent content={<TeacherStudents userId={userId} path="dashboard" />} />
    </BreadcrumbLayout>
  );
};

export default memo(withAuth(TeacherDashboardPage));
