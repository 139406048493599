const USER_ROLE = {
  ADMINISTRATOR: 'administrator',
  ADVISOR_TEACHER: 'advisor-teacher',
  SUBJECT_TEACHER: 'subject-teacher',
  STUDENT: 'student',
  PARENT: 'parent',
  PROJECTOR_TEACHER: 'projector-teacher',
};

export default USER_ROLE;
