import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex, Text } from 'Components/Base';
import NavMenuItem from 'Components/NavMenuItem';

const NavWrapper = styled(Flex)`
  min-height: ${({ theme }) => theme.space.NAVBAR_NAVMENU_HEIGHT};
  align-items: center;
  & > :first-child {
    padding-left: 0;
  }
  & > :last-child {
    padding-right: 0;
  }
`;

const NavMenu = ({ menus }) => {
  return (
    <NavWrapper as="nav">
      {menus.map(({ path, title, imageIcon, action, ...other }) => {
        return (
          <NavMenuItem key={path} to={path} {...other} style={{ flex: '0 0 auto' }}>
            <Text onClick={action} fontWeight="semibold">
              {title}
            </Text>
          </NavMenuItem>
        );
      })}
    </NavWrapper>
  );
};

NavMenu.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default NavMenu;
