import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import TextFieldBase from './TextFieldBase';

const Input = TextFieldBase;

const FormikInput = ({ name, ...props }) => (
  <Fragment>
    <Field name={name} component={Input} {...props} />
  </Fragment>
);

FormikInput.propTypes = {
  name: PropTypes.string,
};

export default FormikInput;
