import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Table from 'Components/Table';
import { Text, Box, Flex } from 'Components/Base';
import TagItem from 'Components/TagItem';
import { MODULE_STATUS, RANK_STATUS } from 'Domains/module';

const TextWrapper = styled(Box)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

const AttendanceTableStyle = styled(Table)`
  & .MuiTableCell-sizeSmall {
    border-right: 1px solid ${({ theme }) => theme.colors.white};
    border-bottom: none;
    padding: 5px 10px;
    &:nth-last-child(1) {
      border-right: none;
    }
  }

  & .MuiTableCell-body {
    &:nth-last-child(-n + 2) {
      padding: 0;
      line-height: 2.6;
    }
  }

  & .MuiTableRow-head:nth-child(1) {
    & .MuiTableCell-head {
      &:nth-child(n + 5) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.white};
      }
    }
  }

  & .MuiTableRow-head:nth-child(2) {
    & .MuiTableCell-head {
      &:nth-child(1) {
        width: 250px;
      }

      &:nth-child(n + 3) {
        min-width: 87px;
        width: 87px;
      }
    }
  }
`;

const getBackgroundColor = rank => {
  if (rank === RANK_STATUS.GOOD) {
    return 'success';
  } else if (rank === RANK_STATUS.NORMAL) {
    return 'warning';
  } else if (rank === RANK_STATUS.NEED_IMPROVEMENT) {
    return 'dangerAlt';
  } else {
    return 'transparent';
  }
};

const TableHead = props => <Text fontWeight={600} {...props} />;
const HeaderColSpanText = styled(TableHead)`
  /* border-bottom: 1px solid ${({ theme }) => theme.colors.white}; */
  white-space: nowrap;
`;

export const IndividualUnitAttendanceTable = ({ data, maxHeight, ...props }) => {
  const { t } = useTranslation('individualUnit');

  const columns = [
    {
      id: 'sectionName',
      Header: <TableHead>{t('SECTION')}</TableHead>,
      accessor: 'sectionName',
      Cell: ({ cell: { value } }) => (
        <TextWrapper style={{ textAlign: 'left' }}>{value}</TextWrapper>
      ),
    },
    {
      id: 'subsectionName',
      Header: <TableHead>{t('SUBSECTION')}</TableHead>,
      accessor: 'subsectionName',
      Cell: ({ cell: { value } }) => (
        <TextWrapper style={{ textAlign: 'left' }}>{value}</TextWrapper>
      ),
    },
    {
      id: 'contentType',
      Header: <TableHead>{t('CATEGORY')}</TableHead>,
      accessor: 'contentType',
      Cell: ({ cell: { value } }) => (
        <TextWrapper style={{ textTransform: 'uppercase' }}>{value}</TextWrapper>
      ),
    },
    {
      id: 'status',
      Header: <TableHead>{t('STATUS')}</TableHead>,
      accessor: 'status',
      Cell: ({ cell: { value } }) => (
        <Flex justifyContent="center">
          <TagItem
            py="1"
            px="2"
            tagColor={value === MODULE_STATUS.COMPLETED ? 'primary' : 'greyscale.grey6'}
            justifyContent="center"
          >
            <Text.Hint color={value === MODULE_STATUS.COMPLETED ? 'white' : 'itemDisabled'}>
              {t(value)}
            </Text.Hint>
          </TagItem>
        </Flex>
      ),
    },
    {
      id: 'examinationResult',
      Header: <HeaderColSpanText>{t('EXAMINATION_RESULT')}</HeaderColSpanText>,
      columns: [
        {
          id: 'examinationResultRate',
          Header: <TableHead>{t('EXAMINATION_SCORE')}</TableHead>,
          accessor: 'quizResult',
          Cell: ({ cell: { value = {} } }) => {
            const percentage = value.score / value.fullScore;
            if (!value || isNaN(percentage)) return null;
            return (
              <TextWrapper
                bg={getBackgroundColor(value.rank)}
              >{`${value.score}/${value.fullScore}`}</TextWrapper>
            );
          },
        },
        {
          id: 'examinationResultPercentage',
          Header: <TableHead>{t('EXAMINATION_PERCENTAGE')}</TableHead>,
          accessor: 'quizResult',
          Cell: ({ cell: { value = {} } }) => {
            const percentage = (value.score / value.fullScore) * 100;
            if (!value || isNaN(percentage)) return null;
            return <TextWrapper bg={getBackgroundColor(value.rank)}>{percentage}</TextWrapper>;
          },
        },
      ],
    },
  ];

  return (
    <AttendanceTableStyle
      dense
      stickyHeader
      columns={columns}
      data={data}
      style={{ maxHeight }}
      {...props}
    />
  );
};

IndividualUnitAttendanceTable.propTypes = {
  data: PropTypes.array.isRequired,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default IndividualUnitAttendanceTable;
