import { useApolloClient } from '@apollo/react-hooks';
import XLSX from 'xlsx';

import {
  RAW_CONTENT_TYPE,
  CONTENT_TYPE,
  SINGLE_MODULE_STATUS_TH,
  RANK_STATUS,
  RANK_STATUS_TH,
} from '../module';
import { getIndividualUnitReport } from '../../GraphQL/queries/report.query';
import { formatSchoolTerm } from '../utils';

export const convertIndividualUnitReportToAOA = individualUnitReport => {
  const {
    schoolTerm: { shortName: schoolTerm },
    schoolGrade: { shortName: schoolGrade },
    subject: { name: subject },
    classNo,

    courseName,

    studentNo,
    classroomUsername,
    firstName,
    lastName,
    reports,
  } = individualUnitReport;

  return reports.map(report => {
    const { sectionName, subsectionName, contentType, status, quizResult: rawQuizResult } = report;

    let quizResult = ['', '', ''];
    if (contentType === 'Quiz' && rawQuizResult) {
      const { score, fullScore, rank } = rawQuizResult;
      if (rank !== RANK_STATUS.UNRANK) {
        const quizResultPercentage = (score / fullScore) * 100;
        quizResult = [`${score}/${fullScore}`, `${quizResultPercentage}%`, RANK_STATUS_TH[rank]];
      } else {
        quizResult = ['', '', RANK_STATUS_TH[RANK_STATUS.UNRANK]];
      }
    }

    return [
      schoolTerm,
      schoolGrade,
      `${schoolGrade}/${classNo}`,
      subject,
      studentNo,
      classroomUsername,
      `${firstName} ${lastName}`,
      courseName,
      sectionName,
      subsectionName,
      contentType === RAW_CONTENT_TYPE.QUIZ ? CONTENT_TYPE.QUIZ : CONTENT_TYPE.VDO,
      SINGLE_MODULE_STATUS_TH[status],
      ...quizResult,
    ];
  });
};

const formatReportToAOA = individualUnitReport => {
  const formattedReportAOA = [
    ['ข้อมูลการเข้าเรียนและการทำข้อสอบของนักเรียนรายหน่วย'],
    [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'ผลการทำข้อสอบ',
      'ผลการทำข้อสอบ',
      'ผลการทำข้อสอบ',
    ],
    [
      'เทอม',
      'ระดับชั้น',
      'ห้อง',
      'วิชา',
      'เลขที่',
      'รหัสผู้ใช้',
      'ชื่อ-นามสกุล',
      'หน่วย',
      'บท',
      'เรื่อง',
      'ประเภทไฟล์',
      'สถานะการเข้าเรียน',
      'คะแนน',
      '%',
      'ผลสอบ',
    ],
  ];

  const individualUnitReportsAOA = convertIndividualUnitReportToAOA(individualUnitReport);

  formattedReportAOA.push(...individualUnitReportsAOA);

  return formattedReportAOA;
};

const createAndFormatWorksheet = formattedReportAOA => {
  const worksheet = XLSX.utils.aoa_to_sheet(formattedReportAOA);
  const cols = [
    { wch: 10 }, // เทอม
    { wch: 10 }, // ระดับชั้น
    { wch: 10 }, // ห้อง
    { wch: 15 }, // วิชา
    { wch: 10 }, // เลขที่
    { wch: 15 }, // รหัสผู้ใช้
    { wch: 15 }, // ชื่อ-นามสกุล
    { wch: 15 }, // หน่วย
    { wch: 15 }, // บท
    { wch: 15 }, // เรื่อง
    { wch: 10 }, // ประเภทไฟล์
    { wch: 10 }, // สถานะการเข้าเรียน
    { wch: 10 }, // คะแนน
    { wch: 10 }, // %
    { wch: 10 }, // ผลสอบ
  ];
  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: cols.length - 1 } }, // ภาพรวมการเข้าเรียนและการทำข้อสอบรายหน่วยของนักเรียน
    { s: { r: 1, c: 12 }, e: { r: 1, c: 14 } }, // ผลการทำข้อสอบ
  ];
  worksheet['!cols'] = cols;

  return worksheet;
};

const ExportIndividualUnitReportData = props => {
  const {
    schoolTermId,
    schoolGradeId,
    classNo,
    subjectId,
    courseCode,
    classroomUsername,
    schoolCode,
    children,
  } = props;

  const client = useApolloClient();

  const fetchIndividualUnitReportData = async () => {
    try {
      const { data } = await client.query({
        query: getIndividualUnitReport,
        variables: {
          schoolTermId,
          schoolGradeId,
          classNo,
          subjectId,
          courseCode,
          classroomUsername,
          schoolCode,
        },
      });
      return { data };
    } catch (error) {
      return { error };
    }
  };

  const exportIndividualUnitReport = async ({ report, type }) => {
    const {
      schoolTerm: { shortName: schoolTerm } = {},
      schoolGrade: { shortName: schoolGrade } = {},
      classNo,
      subject: { name: subject } = {},
      courseName,
      firstName,
      lastName,
    } = report;
    const formattedReportAOA = formatReportToAOA(report);
    const worksheet = createAndFormatWorksheet(formattedReportAOA);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ข้อมูลนักเรียนรายหน่วย');
    XLSX.writeFile(
      workbook,
      `${firstName}-${lastName}_${formatSchoolTerm(
        schoolTerm,
      )}_${schoolGrade}-${classNo}_${subject}_${courseName}.` + (type || 'xlsx'),
    );
  };

  return children({ fetchIndividualUnitReportData, exportIndividualUnitReport });
};

export default ExportIndividualUnitReportData;
