import { withRouter } from 'react-router-dom';
import { lifecycle, compose, branch, renderComponent } from 'recompose';

import { withAuth, AUTH_STATE } from 'Libs/Auth';
import { LoginButton, MyProfileButton } from 'Components/AuthButton';

const doValidate = lifecycle({
  componentDidMount() {
    this.props.auth.fetchUserInfo();
  },
});

export default compose(
  withAuth,
  withRouter,
  // doValidate,
  branch(
    props => props.auth.authState === AUTH_STATE.NOT_AUTHENTICATE,
    renderComponent(LoginButton),
  ),
)(MyProfileButton);
