import { useQuery } from '@apollo/react-hooks';

import { getSchoolInfo } from 'GraphQL/queries/leSchools.query';

const LESchoolData = props => {
  const { children, schoolCode } = props;
  const { loading, error, data } = useQuery(getSchoolInfo, {
    variables: { schoolCode },
    skip: !schoolCode,
  });
  return children({ loading, error, data });
};

export default LESchoolData;
