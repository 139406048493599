import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Text } from 'Components/Base';
import { PRACQUIZ_STATUS } from 'Domains/module';

const TextWrapper = styled(Box)`
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ClickableFlex = styled(Flex)`
  :hover {
    cursor: pointer;
  }
`;

const DetailBox = ({ topicName, subsectionName, show, target, onClose }) => {
  const { t } = useTranslation('module');
  return (
    <Popover
      id={target ? 'detail-box' : undefined}
      open={show}
      anchorEl={target}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box
        width={200}
        height={60}
        color={'white'}
        bg={'greyscale.grey1'}
        style={{ paddingTop: '10px', borderRadius: '6px', textAlign: 'center' }}
      >
        <Text mb={2}>{`${t('TOPIC')}: ${topicName}`}</Text>
        <Text>{`${t('SUBSECTION')}: ${subsectionName}`}</Text>
      </Box>
    </Popover>
  );
};

DetailBox.propTypes = {
  topicName: PropTypes.string.isRequired,
  subsectionName: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  target: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
};

const PracQuizBox = ({ topicName, subsectionName, done, total, status }) => {
  const [target, setTarget] = useState(null);
  const subtitle = status === PRACQUIZ_STATUS.INCOMPLETED ? '-/-' : `${done}/${total}`;
  return (
    <Box>
      <ClickableFlex
        flexDirection="column"
        alignItems="center"
        width={150}
        onClick={event => setTarget(event.currentTarget)}
        aria-describedby={target ? 'detail-box' : undefined}
      >
        <Box
          width={34}
          height={34}
          color={'white'}
          bg={
            status === PRACQUIZ_STATUS.PASSED
              ? 'inprogress'
              : status === PRACQUIZ_STATUS.FAILED
              ? 'danger'
              : 'greyscale.grey3'
          }
          style={{ paddingTop: '7px', borderRadius: '6px', textAlign: 'center' }}
        >
          <TextWrapper>
            <Text.SubTitle fontWeight="bold">{subtitle}</Text.SubTitle>
          </TextWrapper>
        </Box>
        <Text>{subsectionName}</Text>
      </ClickableFlex>
      <DetailBox
        topicName={topicName}
        subsectionName={subsectionName}
        show={Boolean(target)}
        target={target}
        onClose={() => setTarget(null)}
      />
    </Box>
  );
};

PracQuizBox.propTypes = {
  topicName: PropTypes.string.isRequired,
  subsectionName: PropTypes.string.isRequired,
  done: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default PracQuizBox;
