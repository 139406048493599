import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  BorderBox,
  Box as BaseBox,
  Image as BaseImage,
  Layout as BaseLayout,
} from 'Components/Base';

const Box = styled(BaseBox)`
  position: relative;
  max-width: auto;
  background-image: linear-gradient(to bottom, #cce4f3, #99c9e6);
`;

const Layout = styled(BaseLayout)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ImageBox = styled(BorderBox)`
  overflow: hidden;
  position: relative;
`;

const Image = styled(BaseImage)`
  position: absolute;
  max-width: inherit;
  height: 100%;
  bottom: 0;
  right: 0;
`;

const AppLoginLayout = ({ menus, children }) => {
  return (
    <Box width={'100vw'} height={'100vh'} p={{ _: 6, lg: 9 }} m={0}>
      <Layout
        width={1}
        main={children}
        mainProps={{
          p: { _: 3, md: 5 },
          bg: 'white',
          height: '100%',
          borderRadius: { _: '10px', md: '10px 0px 0px 10px' },
        }}
        right={
          <ImageBox
            bg={'primary'}
            height={'100%'}
            borderRadius={'0px 10px 10px 0px'}
            maxWidth={'400px'}
          >
            <Image
              src="/assets/components/AppLoginLayout/login_image.png"
              srcSet={
                '/assets/components/AppLoginLayout/login_image.png 1x, /assets/components/AppLoginLayout/login_image@2x.png 2x, /assets/components/AppLoginLayout/login_image@3x.png 3x'
              }
            />
          </ImageBox>
        }
        rightProps={{
          display: { _: 'none', md: 'block' },
          width: { md: '100%' },
          minWidth: { md: '300px', lg: '375px' },
          maxWidth: '400px',
          flex: { md: '1' },
          borderRadius: '0px 10px 10px 0px',
        }}
      />
    </Box>
  );
};
AppLoginLayout.propTypes = {
  children: PropTypes.element,
  menus: PropTypes.array,
};

AppLoginLayout.defaultProps = {
  menus: [],
};

export default AppLoginLayout;
