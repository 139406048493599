import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { withAnalytic } from '@skooldio/analytics-provider/dist/Analytics/hoc';

import { BackdropProgress } from 'Components/Base';
import ExportCourseGradeReportData from 'Domains/ExportCourseGradeReportData';
import { useAnalytics } from 'Libs/Analytics';
import DropdownButton from 'Components/DropdownButton';
import * as mixpanel from 'config/mixpanel';

const CourseGradeExporter = ({
  disabledCourseGrade,
  disabledCourseSection,
  appliedFilter,
  schoolCode,
  onLogAction,
  myProfileId,
}) => {
  const { send } = useAnalytics();
  const { t } = useTranslation('courseGrade');
  const [loading, setLoading] = useState(false);

  const {
    term: { id: schoolTermId } = {},
    grade: { id: schoolGradeId } = {},
    subject: { id: subjectId } = {},
    syllabus: courseCode,
  } = appliedFilter;

  const itemList = [
    {
      key: 1,
      value: 1,
      text: t('COURSE_GRADE_FILE'),
    },
    {
      key: 2,
      value: 2,
      text: t('COURSE_SECTION_FILE'),
      disabled: disabledCourseSection,
    },
  ];

  const handleExportCourseGradeReport = async (
    fetchCourseGradeReportData,
    exportCourseGradeReport,
  ) => {
    onLogAction({
      category: 'Export',
      action: `Click`,
      label: `Click on course grade page`,
    });
    send('export', { on: 'course_grade_page' });
    mixpanel.trackEvent('Course Grade Export File Course Click', {
      distinct_id: myProfileId,
    });
    setLoading(true);
    const { data, error } = await fetchCourseGradeReportData();
    setLoading(false);
    if (!error) {
      await exportCourseGradeReport({
        report: data.foCourseGradeReport,
        type: 'xlsx',
      });
    }
  };

  const handleExportCourseSectionReport = async (
    fetchCourseSectionReportData,
    exportCourseSectionReport,
  ) => {
    onLogAction({
      category: 'Export',
      action: `Click`,
      label: `Click on course grade page`,
    });
    send('export', { on: 'course_grade_page' });
    mixpanel.trackEvent('Course Grade Export File Section Click', {
      distinct_id: myProfileId,
    });
    setLoading(true);
    const { data, error } = await fetchCourseSectionReportData();
    setLoading(false);
    if (!error) {
      await exportCourseSectionReport({
        report: data.foCourseSectionReport,
        type: 'xlsx',
      });
    }
  };

  return (
    <ExportCourseGradeReportData
      schoolTermId={schoolTermId}
      schoolGradeId={schoolGradeId}
      subjectId={subjectId}
      schoolCode={schoolCode}
      courseCode={courseCode}
    >
      {({
        fetchCourseGradeReportData,
        exportCourseGradeReport,
        fetchCourseSectionReportData,
        exportCourseSectionReport,
      }) => {
        return (
          <Fragment>
            <BackdropProgress open={loading} />
            <DropdownButton
              title={t('EXPORT')}
              list={itemList}
              onChange={value => {
                if (value === 1) {
                  handleExportCourseGradeReport(
                    fetchCourseGradeReportData,
                    exportCourseGradeReport,
                  );
                } else if (value === 2) {
                  handleExportCourseSectionReport(
                    fetchCourseSectionReportData,
                    exportCourseSectionReport,
                  );
                }
              }}
              height={'40px'}
              menuHeight={'40px'}
              disabled={disabledCourseGrade || loading}
              align="right"
            />
          </Fragment>
        );
      }}
    </ExportCourseGradeReportData>
  );
};

export default withAnalytic(CourseGradeExporter);
