import React from 'react';
import { Button, Text } from 'Components/Base';

const LoginButton = ({ history }) => {
  return (
    <Button
      variant={'default'}
      mr={3}
      onClick={() => {
        history.push('/login');
      }}
    >
      <Text>เข้าสู่ระบบ</Text>
    </Button>
  );
};

export default LoginButton;
