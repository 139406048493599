import React from 'react';
import PropTypes from 'prop-types';

import { Box, Flex, Text, Icon, ICON_LIST } from '../Base';

const LegendItem = ({
  legendColor = 'greyscale.grey3',
  legendIconProps,
  legendText,
  legendTextProps,
  ...props
}) => {
  return (
    <Flex alignItems={'center'} {...props}>
      <Box mr={2}>
        <Text display={'flex'} color={legendColor} fontSize={4} {...legendIconProps}>
          <Icon name={ICON_LIST.SOLID_CIRCLE} />
        </Text>
      </Box>
      <Text {...legendTextProps}>{legendText}</Text>
    </Flex>
  );
};

LegendItem.propTypes = {
  legendColor: PropTypes.string,
  legendIconProps: PropTypes.object,
  legendText: PropTypes.string,
  legendTextProps: PropTypes.object,
};

export default LegendItem;
