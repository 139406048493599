import { useApolloClient } from '@apollo/react-hooks';
import _ from 'lodash';
import XLSX from 'xlsx';

import { getCourseUnitReport } from '../../GraphQL/queries/report.query';
import { MODULE_STATUS_TH, QUIZ_MODULE_STATUS_TH } from '../module';
import { formatSchoolTerm } from '../utils';

const formatGrowthRate = growthRate => {
  if (growthRate === null) return null;

  const growthRatePercentText = `${_.round(growthRate, 2)}%`;
  return growthRate > 0 ? `+${growthRatePercentText}` : growthRatePercentText;
};

export const convertCourseUnitReportToAOA = courseUnitReport => {
  const {
    schoolTerm: { shortName: schoolTerm },
    subject: { name: subject },
    reports,
  } = courseUnitReport;

  return reports.map(report => {
    const {
      studentNo,
      classroomUsername,
      firstName,
      lastName,
      attendanceRate,
      examinationRate,
      schoolGrade: { shortName: schoolGrade },
      classNo,
      growthRate,
    } = report;

    let attendanceRateText = '-';
    let examinationRateText = '-';
    let attendanceStatus = MODULE_STATUS_TH.INCOMPLETED;
    let examinationStatus = QUIZ_MODULE_STATUS_TH.INCOMPLETED;

    if (attendanceRate.total) {
      attendanceRateText = `${attendanceRate.actual}/${attendanceRate.total}`;

      const isAttendanceCompleted = attendanceRate.actual / attendanceRate.total === 1;
      if (isAttendanceCompleted) attendanceStatus = MODULE_STATUS_TH.COMPLETED;
    }

    if (examinationRate.total) {
      examinationRateText = `${examinationRate.actual}/${examinationRate.total}`;

      const isExaminationCompleted = examinationRate.actual / examinationRate.total === 1;
      if (isExaminationCompleted) examinationStatus = QUIZ_MODULE_STATUS_TH.COMPLETED;
    }

    return [
      schoolTerm,
      schoolGrade,
      `${schoolGrade}/${classNo}`,
      subject,
      studentNo,
      classroomUsername,
      `${firstName} ${lastName}`,
      attendanceRateText,
      attendanceStatus,
      examinationRateText,
      examinationStatus,
      formatGrowthRate(growthRate),
    ];
  });
};

const formatReportToAOA = courseUnitReport => {
  const { courseName } = courseUnitReport;

  const formattedReportAOA = [
    ['ภาพรวมการเข้าเรียนและการทำข้อสอบของนักเรียนรายหน่วย'],
    [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      `หน่วยการเรียนรู้: ${courseName}`,
      `หน่วยการเรียนรู้: ${courseName}`,
    ],
    [
      'เทอม',
      'ระดับชั้น',
      'ห้อง',
      'วิชา',
      'เลขที่',
      'รหัสผู้ใช้',
      'ชื่อ-นามสกุล',
      'อัตราการเข้าเรียน',
      'สถานะการเข้าเรียน',
      'อัตราการเข้าสอบ',
      'สถานะการเข้าสอบ',
      '% พัฒนาการ',
    ],
  ];

  const courseUnitReportsAOA = convertCourseUnitReportToAOA(courseUnitReport);

  formattedReportAOA.push(...courseUnitReportsAOA);

  return formattedReportAOA;
};

const createAndFormatWorksheet = formattedReportAOA => {
  const worksheet = XLSX.utils.aoa_to_sheet(formattedReportAOA);
  const cols = [
    { wch: 10 }, //เทอม
    { wch: 10 }, //ระดับชั้น
    { wch: 10 }, //ห้อง
    { wch: 15 }, //วิชา
    { wch: 10 }, //เลขที่
    { wch: 15 }, //รหัสผู้ใช้
    { wch: 15 }, //ชื่อ-นามสกุล
    { wch: 15 }, //อัตราการเข้าเรียน
    { wch: 15 }, //สถานะการเข้าเรียน
    { wch: 15 }, //อัตราการเข้าสอบ
    { wch: 15 }, //สถานะการเข้าสอบ
    { wch: 15 }, //% พัฒนาการ
  ];
  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: cols.length - 1 } }, // ภาพรวมการเข้าเรียนและการทำข้อสอบของนักเรียนรายหน่วย
    { s: { r: 1, c: 7 }, e: { r: 1, c: 11 } }, // หน่วยการเรียนรู้: XXX
  ];
  worksheet['!cols'] = cols;

  return worksheet;
};

const ExportCourseUnitReportData = props => {
  const {
    schoolTermId,
    schoolGradeId = null,
    classNo = null,
    subjectId,
    courseCode,
    schoolCode,
    children,
  } = props;

  const client = useApolloClient();

  const fetchCourseUnitReportData = async () => {
    try {
      const { data } = await client.query({
        query: getCourseUnitReport,
        variables: {
          schoolTermId,
          schoolGradeId,
          classNo,
          subjectId,
          courseCode,
          schoolCode,
        },
      });
      return { data };
    } catch (error) {
      return { error };
    }
  };

  const exportCourseUnitReport = async ({ report: courseUnitReport, type }) => {
    const {
      schoolTerm: { shortName: schoolTerm } = {},
      subject: { name: subject } = {},
      courseName,
      reports,
    } = courseUnitReport;

    const { schoolGrade } = reports[0];
    const headerSchoolGrade = schoolGradeId ? schoolGrade.shortName : `ทุกระดับชั้น`;
    const headerClassNo = classNo ?? `ทุกห้อง`;

    const formattedReportAOA = formatReportToAOA(courseUnitReport);
    const worksheet = createAndFormatWorksheet(formattedReportAOA);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'รายหน่วย');
    XLSX.writeFile(
      workbook,
      `ข้อมูลรายหน่วย_${formatSchoolTerm(
        schoolTerm,
      )}_${headerSchoolGrade}-${headerClassNo}_${subject}_${courseName}.` + (type || 'xlsx'),
    );
  };

  return children({ fetchCourseUnitReportData, exportCourseUnitReport });
};

export default ExportCourseUnitReportData;
