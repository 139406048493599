import colors from './colors';
import { spaces, spacesByAliases } from './space';
import fonts, { REBOOT_FONT_FAMILY } from './font';
import buttonStyles from './buttonStyles';
export { muiTheme } from './muiTheme';
const fontSizes = [10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 42, 48, 60, 96];

// styled-system v5
fontSizes.xs = fontSizes[1];
fontSizes.sm = fontSizes[2];
fontSizes.md = fontSizes[3];

const fontWeights = {
  exLight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

// TODO: should adopt bootstrap breakpoints?
const breakpoints = [376, 768, 1088].map(b => `${b}px`);

// alias for styled-system v5
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];

// bootstrap4 breakpoints
// const breakpoints = [576, 768, 992, 1200]; // sm, md, lg, xl

// bulma.io breakpoints
// const breakpoints = [769, 1024, 1216, 1408] // (mobile), tablet, desktop, widescreen, FHD

const breakpointsV2 = [576, 768, 992, 1200].map(b => `${b}px`);

breakpointsV2.sm = breakpointsV2[0];
breakpointsV2.md = breakpointsV2[1];
breakpointsV2.lg = breakpointsV2[2];
breakpointsV2.xl = breakpointsV2[3];

const zIndices = {
  mobileStepper: 1000,
  navBar: 1100,
  drawer: 1200,
  backdrop: 1250,
  modal: 1300, // including modal, menu, popper, ...
  snackbar: 1400,
  tooltip: 1500,
};

const theme = {
  breakpoints: breakpointsV2,
  space: spaces,
  fontSizes,
  fontWeights,
  lineHeights: {
    normal: 1.42,
    solid: 1,
    title: 1.25,
    copy: 1.5,
    loose: 1.75,
    doubled: 2,
    veryloose: 2.25,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  fonts: {
    sans: `'Noto Sans', 'Noto Sans Thai', sans-serif, ${REBOOT_FONT_FAMILY}`,
    serif: `'Noto Sans', sans-serif, ${REBOOT_FONT_FAMILY}`,
    ...fonts,
  },
  borders: [0, '1px solid', '2px solid', '4px solid', '8px solid', '16px solid', '32px solid'],
  radii: [0, 2, 4, 16, 9999, '100%'],
  colors,
  zIndices,
  buttons: buttonStyles,
  textStyles: {
    h1: { fontSize: fontSizes[10] },
    h2: { fontSize: fontSizes[9] },
    h3: { fontSize: fontSizes[8] },
    h4: { fontSize: fontSizes[7] },
    h5: { fontSize: fontSizes[6] },
    h6: { fontSize: fontSizes[5] },
    subtitle1: { fontSize: fontSizes[4] },
    subtitle2: { fontSize: fontSizes[3] },
    body1: { fontSize: fontSizes[2] },
    body2: { fontSize: fontSizes[1] },
    caption: { fontSize: fontSizes[0] },
    extraLarge: { fontSize: fontSizes[8], fontWeight: fontWeights.bold },
    large: { fontSize: fontSizes[6], fontWeight: fontWeights.semibold },
    normal: { fontSize: fontSizes[4], fontWeight: fontWeights.normal },
    small: { fontSize: fontSizes[2], fontWeight: fontWeights.light },
    extraSmall: { fontSize: fontSizes[0], fontWeight: fontWeights.exLight },
  },
  // custom system from skooldio-ui-components-core
  Card: {
    boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    transition: 'box-shadow 0.1s ease-out, transform 0.1s ease-out, opacity 0.1s ease-out',
  },
  cardStyles: {
    default: {
      borderRadius: '9px',
    },
    certificate: {
      borderRadius: 0,
      background:
        'repeating-linear-gradient(45deg, rgba(0,0,0,0.05), rgba(0,0,0,0.05) 2px, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 5px)',
      backgroundPosition: 'center',
      backgroundSize: 'calc(100% - 16px) calc(100% - 16px)',
      backgroundRepeat: 'no-repeat',
    },
  },
  clickableStyles: {
    elevate: {
      cursor: 'pointer',
      '&:hover': {
        boxShadow:
          '0 16px 16px 0 rgba(32, 32, 32, 0.05), 0 8px 8px 0 rgba(42, 42, 42, 0.05), 0 4px 4px 0 rgba(49, 49, 49, 0.05), 0 2px 2px 0 rgba(45, 45, 45, 0.05), 0 64px 64px 0 rgba(35, 35, 35, 0.05), 0 32px 32px 0 rgba(49, 49, 49, 0.05)',
        transform: 'translateY(-10px)',
      },
      '&:active': {
        boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.1)',
      },
    },
    hoverUnderline: {
      cursor: 'pointer',
      '&:link': {
        textDecoration: 'none',
      },
      '&:visited': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
      '&:active': {
        textDecoration: 'underline',
      },
    },
  },
};

export {
  colors as COLOR,
  theme,
  spacesByAliases as SPACE,
  buttonStyles as BUTTONS,
  breakpointsV2 as BREAKPOINTS,
};

export default theme;
