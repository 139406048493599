import { COLOR } from 'Components/Theme';

export const KEY_COLOR = {
  R: COLOR.realistic,
  I: COLOR.investigative,
  A: COLOR.artistic,
  S: COLOR.social,
  E: COLOR.enterprising,
  C: COLOR.conventional,
};

export const TYPE = {
  TEACHER: 'teacher',
  STUDENT: 'student',
};

export const TYPE_COLOR = {
  TEACHER: COLOR.danger,
  STUDENT: COLOR.inprogress,
};
