import gql from 'graphql-tag';

export const getStudentModuleByUserId = gql`
  query foStudentModuleReports($search: FOStudentReportsSearchInput!) {
    foStudentModuleReports(search: $search) {
      total
      reports {
        id
        module {
          name
          topic {
            name
          }
          subject {
            shortname: shortName
            code
          }
          url
          type
          homework
          duration
        }
        report {
          ... on FOSubsectionReport {
            lessonStatus: status
          }
          ... on FOPracQuizReport {
            pracQuizStatus: status
          }
        }
      }
    }
  }
`;

export const getCurrentSchoolTerm = gql`
  query foCurrentSchoolTerm {
    foCurrentSchoolTerm {
      id
      name
      startDate
      endDate
      schoolYear
      yearMonths {
        year
        month
        weeks {
          number
          date
        }
      }
    }
  }
`;

export const getCurrentSchoolYear = gql`
  query foCurrentSchoolYear {
    foCurrentSchoolYear {
      id
      name
      schoolTerms {
        id
        name
        startDate
        endDate
        schoolYear
        yearMonths {
          year
          month
          weeks {
            number
            date
          }
        }
      }
    }
  }
`;

export const getStudentSubjectsByUserId = gql`
  query foStudentSubjects($userId: ID!) {
    foStudentSubjects(userId: $userId) {
      code
      name
    }
  }
`;

export const getStudentPracQuizProgressByUserId = gql`
  query foStudentPracQuizProgress($search: FOStudentProgressSearchInput!) {
    foStudentPracQuizProgress(search: $search) {
      subject {
        code
        shortname: shortName
      }
      status
      value
    }
  }
`;

export const getStudentPracQuizByUserId = gql`
  query foStudentPracQuizDetails($search: FOStudentReportsSearchInput!) {
    foStudentPracQuizDetails(search: $search) {
      subject {
        code
        shortname: shortName
      }
      pracQuizzes {
        topicName
        subsectionName
        status
        done: score
        total: fullScore
      }
    }
  }
`;

export const getStudentModuleProgress = gql`
  query foStudentModuleProgress($search: FOStudentProgressSearchInput!) {
    foStudentModuleProgress(search: $search) {
      subject {
        code
        shortname: shortName
      }
      total: fullScore
      done: score
      status
    }
  }
`;

export const getStudentRIASECByStudentId = gql`
  query foStudentRIASEC($studentId: ID!) {
    foStudentRIASEC(studentId: $studentId) {
      code
      value
      max
    }
  }
`;

export const getTeacherRIASECByStudentId = gql`
  query foTeacherRIASEC($studentId: ID!) {
    foTeacherRIASEC(studentId: $studentId) {
      code
      value
      max
    }
  }
`;

export const getStudentGradesByTeacherId = gql`
  query foStudentGrades($advisorTeacherId: ID!) {
    foStudentGrades(advisorTeacherId: $advisorTeacherId) {
      code
      name
    }
  }
`;

export const getStudentProfilesByTeacherId = gql`
  query foStudentProfiles($search: FOSearchStudentProfilesInput!) {
    foStudentProfiles(search: $search) {
      profiles {
        id
        code
        nickname: nickName
        title
        firstname: firstName
        lastname: lastName
        avatar
      }
      total
    }
  }
`;
