import React, { useState, memo, useEffect, useContext } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'use-media';
import { ThemeContext } from 'styled-components';

import { Box, Text, Flex, ICON_LIST, ToolbarButton } from 'Components/Base';
import { COLOR } from 'Components/Theme';
import Dropdown from 'Components/Dropdown';
import SearchInput from 'Components/SearchInput';
import ToolbarContainer from 'Components/ToolbarContainer';
import LegendItem from 'Components/LegendItem';
import ModuleTableData from 'Domains/ModuleTableData';
import LSPUserData from 'Domains/LSPUserData';
import SchoolTermData from 'Domains/SchoolTermData';
import StudentSubjectsData from 'Domains/StudentSubjectsData';
import getNextMonday from 'Domains/getNextMonday';

import ModuleTable from './ModuleTable';

const ModuleTableComponent = memo(function ModuleTableComponent({
  userId,
  dateFilter,
  subjectCodeFilter,
  searchText,
  gradeId,
}) {
  const theme = useContext(ThemeContext);
  const isBreakpointsMd = useMedia({ minWidth: theme.breakpoints.md });
  const isBreakpointsLg = useMedia({ minWidth: theme.breakpoints.lg });
  const isBreakpointsXl = useMedia({ minWidth: theme.breakpoints.xl });
  return (
    <ModuleTableData
      userId={userId}
      startDate={dateFilter}
      endDate={getNextMonday(dateFilter)}
      subjectCode={subjectCodeFilter}
      searchText={searchText}
      gradeId={gradeId}
    >
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return null;
        if (!data || !data.foStudentModuleReports) return null;
        return (
          <ModuleTable
            maxHeight={
              isBreakpointsXl
                ? '52vh'
                : isBreakpointsLg
                ? '45vh'
                : isBreakpointsMd
                ? '60vh'
                : '40vh'
            }
            data={data.foStudentModuleReports.reports}
          />
        );
      }}
    </ModuleTableData>
  );
});

const FilterComponent = memo(function FilterComponent({
  userId,
  gradeId,
  onSearchText,
  onDateFilter,
  onSubjectCodeFilter,
}) {
  const [yearMonth, setYearMonth] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCode, setSelectedCode] = useState('all');
  const year = yearMonth ? Number(yearMonth.split('-')[0]) : '';
  const month = yearMonth ? Number(yearMonth.split('-')[1]) : '';
  const { t } = useTranslation('module');

  return (
    <SchoolTermData>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return null;
        const {
          foCurrentSchoolTerm: { id: schoolTermId, yearMonths },
        } = data;
        const monthOptions = yearMonths.map(({ year, month }) => ({
          key: `${year}-${month}`,
          value: `${year}-${month}`,
          text: t('MONTH_YEAR', { date: new Date(year, month, 1) }),
        }));
        const selectedYearMonth =
          year && month
            ? _.find(yearMonths, ({ year: y, month: m }) => year === y && month === m)
            : yearMonths[0];
        const mondayOptions = selectedYearMonth.weeks.map(({ date, number }) => ({
          key: date,
          value: date,
          text: t('WEEK', { number }),
        }));

        return (
          <StudentSubjectsData
            key="2"
            userId={userId}
            schoolTermId={schoolTermId}
            gradeId={gradeId}
          >
            {({ loading: subjectsLoading, error: subjectsError, data: subjectsData }) => {
              if (subjectsLoading) return null;
              if (subjectsError) return null;
              const subjectOptions = subjectsData.foStudentSubjects.map(({ code, name }) => ({
                key: code,
                value: code,
                text: name,
              }));
              subjectOptions.splice(0, 0, { key: 'all', value: 'all', text: t('ALL_SUBJECT') });

              return (
                <FilterView
                  yearMonth={yearMonth}
                  monthOptions={monthOptions}
                  setYearMonth={setYearMonth}
                  selectedDate={selectedDate}
                  mondayOptions={mondayOptions}
                  setSelectedDate={setSelectedDate}
                  onDateFilter={onDateFilter}
                  selectedCode={selectedCode}
                  subjectOptions={subjectOptions}
                  setSelectedCode={setSelectedCode}
                  onSubjectCodeFilter={onSubjectCodeFilter}
                  onSearchText={onSearchText}
                />
              );
            }}
          </StudentSubjectsData>
        );
      }}
    </SchoolTermData>
  );
});

const FilterView = memo(function FilterView({
  yearMonth,
  monthOptions,
  setYearMonth,
  selectedDate,
  mondayOptions,
  setSelectedDate,
  onDateFilter,
  selectedCode,
  subjectOptions,
  setSelectedCode,
  onSubjectCodeFilter,
  onSearchText,
}) {
  useEffect(() => {
    setYearMonth(monthOptions[0].value);
  }, []);

  useEffect(() => {
    setSelectedDate(mondayOptions[0].value);
    onDateFilter(mondayOptions[0].value);
  }, [yearMonth]);

  const { t } = useTranslation('module');
  const year = yearMonth ? Number(yearMonth.split('-')[0]) : '';
  const month = yearMonth ? Number(yearMonth.split('-')[1]) : '';
  const endDate = new Date(selectedDate);
  endDate.setDate(endDate.getDate() + 6);
  const week = _.findIndex(mondayOptions, ({ key }) => key === selectedDate);

  return (
    <Box>
      <Flex mb={4} flexDirection={{ _: 'column', md: 'row' }}>
        <Flex flex="1" px={{ _: 3, md: 0 }}>
          <Dropdown
            defaultValue={yearMonth}
            title={t('FILTER_MONTH')}
            list={monthOptions}
            onChange={setYearMonth}
          />
        </Flex>
        <Flex flex="1" px={3} pt={{ _: 2, md: 0 }}>
          <Dropdown
            defaultValue={selectedCode}
            title={t('FILTER_SUBJECT')}
            list={subjectOptions}
            onChange={code => {
              setSelectedCode(code);
              onSubjectCodeFilter(code);
            }}
          />
        </Flex>
        <Flex flex="2" px={{ _: 3, md: 0 }} pt={{ _: 2, md: 0 }}>
          <SearchInput placeholder={t('SEARCH')} onChange={text => onSearchText(text)} />
        </Flex>
      </Flex>
      <Flex px={{ _: 3, md: 0 }} alignItems="center">
        <Flex flex="1">
          <Text>{t('MONTH', { date: new Date(year, month, 1) })}</Text>
        </Flex>
        <Flex flex="1" justifyContent="center" alignItems="center">
          <ToolbarButton
            startIcon={ICON_LIST.ARROW_LEFT}
            iconSize={28}
            color="primary"
            onClick={
              week > 0
                ? () => {
                    setSelectedDate(mondayOptions[week - 1].value);
                    onDateFilter(mondayOptions[week - 1].value);
                  }
                : undefined
            }
            disabled={week === 0}
          />
          {week >= 0 && <Text>{mondayOptions[week].text}</Text>}
          <ToolbarButton
            startIcon={ICON_LIST.ARROW_RIGHT}
            iconSize={28}
            color="primary"
            onClick={
              week < mondayOptions.length - 1 &&
              (() => {
                setSelectedDate(mondayOptions[week + 1].value);
                onDateFilter(mondayOptions[week + 1].value);
              })
            }
            disabled={week === mondayOptions.length - 1}
          />
        </Flex>
        <Flex flex="1" justifyContent="flex-end">
          <Text>
            {t('PERIOD', {
              startDate: selectedDate,
              endDate: endDate,
            })}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
});

const UserModule = ({ userId }) => {
  const [searchText, setSearchText] = useState('');
  const [dateFilter, setDateFilter] = useState(new Date());
  const [subjectCodeFilter, setSubjectCodeFilter] = useState(null);
  const { t } = useTranslation('module');

  return (
    <LSPUserData userId={userId}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return null;
        const {
          foProfile: {
            schoolGrade: { id: gradeId },
          },
        } = data;
        return (
          <ToolbarContainer
            topToolbar={
              <FilterComponent
                userId={userId}
                gradeId={gradeId}
                onSearchText={text => setSearchText(text)}
                onDateFilter={date => setDateFilter(date)}
                onSubjectCodeFilter={code => setSubjectCodeFilter(code)}
              />
            }
            topToolbarProps={{ pt: 4, px: { _: 0, md: 4, lg: 7 } }}
            content={
              <ModuleTableComponent
                userId={userId}
                gradeId={gradeId}
                dateFilter={dateFilter}
                subjectCodeFilter={subjectCodeFilter}
                searchText={searchText}
              />
            }
            contentProps={{ px: { _: 0, md: 4, lg: 7 } }}
            bottomStatusBar={
              <Flex>
                <LegendItem legendText={t('HAVE')} />
                <Flex mr={3} />
                <LegendItem legendColor={COLOR.black} legendText={t('DONE')} />
              </Flex>
            }
            bottomStatusBarProps={{ bg: 'greyscale.grey5' }}
          />
        );
      }}
    </LSPUserData>
  );
};

export default memo(UserModule);
