import React from 'react';
import { Text as BaseText } from '@skooldio/skooldio-ui-components-core';
import styled from 'styled-components';

const Text = styled(BaseText)``;
Text.defaultProps = {
  fontSize: 3,
  fontWeight: 400,
};

const TextLight = styled(BaseText)``;
TextLight.defaultProps = {
  fontSize: 4,
  fontWeight: 300,
};

const TextTitle = styled(BaseText)``;
TextTitle.defaultProps = {
  fontSize: 6,
  fontWeight: 700,
};

const TextSubTitle = styled(BaseText)``;
TextSubTitle.defaultProps = {
  fontSize: 5,
  fontWeight: 400,
};

const TextHint = styled(BaseText)``;
TextHint.defaultProps = {
  fontSize: 2,
  fontWeight: 400,
};

Text.Light = TextLight;
Text.Title = TextTitle;
Text.SubTitle = TextSubTitle;
Text.Hint = TextHint;

export default Text;
