import React, { useCallback, memo } from 'react';

import { Flex, Container } from 'Components/Base';
import { SPACE } from 'Components/Theme';
import UserItem from 'Containers/UserItem';
import Breadcrumb from 'Components/Breadcrumb';
import BreadcrumbLayout from 'Components/BreadcrumbLayout';
import { withAuth } from 'Libs/Auth';
import UserModule from 'Containers/UserModule';
import TabContent from 'Components/TabContent';

const BreadcrumbComponent = memo(function BreadcrumbComponent({ userId, userCode }) {
  const items = [
    {
      key: 'module',
      title: 'Module',
      path: '/student/module',
    },
    {
      key: userId,
      title: userCode,
      path: `/student/module`,
    },
  ];
  return <Breadcrumb items={items} variant={'dark'} />;
});

const StudentModulePage = props => {
  const {
    auth: {
      myProfile: { id: userId, additional5: userCode },
    },
  } = props;
  return (
    <Container>
      <BreadcrumbLayout
        breadcrumbComponent={useCallback(
          () => (
            <BreadcrumbComponent userId={userId} userCode={userCode} />
          ),
          [userId],
        )}
      >
        <TabContent content={<UserModule userId={userId} />} />
      </BreadcrumbLayout>
    </Container>
  );
};

export default memo(withAuth(StudentModulePage));
