import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

import theme from 'Components/Theme';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: theme.zIndices.backdrop,
    color: '#fff',
  },
}));

const BackdropProgress = ({ open, onClick, ...props }) => {
  const classes = useStyles();
  return (
    <Backdrop classes={classes} open={open} onClick={onClick}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

BackdropProgress.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
};

BackdropProgress.defaultProps = {
  open: false,
  onClick: () => {},
};
export default BackdropProgress;
