/**
 *
 * @param {string} schoolTerm shortName ex. 2563/2
 * @returns {string} เทอม2_ปีการศึกษา2563
 */
const formatSchoolTerm = shortSchoolTerm => {
  if (!shortSchoolTerm.includes('/')) return shortSchoolTerm;

  const [year, term] = shortSchoolTerm.split('/');
  return `เทอม${term}_ปีการศึกษา${year}`;
};

/**
 *
 * @param {string} schoolTerm fullName ex. เทอม 2 ปีการศึกษา 2563
 * @returns {string} เทอม2_ปีการศึกษา2563
 */
const formatFullSchoolTerm = fullSchoolTerm => {
  const spaceRemovedSchoolTerm = fullSchoolTerm.replace(/\s/g, '');
  const position = spaceRemovedSchoolTerm.indexOf('ปีการศึกษา');

  if (!position > 0) return spaceRemovedSchoolTerm;
  return `${spaceRemovedSchoolTerm.substring(0, position)}_${spaceRemovedSchoolTerm.substring(
    position,
  )}`;
};

export { formatSchoolTerm, formatFullSchoolTerm };
