import React from 'react';

import FormikInput from './FormikInput';
import FormikDropdown from './FormikDropdown';
import TextFieldBase from './TextFieldBase';

const TextField = FormikInput;
TextField.Input = FormikInput;
TextField.Input.displayName = 'TextFieldInput';

TextField.Dropdown = FormikDropdown;
TextField.Dropdown.displayName = 'TextFieldDropdown';

TextField.ReadOnly = props => (
  <TextFieldBase isFormik={false} readOnly={true} name={'readOnly'} {...props} />
);

TextField.Blank = () => <div style={{ margin: 0 }} />;
TextField.Blank.displayName = 'TextFieldBlank';

TextField.ReadOnly.displayName = 'TextFieldReadOnly';

export default TextField;
