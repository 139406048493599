import { useQuery } from '@apollo/react-hooks';

import { getSchoolTerms } from 'GraphQL/queries/attendanceFilter.query';

const CurrentSchoolTermData = props => {
  const { children } = props;
  const { loading, error, data } = useQuery(getSchoolTerms);
  return children({ loading, error, data });
};

export default CurrentSchoolTermData;
