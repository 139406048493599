export const getAuthContext = props => {
  const { authContext } = props;
  return authContext;
};

export const AUTH_STATE = {
  NONE: 'NONE', // First load state
  NOT_AUTHENTICATE: 'NOT_AUTHENTICATE',
  PENDING: 'PENDING',
  AUTHENTICATED: 'AUTHENTICATED',
};
