import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import { showAuth, hideAuth } from 'Redux/menu/actions';
import NoMatchComponent from 'Components/NoMatch';

const NoMatchPage = ({ history, showAuth, hideAuth }) => {
  useEffect(() => {
    hideAuth();
    return showAuth;
  }, []);

  return (
    <Fragment>
      <NoMatchComponent
        onBackClick={() => {
          history.push('/');
        }}
      />
    </Fragment>
  );
};

export default connect(undefined, { showAuth, hideAuth })(NoMatchPage);
