import gql from 'graphql-tag';

export const getLSPUserById = gql`
  query foProfile($userId: ID!) {
    foProfile(userId: $userId) {
      ... on FOStudentProfile {
        id
        title
        firstname: firstName
        lastname: lastName
        nickname: nickName
        avatar
        code
        schoolGrade {
          name
        }
        teacherId: advisorTeacherId
        __typename
      }
      ... on FOTeacherProfile {
        id
        title
        firstname: firstName
        lastname: lastName
        nickname: nickName
        avatar
        email
        position
        phoneNumber
        __typename
      }
    }
  }
`;
