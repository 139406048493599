import { Button as SkooldioButton } from '@skooldio/skooldio-ui-components-core';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { layout } from 'styled-system';

const ButtonOutlined = styled(SkooldioButton)`
  border-radius: 4px;
  font-size: 16px;
  font-weight: 300;
  font-family: inherit;
  padding: 8px 16px;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
  border: ${props => `1px solid ${props.theme.colors.itemDisabled}`};
  color: ${props => props.theme.colors.buttonText};
  background: white;
  ${layout};
  ${props =>
    props.disabled &&
    css`
      color: ${props.theme.colors.itemDisabled};
      border-color: ${props.theme.colors.itemDisabled};
      background-color: ${props.theme.colors.greyscale.grey6};
      &:hover {
        cursor: default;
        color: ${props.theme.colors.itemDisabled};
        border-color: ${props.theme.colors.itemDisabled};
        background-color: ${props.theme.colors.greyscale.grey6};
      }
    `};
`;

ButtonOutlined.propTypes = {
  disabled: PropTypes.bool,
};

ButtonOutlined.defaultProps = {
  minWidth: 100,
};

export default ButtonOutlined;
