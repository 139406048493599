import React, { memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import TabContent from 'Components/TabContent';
import Breadcrumb from 'Components/Breadcrumb';
import BreadcrumbLayout from 'Components/BreadcrumbLayout';
import LSPUserData from 'Domains/LSPUserData';
import StudentPracQuiz from 'Containers/StudentPracQuiz';
import TeacherProfileBox from 'Containers/TeacherProfileBox';

const BreadcrumbComponent = memo(function BreadcrumbComponent({ userId, userCode }) {
  const items = [
    {
      key: 'dashboard',
      title: 'Dashboard',
      path: '/teacher/dashboard',
    },
    {
      key: userId,
      title: userCode,
      path: `/teacher/dashboard/${userId}`,
    },
    {
      key: 'pracquiz',
      title: 'Pracquiz',
      path: `/teacher/dashboard/${userId}/pracquiz`,
    },
  ];
  return <Breadcrumb items={items} variant={'dark'} />;
});

const TeacherStudentPracQuizPage = props => {
  const history = useHistory();
  const { studentId: userId } = useParams();

  return (
    <LSPUserData userId={userId}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return null;
        const {
          foProfile: { code, teacherId },
        } = data;
        return (
          <BreadcrumbLayout
            breadcrumbComponent={() => <BreadcrumbComponent userId={userId} userCode={code} />}
          >
            <TabContent
              content={
                <StudentPracQuiz
                  userId={userId}
                  onBack={() => history.push(`/teacher/dashboard/${userId}`)}
                />
              }
              sidebar={<TeacherProfileBox userId={teacherId} />}
            />
          </BreadcrumbLayout>
        );
      }}
    </LSPUserData>
  );
};

export default memo(TeacherStudentPracQuizPage);
