import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, branch, renderComponent, mapProps } from 'recompose';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withAnalytic } from '@skooldio/analytics-provider/dist/Analytics/hoc';

import { withAuth, AUTH_STATE } from 'Libs/Auth';
import { withConfig } from 'Libs/Config';
import NavMenu from 'Containers/NavMenu';
import NavMenuItem from 'Components/NavMenuItem';
import { Box, Image, Text, Flex } from 'Components/Base';
import LESchoolData from 'Domains/LESchoolData';
import { getSchoolCode } from 'Domains/LESchoolData/Routing';
import { extractGraphQLError } from 'Util/utils';
import ERROR_CODE from 'Domains/errorCode';
import AccountInfo from 'Containers/AccountInfo';
import USER_ROLE from 'Domains/userRole';
import { useAnalytics } from 'Libs/Analytics';
import * as mixpanel from 'config/mixpanel';

const MenuBlock = styled(Box)`
  position: fixed;
  top: 0;
  z-index: 1;
  height: ${({ theme }) => theme.space.NAVBAR_NAVMENU_HEIGHT};
`;

const ChildrenBox = styled(Flex)`
  padding-top: ${({ theme }) => theme.space.NAVBAR_NAVMENU_HEIGHT};
  height: 100vh;
  flex-direction: column;
  /* background-color: ${({ theme }) => theme.colors.backgroundPage}; */
  overflow: hidden;
`;

const InnerBlock = styled(Flex)`
  flex: 1 0;
`;

const MenusComponent = memo(function MenusComponent({ menus, schoolCode }) {
  return (
    <MenuBlock bg={'primary'} width={1} px={[4, null, '24px', '40px']}>
      <Flex justifyContent={'space-between'} alignItems="center">
        <Flex alignItems="center">
          <NavMenuItem style={{ padding: 0 }} to={'/'}>
            <Image
              src="/assets/components/AppLayout/logo-le.png"
              srcSet="/assets/components/AppLayout/logo-le.png 1x, /assets/components/AppLayout/logo-le@2x.png 2x, /assets/components/AppLayout/logo-le@3x.png 3x"
              width="170px"
            />
          </NavMenuItem>
          <Box mx={[0, null, 3, 5]} style={{ overflow: 'auto' }}>
            <NavMenu menus={menus} />
          </Box>
        </Flex>
        <Box p={0} flex="0 0 auto">
          <AccountInfo schoolCode={schoolCode} />
        </Box>
      </Flex>
    </MenuBlock>
  );
});

const HandleGQLError = ({ schoolCode, error, logout, onLogAction }) => {
  const { t } = useTranslation('error');
  const gqlError = extractGraphQLError(error);
  const { extensions } = gqlError ?? {};
  const { code } = extensions ?? {};

  const NoPermissionText = t('NO_PERMISSION', { schoolCode });
  const NoSchoolExistsText = t('NO_SCHOOL_EXISTS', { schoolCode });
  const InternalServerErrorText = t('INTERNAL_SERVER_ERROR');

  const menus = [
    {
      path: `/`,
      title: t('HOME'),
    },
  ];

  let ErrorMessage = '';
  switch (code) {
    case ERROR_CODE.BAD_USER_INPUT:
      ErrorMessage = NoSchoolExistsText;
      break;
    case ERROR_CODE.FORBIDDEN:
      ErrorMessage = NoPermissionText;
      break;
    case ERROR_CODE.INTERNAL_SERVER_ERROR:
      ErrorMessage = InternalServerErrorText;
      break;
    default:
      break;
  }

  return (
    <>
      <MenusComponent menus={menus} />
      <ChildrenBox>
        <InnerBlock p={5}>
          <Text.SubTitle color={'primary'}>{ErrorMessage}</Text.SubTitle>
        </InnerBlock>
      </ChildrenBox>
    </>
  );
};

const AppReportLayout = ({
  menus,
  children,
  schoolCode,
  onLogAction,
  appConfig,
  location,
  auth: { myRoles, myProfile },
  ...props
}) => {
  const { send } = useAnalytics();
  const { t } = useTranslation('dashboard');
  return (
    <LESchoolData schoolCode={schoolCode}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) {
          return <HandleGQLError schoolCode={schoolCode} error={error} onLogAction={onLogAction} />;
        }
        const foSchoolInfo = data?.foSchoolInfo ?? {};
        const { code, name } = foSchoolInfo;
        menus = [
          {
            path: `/report/${code}/course-grade${location?.search}`,
            title: t('COURSE_GRADE_TITLE'),
            action: () => {
              onLogAction({
                category: 'Course grade',
                action: `Click`,
                label: `Course grade`,
              });
              send('navigation_click', { to: 'course_code_report' });
              mixpanel.trackEvent('Course Grade Navigator Click', {
                distinct_id: myProfile.id,
              });
            },
          },
          {
            path: `/report/${code}/course-code${location?.search}`,
            title: t('COURSE_TITLE'),
            action: () => {
              onLogAction({
                category: 'Course code',
                action: `Click`,
                label: `Course code`,
              });
              send('navigation_click', { to: 'course_code_report' });
              mixpanel.trackEvent('Course Room Navigator Click', {
                distinct_id: myProfile.id,
              });
            },
          },
          {
            path: `/report/${code}/course-unit${location?.search}`,
            title: t('UNIT_TITLE'),
            //imageIcon: <ReportIcon />,
            action: () => {
              onLogAction({
                category: 'Section',
                action: `Click`,
                label: `Unit`,
              });
              send('navigation_click', { to: 'unit_report' });
              mixpanel.trackEvent('Course Student Navigator Click', {
                distinct_id: myProfile.id,
              });
            },
          },
          {
            path: `/report/${code}/section${location?.search}`,
            title: t('SECTION_TITLE'),
            action: () => {
              onLogAction({
                category: 'Section',
                action: `Click`,
                label: `Section`,
              });
              send('navigation_click', { to: 'section_report' });
              mixpanel.trackEvent('Section Navigator Click', {
                distinct_id: myProfile.id,
              });
            },
          },
        ];

        if (myRoles.includes(USER_ROLE.PROJECTOR_TEACHER)) {
          menus.push({
            href: appConfig?.backofficeUrl ?? '#',
            as: 'a',
            title: t('BACKOFFICE_TITLE'),
            action: () => {
              onLogAction({
                category: 'Backoffice',
                action: `Click`,
                label: `Backoffice`,
              });
              send('navigation_click', { to: 'backoffice_web' });
            },
          });
        }

        return (
          <>
            <MenusComponent menus={menus} schoolCode={schoolCode} />
            <ChildrenBox>
              <InnerBlock p={4}>{children}</InnerBlock>
            </ChildrenBox>
          </>
        );
      }}
    </LESchoolData>
  );
};

AppReportLayout.propTypes = {
  menus: PropTypes.array,
  school: PropTypes.string,
  name: PropTypes.string,
  tel: PropTypes.string,
  lineId: PropTypes.string,
  children: PropTypes.element,
};

AppReportLayout.defaultProps = {
  menus: [],
};

export { AppReportLayout };
export default compose(
  mapProps(getSchoolCode),
  withAuth,
  withConfig,
  withAnalytic,
  branch(
    props => props.auth.authState === AUTH_STATE.NOT_AUTHENTICATE,
    renderComponent(() => {
      const { from } = { from: { pathname: '/login' } };
      return <Redirect to={from} />;
    }),
  ),
)(AppReportLayout);
