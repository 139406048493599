import { useApolloClient } from '@apollo/react-hooks';
import XLSX from 'xlsx';

import { getCourseCodeReport } from 'GraphQL/queries/report.query';
import { CONTENT_TYPE, MODULE_STATUS_TH } from 'Domains/module';
import { formatSchoolTerm } from 'Domains/utils';

const ExportCourseCodeReportData = props => {
  const {
    schoolTermId,
    schoolGradeId,
    classNo,
    subjectId,
    courseCode,
    schoolCode,
    children,
  } = props;
  const client = useApolloClient();

  const fetchCourseReportData = async () => {
    try {
      const { data } = await client.query({
        query: getCourseCodeReport,
        variables: {
          schoolTermId,
          schoolGradeId,
          classNo,
          subjectId,
          courseCode,
          schoolCode,
        },
      });
      return { data };
    } catch (error) {
      return { error };
    }
  };

  const exportCourseCodeReport = async ({ reports, type }) => {
    const {
      schoolTerm: { shortName: schoolTerm } = {},
      schoolGrade: { shortName: schoolGrade } = {},
      classNo,
      subject: { name: subject } = {},
      courseName,
    } = reports[0];

    const formattedReportAOA = [
      ['ภาพรวมการเข้าเรียนและการทำข้อสอบของนักเรียนรายห้อง'],
      [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'การเข้าเรียนของนักเรียน',
        'การเข้าเรียนของนักเรียน',
        'ผลการทำข้อสอบท้ายหน่วยของนักเรียน',
        'ผลการทำข้อสอบท้ายหน่วยของนักเรียน',
        'ผลการทำข้อสอบท้ายหน่วยของนักเรียน',
      ],
      [
        'เทอม',
        'ระดับชั้น',
        'ห้อง',
        'วิชา',
        'หน่วย',
        'บท',
        'เรื่อง',
        'ประเภทไฟล์',
        'จำนวนนักเรียน',
        'สถานะ',
        'ดี (80% ขึ้นไป)',
        'พอใช้ (60-79%)',
        'ควรปรับปรุง (ต่ำกว่า 60%)',
      ],
      ...reports.map(report => {
        const {
          schoolGrade: { shortName: schoolGrade },
          classNo,
          schoolTerm: { shortName: schoolTerm },
          subject: { name: subject },
          courseName,
          sectionName,
          subsectionName,
          contentType,
          totalAttemptedStudents,
          status,
        } = report;
        return [
          schoolTerm,
          schoolGrade,
          `${schoolGrade}/${classNo}`,
          subject,
          `${courseName} (${courseCode})`,
          sectionName,
          subsectionName,
          contentType === 'Quiz' ? CONTENT_TYPE.QUIZ : CONTENT_TYPE.VDO,
          totalAttemptedStudents,
          MODULE_STATUS_TH[status],
          ...(contentType === 'Quiz'
            ? [report.quizResult.good, report.quizResult.normal, report.quizResult.needImprovement]
            : ['', '', '']),
        ];
      }),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(formattedReportAOA);
    worksheet['!merges'] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 12 } }, //ภาพรวมการเข้าเรียนและการทำข้อสอบของนักเรียนรายห้อง
      { s: { r: 1, c: 8 }, e: { r: 1, c: 9 } }, //การเข้าเรียนของนักเรียน
      { s: { r: 1, c: 10 }, e: { r: 1, c: 12 } }, //ผลการทำข้อสอบท้ายหน่วยของนักเรียน
    ];
    worksheet['!cols'] = [
      { wch: 10 }, //ระดับชั้น
      { wch: 10 }, //ห้อง
      { wch: 10 }, //เทอม
      { wch: 25 }, //วิชา
      { wch: 25 }, //หน่วย
      { wch: 30 }, //บท
      { wch: 30 }, //เรื่อง
      { wch: 10 }, //ประเภทไฟล์
      { wch: 15 }, //จำนวนนักเรียน
      { wch: 15 }, //สถานะ
      { wch: 15 }, //ดี
      { wch: 15 }, //พอใช้
      { wch: 15 }, //ปรับปรุง
    ];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'รายห้อง-เข้าเรียนและทำข้อสอบ');
    XLSX.writeFile(
      workbook,
      `ภาพรวมรายห้อง_${formatSchoolTerm(
        schoolTerm,
      )}_${schoolGrade}-${classNo}_${subject}_${courseName}.` + (type || 'xlsx'),
    );
  };

  return children({ fetchCourseReportData, exportCourseCodeReport });
};

export default ExportCourseCodeReportData;
