import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Flex, Text, Icon, ICON_LIST, Link, Image } from '../Base';

const Profile = ({ name, tel, lineId }) => {
  const { t } = useTranslation('dashboard');
  return (
    <Flex alignItems={'center'}>
      <Text
        color={'white'}
        mr={tel ? 2 : lineId ? 2 : 0}
        style={{ textOverflow: 'ellipsis' }}
        flex="0 0 100%"
      >
        {t('HELLO', { name })}
      </Text>
      {tel && (
        <Link href={`tel:${tel}`}>
          <Flex display={'flex'} ml={2}>
            {/* TODO: Replace with SVG when cropped problem solved */}
            <Image
              src={'/assets/components/Icons/tel-icon.png'}
              srcSet={
                '/assets/components/Icons/tel-icon.png 1x, /assets/components/Icons/tel-icon@2x.png 2x, /assets/components/Icons/tel-icon@3x.png 3x'
              }
            />
          </Flex>
        </Link>
      )}
      {lineId && (
        <Link href={`https://line.me/ti/p/~${lineId}`}>
          <Flex display={'flex'} ml={2}>
            <Image
              src={'/assets/components/Icons/line-icon.png'}
              srcSet={
                '/assets/components/Icons/line-icon.png 1x, /assets/components/Icons/line-icon@2x.png 2x, /assets/components/Icons/line-icon@3x.png 3x'
              }
            />
          </Flex>
        </Link>
      )}
    </Flex>
  );
};

Profile.propTypes = {
  name: PropTypes.string,
  tel: PropTypes.string,
  lineId: PropTypes.string,
};

export default Profile;
