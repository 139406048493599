import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import TagItem from 'Components/TagItem';
import Table from 'Components/Table';
import { Text, Box, Link, Icon, ICON_LIST } from 'Components/Base';
import { MODULE_STATUS, PRACQUIZ_STATUS, MODULE_TYPE } from 'Domains/module';

const TextWrapper = styled(Box)`
  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

const ModuleTableStyle = styled(Table)`
  & .MuiTableCell-sizeSmall {
    padding: 5px 15px;
    border-right: 1px solid ${({ theme }) => theme.colors.white};
    border-bottom: none;

    /* -n + 3 is targeting first 3 children */
    &:nth-child(-n + 3) {
      width: 100px;
    }
    &:nth-last-child(1) {
      border-right: none;
    }
  }

  & .MuiTableCell-stickyHeader {
    background-color: ${({ theme }) => theme.colors.inprogress};
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
  }

  & .MuiTableRow-root:nth-child(2n) {
    background-color: ${({ theme }) => theme.colors.tableRowBgAlt};
  }
`;

const toTwoDigits = number => (number < 10 ? `0${number}` : `${number}`);

const secondToMinute = seconds => ({
  minutes: toTwoDigits(Math.floor(seconds / 60)),
  seconds: toTwoDigits(seconds % 60),
});

// maxHeight is not styled system
export const ModuleTable = ({ data, maxHeight }) => {
  const { t } = useTranslation('module');
  const columns = [
    {
      Header: (
        <Box>
          <Text fontWeight="bold">{t('STATUS')}</Text>
        </Box>
      ),
      accessor: 'id',
      Cell: ({
        row: {
          original: {
            module: { type },
            report,
          },
        },
      }) => {
        let completed =
          type === MODULE_TYPE.LESSON
            ? report && report.lessonStatus === MODULE_STATUS.COMPLETED
            : report && report.pracQuizStatus !== PRACQUIZ_STATUS.INCOMPLETED;
        return (
          <TagItem
            py="1"
            px="2"
            tagColor={completed ? 'inprogress' : 'greyscale.grey3'}
            justifyContent="center"
            width={'100px'}
          >
            <Text color="white">{completed ? 'Completed' : 'Incompleted'}</Text>
          </TagItem>
        );
      },
    },
    {
      Header: <Text fontWeight="bold">{t('SUBJECT')}</Text>,
      accessor: 'module.subject.shortname',
      Cell: ({ cell: { value } }) => (
        <Box maxWidth={'120px'}>
          <TextWrapper style={{ textAlign: 'left' }}>{value}</TextWrapper>
        </Box>
      ),
    },
    {
      Header: <Text fontWeight="bold">{t('TOPIC')}</Text>,
      accessor: 'module.topic.name',
      Cell: ({ cell: { value } }) => (
        <Box maxWidth={'120px'}>
          <TextWrapper style={{ textAlign: 'left' }}>{value}</TextWrapper>
        </Box>
      ),
    },
    {
      Header: <Text fontWeight="bold">{t('SUBSECTION')}</Text>,
      accessor: 'module.name',
      Cell: ({
        row: {
          original: {
            module: { name, url },
          },
        },
      }) => (
        <TextWrapper style={{ textAlign: 'left' }}>
          <Link href={url} color="inprogress" clickableStyle="hoverUnderline">
            {name}
          </Link>
        </TextWrapper>
      ),
    },
    {
      Header: (
        <Text textAlign="center" fontWeight="bold">
          {t('CATEGORY')}
        </Text>
      ),
      accessor: 'module.type',
      Cell: ({ cell: { value } }) => (
        <Box maxWidth={'120px'}>
          <TextWrapper>{value === MODULE_TYPE.PRACQUIZ ? 'แบบฝึกหัด' : 'บทเรียน'}</TextWrapper>
        </Box>
      ),
    },
    {
      Header: (
        <Text textAlign="center" fontWeight="bold">
          {t('HOMEWORK')}
        </Text>
      ),
      accessor: 'module.homework',
      Cell: (
        { cell: { value } }, // Right now it's boolean
      ) =>
        value === true ? (
          <Text textAlign="center" color={'black'} fontSize={0}>
            <Icon name={ICON_LIST.SOLID_CIRCLE} />
          </Text>
        ) : (
          <Text textAlign="center" color={'black'} fontSize={0}>
            -
          </Text>
        ),
    },
    {
      Header: (
        <Box style={{ minWidth: '85px' }}>
          <Text textAlign="center" fontWeight="bold">
            {t('DURATION')}
          </Text>
        </Box>
      ),
      accessor: 'module',
      Cell: ({
        cell: {
          value: { type, duration },
        },
      }) => {
        const { minutes, seconds } = secondToMinute(parseInt(duration, 10));
        const text = type === MODULE_TYPE.LESSON ? `${minutes}:${seconds} ${t('MINUTES')}` : '-';
        return (
          <Box>
            <Text textAlign="center">{text}</Text>
          </Box>
        );
      },
      width: 80,
    },
  ];

  return (
    <ModuleTableStyle dense stickyHeader columns={columns} data={data} style={{ maxHeight }} />
  );
};

ModuleTable.propTypes = {
  data: PropTypes.array.isRequired,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ModuleTable;
