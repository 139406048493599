import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy } from 'react-table';
import { ThemeContext } from 'styled-components';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from '@material-ui/core';

import { Icon, ICON_LIST, Flex } from 'Components/Base';

const useStyles = makeStyles({
  table: {
    minWidth: 345,
  },
});

const tableRowStyle = makeStyles({
  root: theme => ({
    '&:nth-child(2n + 1)': {
      backgroundColor: theme.colors.tableRowBg,
    },
    '&:nth-child(2n)': {
      backgroundColor: theme.colors.tableRowBgAlt,
    },
  }),
});

const tableCellStyle = makeStyles({
  root: theme => ({
    fontFamily: theme.fonts.sans,
  }),
  stickyHeader: theme => ({
    fontFamily: theme.fonts.sans,
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    textAlign: 'center',
    left: 'unset',
  }),
  stickyColumnHeader: theme => ({
    fontFamily: theme.fonts.sans,
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    textAlign: 'center',
    left: 'unset',
    position: 'sticky',
    zIndex: 3,
  }),
  stickyColumn: theme => ({
    fontFamily: theme.fonts.sans,
    backgroundColor: 'inherit',
    textAlign: 'center',
    left: 'unset',
    position: 'sticky',
  }),
});

const resolveStickyColumnIds = (lastHeaders, stickyColumnIds) => {
  const stickyColumns = [];
  stickyColumnIds.forEach(columnId => {
    lastHeaders.forEach(({ id }, index) => {
      if (id === columnId) {
        stickyColumns.push(index);
      }
    });
  });
  return stickyColumns;
};

const resolveIsStickyColumn = (rowsLength, stickyColumns, idx) => {
  if (rowsLength >= 1 && stickyColumns.includes(idx)) {
    return true;
  }
  return false;
};

const resolveTotalLeft = (totalLeft, position) => {
  //21: Padding Left: 10, Right: 10 & Border-Right: 1
  if (totalLeft && position) {
    if (position >= 0) return totalLeft + 21 * position;
    return 0;
  }
  return 0;
};

export const SimpleTable = ({
  columns,
  data,
  dense,
  stickyHeader,
  stickyColumnIds,
  renderEmpty,
  getRowProps,
  onMouseOverBodyCell,
  onClickBodyCell,
  ...restProps
}) => {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );
  const theme = useContext(ThemeContext);
  const classes = useStyles(theme);
  const tableRowClasses = tableRowStyle(theme);
  const tableCellClasses = tableCellStyle(theme);
  const rowsLength = rows.length;
  const lastHeaders = headerGroups[headerGroups.length - 1].headers;
  const stickyColumns = resolveStickyColumnIds(lastHeaders, stickyColumnIds);
  // Render the UI for your table
  return (
    <TableContainer {...restProps}>
      <Table
        size={dense ? 'small' : 'medium'}
        stickyHeader={stickyHeader}
        className={classes.table}
        {...getTableProps()}
      >
        <TableHead>
          {headerGroups.map((headerGroup, groupIdx) => {
            const top = groupIdx * 35;
            return (
              <TableRow key={groupIdx} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, idx) => {
                  const isSticky = resolveIsStickyColumn(rowsLength, stickyColumns, idx);
                  const headerProps = column.getHeaderProps(column.getSortByToggleProps());
                  return (
                    <TableCell
                      key={idx}
                      className={
                        isSticky
                          ? tableCellClasses.stickyColumnHeader
                          : tableCellClasses.stickyHeader
                      }
                      {...headerProps}
                      style={{
                        ...headerProps.style,
                        top,
                        left: isSticky ? resolveTotalLeft(column.totalLeft, idx) : 'unset',
                        minWidth: column.totalWidth,
                      }}
                    >
                      <Flex justifyContent="center">
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon
                                name={ICON_LIST.ARROW_DOWNWARD}
                                style={{ marginLeft: '10px' }}
                                size={20}
                              />
                            ) : (
                              <Icon
                                name={ICON_LIST.ARROW_UPWARD}
                                style={{ marginLeft: '10px' }}
                                size={20}
                              />
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </Flex>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {Array.isArray(rows) && rowsLength >= 1
            ? rows.map((row, rowIdx) => {
                prepareRow(row);
                return (
                  <TableRow
                    key={rowIdx}
                    {...row.getRowProps(getRowProps(row))}
                    className={tableRowClasses.root}
                  >
                    {row.cells.map((cell, idx) => {
                      const isSticky = resolveIsStickyColumn(rowsLength, stickyColumns, idx);
                      return (
                        <TableCell
                          key={idx}
                          className={
                            isSticky ? tableCellClasses.stickyColumn : tableCellClasses.root
                          }
                          style={{
                            left: isSticky
                              ? resolveTotalLeft(lastHeaders[idx].totalLeft, idx)
                              : 'unset',
                          }}
                          onMouseOver={() => onMouseOverBodyCell && onMouseOverBodyCell(idx + 1)}
                          onMouseOut={() => onMouseOverBodyCell && onMouseOverBodyCell(null)}
                          onClick={() => onClickBodyCell && onClickBodyCell(cell)}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            : renderEmpty({
                colSpan: Math.max(...headerGroups.map(({ headers }) => headers?.length ?? 0)),
              })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

SimpleTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  stickyColumnIds: PropTypes.arrayOf(PropTypes.string),
  dense: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  renderEmpty: PropTypes.func,
  getRowProps: PropTypes.func,
  onMouseOverBodyCell: PropTypes.func,
  onClickBodyCell: PropTypes.func,
};

SimpleTable.defaultProps = {
  dense: false,
  stickyHeader: false,
  stickyColumnIds: [],
  renderEmpty: () => null,
  getRowProps: e => e,
};

export default SimpleTable;
