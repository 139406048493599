import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { ConfigContext } from './context';
import getConfig from './utils';

const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(getConfig());
  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};

ConfigProvider.propTypes = {
  config: PropTypes.object,
};

export { useConfig };
export default ConfigProvider;
