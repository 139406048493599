import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import styledBy from 'styled-by';

import Box from '../Box';

const BaseContainer = ({ fluid, ...props }) => <Box mx={fluid ? 5 : 'auto'} my={0} {...props} />;

const Container = styled(BaseContainer)`
  margin: 0 auto;
  position: relative;

  @media screen and (min-width: ${props => props.theme.breakpoints[2]}) {
    max-width: 960px;
    width: 960px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 1152px;
    width: 1152px;
  }
  /* @media screen and (min-width: 1472px) {
    max-width: 1344px;
    width: 1344px;
  } */

  ${styledBy(
    'fluid',
    props => css`
      &&& {
        max-width: none;
        width: auto;
      }
    `,
  )}
`;

Container.propTypes = {
  fluid: PropTypes.bool,
};

Container.defaultProps = {
  fluid: false,
};

export default Container;
