import React, { useState, memo, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Text, Box, Flex, Button, Icon, ICON_LIST } from 'Components/Base';
import Dropdown from 'Components/Dropdown';
import Table from 'Components/Table';
import Slider from 'Components/Slider';
import StudentModuleProgressData from 'Domains/StudentModuleProgressData';
import SchoolYearData from 'Domains/SchoolYearData';
import Section from 'Components/Section';
import { MODULE_PROGRESS_STATUS } from 'Domains/module';
import getNextMonday from 'Domains/getNextMonday';

const TableStyle = styled(Table)`
  & .MuiTableCell-sizeSmall {
    padding: 5px 15px;
  }

  & .MuiTableCell-root {
    border: transparent;
  }

  td:nth-child(2) {
    width: 100%;
  }

  & .MuiSlider-root {
    padding: 0;
  }
`;

const TextWrapper = styled(Box)`
  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FilterView = memo(function FilterView({
  setSelectedSchoolTerm,
  setSelectedYearMonth,
  setSelectedWeek,
  selectedSchoolTerm,
  schoolTerms,
  selectedYearMonth,
  yearMonthOptions,
  selectedWeek,
  weekOptions,
}) {
  useEffect(() => {
    setSelectedSchoolTerm(schoolTerms[0].value);
  }, []);

  useEffect(() => {
    setSelectedYearMonth(yearMonthOptions[0].value);
  }, [selectedSchoolTerm]);

  useEffect(() => {
    setSelectedWeek(weekOptions[0].value);
  }, [selectedYearMonth]);

  const { t } = useTranslation('dashboard');
  return (
    <Box>
      <Text>{t('PROGRESS_TITLE')}</Text>
      <Flex mt={4} flexDirection={{ _: 'column', md: 'row' }}>
        <Flex flex="1" px={{ _: 3, md: 0 }} pr={{ md: 3 }} pb={{ _: 2, md: 0 }}>
          <Dropdown
            defaultValue={selectedSchoolTerm}
            title={t('TITLE_SEMESTER')}
            list={schoolTerms}
            onChange={setSelectedSchoolTerm}
          />
        </Flex>
        <Flex flex="1" px={{ _: 3, md: 0 }} pr={{ md: 3 }} pb={{ _: 2, md: 0 }}>
          <Dropdown
            defaultValue={selectedYearMonth}
            title={t('TITLE_MONTH')}
            list={yearMonthOptions}
            onChange={setSelectedYearMonth}
          />
        </Flex>
        <Flex flex="1" px={{ _: 3, md: 0 }} pr={{ md: 3 }} pb={{ _: 2, md: 0 }}>
          <Dropdown
            defaultValue={selectedWeek}
            title={t('TITLE_WEEK')}
            list={weekOptions}
            onChange={setSelectedWeek}
          />
        </Flex>
        <Flex flex="1" px={{ _: 3, md: 0 }}>
          <Button width="100%" color="secondary">
            <Icon name={ICON_LIST.EXPORT} size={18} />
            <Text.SubTitle>{t('EXPORT')}</Text.SubTitle>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
});

const StudentModuleProgress = ({ userId }) => {
  const { t } = useTranslation('dashboard');

  const [selectedSchoolTerm, setSelectedSchoolTerm] = useState('');
  const [selectedYearMonth, setSelectedYearMonth] = useState('');
  const [selectedWeek, setSelectedWeek] = useState('');

  const columns = [
    {
      Header: <Text fontWeight="bold">{t('SUBJECT')}</Text>,
      accessor: 'subject.shortname',
      Cell: ({ cell: { value } }) => <TextWrapper>{value}</TextWrapper>,
    },
    {
      Header: <Text fontWeight="bold">{t('PROGRESS')}</Text>,
      accessor: 'done',
      Cell: ({
        row: {
          original: { done, total, status },
        },
      }) => (
        <Slider
          progressMode
          showValue="off"
          maxValue={total}
          value={done}
          color={status === MODULE_PROGRESS_STATUS.INCOMPLETED ? 'warning' : 'inprogress'}
        />
      ),
    },
    {
      Header: (
        <Text fontWeight="bold" textAlign="center" width={'75px'}>
          {t('NUMBER_OF_VIDEOS')}
        </Text>
      ),
      accessor: 'total',
      Cell: ({
        row: {
          original: { done, total, status },
        },
      }) => (
        <TextWrapper>
          <Text
            textAlign="center"
            color={status === MODULE_PROGRESS_STATUS.INCOMPLETED ? 'warning' : 'inprogress'}
          >{`${done}/${total}`}</Text>
        </TextWrapper>
      ),
    },
  ];

  const [startDate, endDate] = selectedWeek.split('_');

  return (
    <Section
      title={
        <SchoolYearData>
          {({ loading, error, data }) => {
            if (loading) return null;
            if (error) return null;
            const schoolTerms = data.foCurrentSchoolYear.schoolTerms.map(({ name, id }) => ({
              key: id,
              value: id,
              text: name,
            }));
            const schoolTermData =
              data.foCurrentSchoolYear.schoolTerms.find(
                schoolTerm => schoolTerm.id === selectedSchoolTerm,
              ) || data.foCurrentSchoolYear.schoolTerms[0];
            const yearMonthOptions = schoolTermData.yearMonths.map(({ year, month }) => ({
              key: `${year}-${month}`,
              value: `${year}-${month}`,
              text: t('MONTH_YEAR', { date: new Date(year, month, 1) }),
            }));
            yearMonthOptions.splice(0, 0, {
              key: `all`,
              value: `all`,
              text: t('ALL_MONTH_YEARS'),
            });
            const yearMonthData =
              schoolTermData.yearMonths.find(
                ({ year, month }) => `${year}-${month}` === selectedYearMonth,
              ) || schoolTermData.yearMonths[0];
            const weekOptions = yearMonthData.weeks.map(({ date, number }) => ({
              key: `${date}_${date}`,
              value: `${date}_${date}`,
              text: t('WEEK', { number }),
              disabled: selectedYearMonth === 'all',
            }));
            let allWeeksValue;
            if (selectedYearMonth === 'all') {
              const start = schoolTermData.yearMonths[0].weeks[0].date;
              const end =
                schoolTermData.yearMonths[schoolTermData.yearMonths.length - 1].weeks[
                  schoolTermData.yearMonths[schoolTermData.yearMonths.length - 1].weeks.length - 1
                ].date;
              allWeeksValue = `${start}_${end}`;
            } else {
              const start = yearMonthData.weeks[0].date;
              const end = yearMonthData.weeks[yearMonthData.weeks.length - 1].date;
              allWeeksValue = `${start}_${end}`;
            }
            weekOptions.splice(0, 0, {
              key: allWeeksValue,
              value: allWeeksValue,
              text: t('ALL_WEEKS'),
            });
            return (
              <FilterView
                setSelectedSchoolTerm={setSelectedSchoolTerm}
                setSelectedYearMonth={setSelectedYearMonth}
                setSelectedWeek={setSelectedWeek}
                selectedSchoolTerm={selectedSchoolTerm}
                schoolTerms={schoolTerms}
                selectedYearMonth={selectedYearMonth}
                yearMonthOptions={yearMonthOptions}
                selectedWeek={selectedWeek}
                weekOptions={weekOptions}
              />
            );
          }}
        </SchoolYearData>
      }
      detail={
        userId &&
        startDate &&
        endDate && (
          <StudentModuleProgressData
            userId={userId}
            startDate={startDate}
            endDate={getNextMonday(endDate)}
          >
            {({ loading, error, data }) => {
              if (loading) return null;
              if (error) return null;
              return (
                <TableStyle
                  dense
                  stickyHeader
                  columns={columns}
                  data={data.foStudentModuleProgress}
                />
              );
            }}
          </StudentModuleProgressData>
        )
      }
    />
  );
};

export default StudentModuleProgress;
