import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';

import { getStudentModuleByUserId } from 'GraphQL/queries/modules.query';

const ModuleTableData = props => {
  const { userId, startDate, endDate, subjectCode, searchText, children } = props;
  const { loading, error, data } = useQuery(getStudentModuleByUserId, {
    variables: {
      search: {
        userId,
        dateRange: [startDate, endDate],
        // subjectCode, // Client filter
        // searchText, // Client filter
      },
    },
  });

  // TODO: Hot Fix Client Filter
  let filterData;
  if (data && data.foStudentModuleReports) {
    filterData = _.cloneDeep(data);
    filterData.foStudentModuleReports.reports = data.foStudentModuleReports.reports.filter(
      report => {
        let found = true;
        if (subjectCode && subjectCode !== 'all') {
          found = found && report.module.subject.code === subjectCode;
        }
        if (searchText && searchText.length >= 3) {
          found =
            found &&
            (report.module.topic.name.includes(searchText) ||
              report.module.name.includes(searchText));
        }
        return found;
      },
    );
    filterData.foStudentModuleReports.total = data.foStudentModuleReports.reports.length;
  }
  return children({ loading, error, data: filterData });
};

export default ModuleTableData;
