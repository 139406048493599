import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const StyledRadio = styled(Radio)`
  &&& {
    color: ${({ theme, color }) => theme.colors[color]};
  }
`;

export const SimpleRadio = ({ data, title, value, onChange, radioColor, ...restProps }) => {
  return (
    <RadioGroup name={title} value={value} onChange={onChange} {...restProps}>
      {data.map(({ value, label, ...dataProps }, idx) => (
        <FormControlLabel
          key={idx}
          value={value}
          control={<StyledRadio color={radioColor} />}
          label={label}
          {...dataProps}
        />
      ))}
    </RadioGroup>
  );
};

SimpleRadio.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  radioColor: PropTypes.string,
};

SimpleRadio.defaultProps = {
  radioColor: 'primary',
};

export default SimpleRadio;
