import React, { useMemo } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Text, Icon, ICON_LIST } from 'Components/Base';
import BarChart from 'Components/BarChart';
import LegendItem from 'Components/LegendItem';
import { COLOR } from 'Components/Theme';
import StudentPracQuizProgressData from 'Domains/StudentPracQuizProgressData';
import { PRACQUIZ_STATUS } from 'Domains/module';
import SchoolTermData from 'Domains/SchoolTermData';
import Section from 'Components/Section';
import getNextMonday from 'Domains/getNextMonday';

const MeneWrapper = styled(Box)`
  width: 24px;
  height: 24px;
  padding: 4px;
  background-color: ${({ theme }) => theme.colors.greyscale.grey1};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  margin-right: 10px;
`;

const ClickableFlex = styled(Flex)`
  :hover {
    cursor: pointer;
  }
`;

const BarChartWithDataFormat = ({ data }) => {
  const { t } = useTranslation('dashboard');
  const jColors = [COLOR.greyscale.grey4, COLOR.inprogress, COLOR.danger];
  const pracQuizStatuses = [
    {
      key: PRACQUIZ_STATUS.INCOMPLETED,
      label: t('INCOMPLETED'),
    },
    {
      key: PRACQUIZ_STATUS.PASSED,
      label: t('PASSED'),
    },
    {
      key: PRACQUIZ_STATUS.FAILED,
      label: t('FAILED'),
    },
  ];

  const groupedData = _.groupBy(data, d => d.subject.shortname);
  const totalGroupedData = _.map(groupedData, (data, subject) => ({
    [subject]: _.sumBy(data, 'value'),
  })).reduce((prev, curr) => ({ ...prev, ...curr }), 0);

  const formattedData = useMemo(() => {
    return _.map(groupedData, (data, subject) => {
      const newData = { subject };
      data.forEach(datum => {
        const { status, value } = datum;
        newData[status] = Math.round((value * 100) / totalGroupedData[subject]);
      });
      return newData;
    });
  }, [data]);

  return (
    <Box>
      <Flex style={{ overflowX: 'auto' }}>
        <BarChart
          data={formattedData}
          xAxisKey="subject"
          barKeys={pracQuizStatuses}
          barColors={jColors}
          legendType="circle"
          showLabel
          customLabel={value => `${value}%`}
        />
      </Flex>
      <Flex justifyContent={'center'}>
        <LegendItem
          mr={2}
          legendText={pracQuizStatuses[0].label}
          legendTextProps={{ fontSize: 2 }}
          legendColor={jColors[0]}
          legendIconProps={{ fontSize: 2 }}
        />
        <LegendItem
          mr={2}
          legendText={pracQuizStatuses[1].label}
          legendTextProps={{ fontSize: 2 }}
          legendColor={jColors[1]}
          legendIconProps={{ fontSize: 2 }}
        />
        <LegendItem
          legendText={pracQuizStatuses[2].label}
          legendTextProps={{ fontSize: 2 }}
          legendColor={jColors[2]}
          legendIconProps={{ fontSize: 2 }}
        />
      </Flex>
    </Box>
  );
};

const StudentPracQuizProgress = ({ userId, onViewAll }) => {
  const { t } = useTranslation('dashboard');
  return (
    <SchoolTermData>
      {({ loading: schoolTermLoading, error: schoolTermError, data: schoolTermData }) => {
        if (schoolTermLoading) return null;
        if (schoolTermError) return null;
        const {
          foCurrentSchoolTerm: { yearMonths },
        } = schoolTermData;
        const startDate = yearMonths[0].weeks[0].date;
        const endDate =
          yearMonths[yearMonths.length - 1].weeks[
            yearMonths[yearMonths.length - 1].weeks.length - 1
          ].date;
        return (
          <StudentPracQuizProgressData
            userId={userId}
            startDate={startDate}
            endDate={getNextMonday(endDate)}
          >
            {({ loading, error, data }) => {
              if (loading) return null;
              if (error) return null;
              return (
                <Section
                  mt={5}
                  title={
                    <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
                      <Text>{t('PRACQUIZ')}</Text>
                      <ClickableFlex flexDirection="row" alignItems="center" onClick={onViewAll}>
                        <MeneWrapper>
                          <Icon name={ICON_LIST.MENU_BURGER} size={16} />
                        </MeneWrapper>
                        <Text>{t('VIEW_ALL')}</Text>
                      </ClickableFlex>
                    </Flex>
                  }
                  detail={<BarChartWithDataFormat data={data.foStudentPracQuizProgress} />}
                />
              );
            }}
          </StudentPracQuizProgressData>
        );
      }}
    </SchoolTermData>
  );
};

export default StudentPracQuizProgress;
