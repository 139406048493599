import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, Text } from '../../Base';

import HorizontalList from './ListComponents/HorizontalList';

const LIST_TYPE = {
  HORIZONTAL_LIST: 'HORIZONTAL_LIST',
};
const Container = ({ type, children, columnSpacing, rowSpacing, columns, ...props }) => {
  switch (type) {
    case LIST_TYPE.HORIZONTAL_LIST:
      return (
        <HorizontalList
          columnSpacing={columnSpacing}
          rowSpacing={rowSpacing}
          columns={columns}
          flexWrap={'wrap'}
          {...props}
        >
          {children}
        </HorizontalList>
      );
    default:
      return <Box {...props}>{children}</Box>;
  }
};

const EmptyState = ({ emptyText }) => (
  <Text.Light py={6} textAlign="center">
    {emptyText}
  </Text.Light>
);

class List extends Component {
  static propTypes = {
    renderItem: PropTypes.func,
    items: PropTypes.array,
    listType: PropTypes.oneOf(Object.values(LIST_TYPE)),
    emptyText: PropTypes.string,
    renderEmptyState: PropTypes.func,
  };
  static defaultProps = {
    renderItem: () => {},
    items: [],
    emptyText: 'ไม่พบคอร์สออนไลน์หรือเวิร์คชอป',
    renderEmptyState: () => {},
  };

  render() {
    const {
      renderItem,
      items,
      listType,
      columns,
      columnSpacing,
      rowSpacing,
      emptyText,
      renderEmptyState,
      ...props
    } = this.props;
    return (
      <Container
        type={items.length > 0 ? listType : undefined}
        columns={columns}
        columnSpacing={columnSpacing}
        rowSpacing={rowSpacing}
        {...props}
      >
        {items.length > 0 ? (
          items.map((item, index) => {
            return React.cloneElement(renderItem(item, index));
          })
        ) : renderEmptyState ? (
          renderEmptyState()
        ) : (
          <EmptyState emptyText={emptyText} />
        )}
      </Container>
    );
  }
}

List.LIST_TYPE = LIST_TYPE;

export { List, LIST_TYPE };
export default List;
