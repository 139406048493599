import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Box } from 'Components/Base';

const VARIANT = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

const ListItem = ({
  icon,
  children,
  iconContainerProps,
  containerProps,
  variant = VARIANT.VERTICAL,
  ...props
}) => {
  switch (variant) {
    case VARIANT.VERTICAL:
      return (
        <Flex p={2} flexDirection={'column'} alignItems={'center'} {...containerProps}>
          {React.isValidElement(icon) && (
            <Box mb={6} {...iconContainerProps}>
              {React.cloneElement(icon)}
            </Box>
          )}
          <Box {...props}>{children}</Box>
        </Flex>
      );
    case VARIANT.HORIZONTAL:
    default:
      return (
        <Flex p={2} alignItems={'center'} {...containerProps}>
          {React.isValidElement(icon) && (
            <Box mr={6} {...iconContainerProps}>
              {React.cloneElement(icon)}
            </Box>
          )}
          <Box {...props}>{children}</Box>
        </Flex>
      );
  }
};

ListItem.propTypes = {
  icon: PropTypes.element,
  iconContainerProps: PropTypes.object,
  containerProps: PropTypes.object,
  variant: PropTypes.oneOf(Object.values(VARIANT)),
};

ListItem.VARIANT = VARIANT;

export default ListItem;
