import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { compose, branch, renderComponent, mapProps } from 'recompose';

import { withAuth, AUTH_STATE } from '../Libs/Auth';
import Loading from '../Components/Loading';

const CannotAccess = () => <div>You do not have permission to access this page</div>;

const redirectLoginHoc = branch(
  props => props.auth.authState === AUTH_STATE.NOT_AUTHENTICATE,
  renderComponent(
    mapProps(props => ({
      to: {
        pathname: '/login',
        state: { from: props.location },
      },
    }))(Redirect),
  ),
);

const notAccessHoc = (roles = []) =>
  branch(
    ({ auth: { authState, myRoles } }) =>
      authState === AUTH_STATE.AUTHENTICATED &&
      roles &&
      roles.length > 0 &&
      !roles.some(role => myRoles.indexOf(role) >= 0),
    renderComponent(CannotAccess),
  );

const AuthenHOC = (enabledRoles = []) =>
  compose(
    withAuth,
    branch(props => props.auth.authState === AUTH_STATE.PENDING, renderComponent(Loading)),
    redirectLoginHoc,
    notAccessHoc(enabledRoles),
  );

const savedAuthenComponents = {};

const getAuthenComponents = (path, roles, Component) => {
  if (!savedAuthenComponents[path]) {
    savedAuthenComponents[path] = AuthenHOC(roles)(Component);
  }
  return savedAuthenComponents[path];
};

export const PrivateRoute = ({
  path,
  component: Component,
  render,
  roles,
  componentProps,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        const AuthComp = getAuthenComponents(path, roles, Component);
        return <AuthComp {...props} {...componentProps} />;
      }}
    />
  );
};

export const PublicRoute = ({ component: Component, render, roles, componentProps, ...rest }) => {
  return <Route {...rest} render={props => <Component {...props} {...componentProps} />} />;
};

export const LayoutRoute = ({ component: LayoutComponent, children, ...restProps }) => (
  <Route
    {...restProps}
    render={props => <LayoutComponent {...props}>{children}</LayoutComponent>}
  />
);

export default { PrivateRoute, PublicRoute, LayoutRoute };
