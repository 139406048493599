import { Formik as FormContainer, Form, FieldArray } from 'formik';

import TextField from './TextField';
import * as FormValidationRegEx from './validationUtil';

const prepareInitialValues = (initialValues = {}) => {
  const result = { ...initialValues };
  for (const [key, value] of Object.entries(result)) {
    result[key] = value || '';
  }
  return result;
};
export { FormContainer, Form, FieldArray, TextField, FormValidationRegEx, prepareInitialValues };

export default {
  FormContainer,
  Form,
  FieldArray,
  TextField,
  FormValidationRegEx,
  prepareInitialValues,
};
