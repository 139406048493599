import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Table from 'Components/Table';
import { Text, Box } from 'Components/Base';
import ProgressBar from 'Components/ProgressBar';
import { COURSE_GRADE_TYPE } from 'Domains/CourseGradeReportData';
import { RAW_CONTENT_TYPE } from 'Domains/module';

const TextWrapper = styled(Box)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

const AttendanceTableStyle = styled(Table)`
  & .MuiTableCell-sizeSmall {
    padding: 5px 10px;
    border-right: 1px solid ${({ theme }) => theme.colors.white};
    border-bottom: none;

    &:nth-last-child(1) {
      border-right: none;
    }
  }

  & .MuiTableRow-head:nth-child(1) {
    & .MuiTableCell-head {
      &:nth-child(n + 2) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.white};
      }
    }
  }

  & .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  & .MuiTableRow-root:not(.MuiTableRow-head):nth-child(2n + 1) {
    & .MuiTableCell-root:nth-child(1) {
      background-color: rgb(247, 251, 253) !important;
    }
  }

  & .MuiTableRow-root:not(.MuiTableRow-head):nth-child(2n) {
    & .MuiTableCell-root:nth-child(1) {
      background-color: rgb(235, 245, 250) !important;
    }
  }

  & .MuiTableCell-root.MuiTableCell-body:empty {
    background-color: ${({ theme }) => theme.colors.greyscale.grey4};
  }
`;

const TableHead = props => <Text fontWeight={600} {...props} />;
const HeaderColSpanText = styled(TableHead)`
  white-space: nowrap;
`;

export const CourseSectionTable = ({ data, maxHeight, type, ...props }) => {
  const { t } = useTranslation('courseGrade');

  const columns = [
    {
      id: 'classNo',
      Header: <TableHead>{t('CLASS')}</TableHead>,
      accessor: properties => properties?.classNo,
      width: 100,
      Cell: ({ cell: { row } }) => {
        return (
          <TextWrapper width={'100px'}>
            {data?.schoolGrade?.shortName}/{row?.original?.classNo}
          </TextWrapper>
        );
      },
    },
    {
      id: 'courseUnit',
      Header: (
        <HeaderColSpanText>
          {t('LESSON', { courseName: data.course?.courseTitle })}
        </HeaderColSpanText>
      ),
      columns: data.courseSections?.map((courseSection, idx) => ({
        id: `subsection${idx + 1}`,
        Header: <TableHead>{courseSection.courseSectionTitle}</TableHead>,
        accessor: properties => properties.courseSections,
        disableSortBy: true,
        width: 184,
        Cell: ({ cell: { value } }) => {
          if (
            (type === COURSE_GRADE_TYPE.ATTENDANCE_RATE &&
              value[idx]['contentTypes'].includes(RAW_CONTENT_TYPE.VDO)) ||
            (type !== COURSE_GRADE_TYPE.ATTENDANCE_RATE &&
              value[idx]['contentTypes'].includes(RAW_CONTENT_TYPE.QUIZ))
          ) {
            return (
              <ProgressBar
                progress={_.round(value[idx][type], 2)}
                thresholdType={type === COURSE_GRADE_TYPE.EXAMINATION_AVERAGE_RESULT ? 2 : 1}
              />
            );
          } else {
            return null;
          }
        },
      })),
    },
  ];

  return (
    <AttendanceTableStyle
      dense
      stickyHeader
      columns={columns}
      data={data.classes || []}
      stickyColumnIds={['classNo']}
      style={{ maxHeight, paddingBottom: '24px', overflow: 'unset' }}
      getRowProps={() => ({
        hover: true,
      })}
      {...props}
    />
  );
};

CourseSectionTable.propTypes = {
  data: PropTypes.shape({
    reports: PropTypes.array.isRequired,
    subject: PropTypes.object,
    course: PropTypes.object,
  }),
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  courseName: PropTypes.string,
};

export default CourseSectionTable;
