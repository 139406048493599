import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation, withTranslation } from 'react-i18next';

import TagItem from 'Components/TagItem';
import Table from 'Components/Table';
import { Text, Box, Flex, Link, Icon, ICON_LIST } from 'Components/Base';
import { MODULE_STATUS, CONTENT_TYPE } from 'Domains/module';
import { isNil } from 'Util/utils';

const TextWrapper = styled(Box)`
  max-width: 275px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

const AttendanceTableStyle = styled(Table)`
  & .MuiTableCell-sizeSmall {
    padding: 5px 10px;
    border-right: 1px solid ${({ theme }) => theme.colors.white};
    border-bottom: none;

    &:nth-last-child(1) {
      border-right: none;
    }
  }

  & .MuiTableRow-head:nth-child(1) {
    & .MuiTableCell-head {
      &:nth-child(n + 4) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.white};
      }
    }
  }

  & .MuiTableRow-head:nth-child(2) {
    & .MuiTableCell-head {
      &:nth-child(1) {
        width: 250px;
      }

      &:nth-child(n + 3) {
        min-width: 87px;
        width: 87px;
      }
    }
  }
`;

const formatTextDisplay = text => {
  if (text && text.length > 30) {
    return `${text.slice(0, 30 - 4)}...${text.slice(-3)}`;
  }
  return text;
};

const TableHead = props => <Text fontWeight={600} {...props} />;
const HeaderColSpanText = styled(TableHead)`
  /* border-bottom: 1px solid ${({ theme }) => theme.colors.white}; */
  white-space: nowrap;
`;

// maxHeight is not styled system
export const AttendanceTable = ({ data, maxHeight, ...props }) => {
  const { t } = useTranslation('summary');

  const columns = [
    {
      id: 'topic',
      Header: (
        <Box>
          <TableHead>{t('TOPIC')}</TableHead>
        </Box>
      ),
      accessor: 'sectionName',
      disableSortBy: true,
      Cell: ({ cell: { value } }) => (
        <Box>
          <TextWrapper style={{ textAlign: 'left' }}>{value}</TextWrapper>
        </Box>
      ),
    },
    {
      id: 'subsection',
      Header: <TableHead>{t('SUBSECTION')}</TableHead>,
      accessor: 'subsectionName',
      disableSortBy: true,
      Cell: ({ cell: { value } }) => (
        <Box>
          <TextWrapper style={{ textAlign: 'left' }}>{formatTextDisplay(value)}</TextWrapper>
        </Box>
      ),
    },
    {
      id: 'category',
      Header: <TableHead>{t('CATEGORY')}</TableHead>,
      accessor: 'contentType',
      disableSortBy: true,
      Cell: ({ cell: { value } }) => (
        <Box>
          <TextWrapper>{value === 'Video' ? CONTENT_TYPE.VDO : CONTENT_TYPE.QUIZ}</TextWrapper>
        </Box>
      ),
    },
    {
      id: 'attendance',
      Header: <HeaderColSpanText>{t('ATTENDANCE')}</HeaderColSpanText>,
      columns: [
        {
          id: 'numberStudent',
          Header: <TableHead>{t('NUMBER_OF_STUDENT')}</TableHead>,
          accessor: 'totalAttemptedStudents',
          disableSortBy: true,
          Cell: ({ cell: { value } }) => (
            <Box>
              <TextWrapper>{value}</TextWrapper>
            </Box>
          ),
        },
        {
          id: 'status',
          Header: <TableHead>{t('STATUS')}</TableHead>,
          accessor: 'status',
          disableSortBy: true,
          Cell: ({ cell: { value } }) => (
            <Flex justifyContent="center">
              <TagItem
                py="1"
                px="2"
                tagColor={value === MODULE_STATUS.COMPLETED ? 'primary' : 'inprogress'}
                justifyContent="center"
                width={'100px'}
              >
                <Text.Hint color="white">
                  {value === MODULE_STATUS.COMPLETED ? t('COMPLETED') : t('INCOMPLETED')}
                </Text.Hint>
              </TagItem>
            </Flex>
          ),
        },
      ],
    },
    {
      id: 'examResult',
      Header: <HeaderColSpanText>{t('EXAMINATION_RESULT')}</HeaderColSpanText>,
      columns: [
        {
          id: 'good',
          Header: (
            <TableHead>
              {t('GOOD')
                .split('\n')
                .reduce(
                  (init, next, index) => (init ? [...init, <br key={index} />, next] : [next]),
                  null,
                )}
            </TableHead>
          ),
          accessor: 'quizResult.good',
          disableSortBy: true,
          Cell: ({ cell: { value } }) => (
            <Box>
              <TextWrapper>{value}</TextWrapper>
            </Box>
          ),
        },
        {
          id: 'normal',
          Header: (
            <TableHead>
              {t('NORMAL')
                .split('\n')
                .reduce(
                  (init, next, index) => (init ? [...init, <br key={index} />, next] : [next]),
                  null,
                )}
            </TableHead>
          ),
          accessor: 'quizResult.normal',
          disableSortBy: true,
          Cell: ({ cell: { value } }) => (
            <Box>
              <TextWrapper>{value}</TextWrapper>
            </Box>
          ),
        },
        {
          id: 'bad',
          Header: (
            <TableHead>
              {t('BAD')
                .split('\n')
                .reduce(
                  (init, next, index) => (init ? [...init, <br key={index} />, next] : [next]),
                  null,
                )}
            </TableHead>
          ),
          accessor: 'quizResult.needImprovement',
          disableSortBy: true,
          Cell: ({ cell: { value } }) => (
            <Box>
              <TextWrapper>{value}</TextWrapper>
            </Box>
          ),
        },
      ],
    },
  ];
  return (
    <AttendanceTableStyle
      dense
      stickyHeader
      columns={columns}
      data={data}
      style={{ maxHeight }}
      {...props}
    />
  );
};

AttendanceTable.propTypes = {
  data: PropTypes.array.isRequired,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default AttendanceTable;
