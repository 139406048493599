import styled from 'styled-components';
import { border, shadow } from 'styled-system';

import Box from '../Box';

const BorderBox = styled(Box)(border, shadow);

BorderBox.propTypes = {
  ...Box.propTypes,
};

export default BorderBox;
