import React from 'react';
import _ from 'lodash';

import { Flex } from 'Components/Base';
import ButtonIcon from 'Components/ButtonIcon';
import HorizontalSelect from 'Components/HorizontalSelect';

export const convertToBreadCrumb = (filterData = {}) => {
  if (_.isEmpty(filterData)) {
    return [{ key: 'no-filter', title: 'ยังไม่มีการกรองในขณะนี้' }];
  } else {
    // TO-DO: need to discuss with PM for undefined case
    const { term, grade, classNo, subject, syllabus, lesson } = filterData;
    const termName = `เทอม: ${term?.name}`;
    const gradeName = `ระดับชั้น: ${grade?.name}`;

    const breadCrumbData = [
      {
        key: 'term',
        title: termName,
      },
      {
        key: 'grade',
        title: gradeName,
      },
    ];

    if (classNo) {
      const className =
        classNo.className !== `ทุกห้อง`
          ? `ห้อง: ${classNo?.classNo} (${classNo?.totalStudents} คน)`
          : `ห้อง: ${classNo?.className}`;
      breadCrumbData.push({
        key: 'class',
        title: className,
      });
    }

    if (subject) {
      const subjectName = `วิชา: ${subject?.name}`;
      breadCrumbData.push({
        key: 'subject',
        title: subjectName,
      });
    }

    if (syllabus) {
      const syllabusName = `หน่วยการเรียนรู้: ${syllabus?.courseName} (${syllabus?.courseCode})`;
      breadCrumbData.push({
        key: 'syllabus',
        title: syllabusName,
      });
    }

    if (lesson) {
      const lessonName = `บทเรียน: ${lesson.sectionName}`;
      breadCrumbData.push({
        key: 'lesson',
        title: lessonName,
      });
    }
    return breadCrumbData;
  }
};

const getOptionList = list => {
  const { termList, gradeList, classList, subjectList, syllabusList, lessonList } = list;

  const termInfo = termList?.foSchoolTerms?.schoolTerms ?? [];
  const gradeInfo = gradeList?.foGrades?.grades ?? [];
  const classInfo = classList?.foClassNos?.classNos ?? [];
  const subjInfo = subjectList?.foSubjects?.subjects ?? [];
  const syllabusInfo = syllabusList?.foCourses?.courses ?? [];
  const lessonInfo = lessonList?.foLessons?.lessons ?? [];

  return { termInfo, gradeInfo, classInfo, subjInfo, syllabusInfo, lessonInfo };
};

export const getFilterData = (list, selectedValue) => {
  const { term, grade, classNo, subject, syllabus, lesson } = selectedValue;
  const { termInfo, gradeInfo, classInfo, subjInfo, syllabusInfo, lessonInfo } = getOptionList(
    list,
  );

  const termData = termInfo.find(item => item.id === term);
  const subjectData = subjInfo.find(item => item.id === subject);
  const syllabusData = syllabusInfo.find(item => item.courseCode === syllabus);

  const gradeData =
    grade !== `all-grade-selected`
      ? gradeInfo.find(item => item.id === grade)
      : {
          name: 'ทุกระดับชั้น',
        };
  const classData =
    classNo !== `all-class-selected`
      ? classInfo.find(item => item.classNo === classNo)
      : {
          className: 'ทุกห้อง',
        };
  const lessonData =
    lesson !== `all-lesson-selected`
      ? lessonInfo.find(item => item.sectionCode === lesson)
      : {
          sectionName: 'บทเรียนทั้งหมด',
        };

  return {
    term: termData,
    grade: gradeData,
    classNo: classData,
    subject: subjectData,
    syllabus: syllabusData,
    lesson: lessonData,
  };
};

const AttendanceFilter = ({
  list,
  updateValue,
  value,
  isInvalidFilter,
  onFilterApply,
  isDisableResetFilter,
  onResetFilter,
  translation,
  isShowClassFilter = true,
  isShowSyllabusFilter = true,
  isShowLessonFilter = true,
  isAddLessonsOption,
  isAddGradesOption,
  isAddClassNosOption,
}) => {
  const { term, grade, classNo, subject, syllabus, lesson } = value;
  const { termInfo, gradeInfo, classInfo, subjInfo, syllabusInfo, lessonInfo } = getOptionList(
    list,
  );

  const termOptions = termInfo.map(({ id, name }) => ({
    key: id,
    value: id,
    text: name,
  }));

  const gradeOptions = gradeInfo.map(({ id, name }) => ({
    key: id,
    value: id,
    text: name,
  }));

  const addAllGradesOption = options => {
    if (options && options.length >= 1) {
      return [
        { key: 'all-grade-selected', value: 'all-grade-selected', text: 'ทุกระดับชั้น' },
        ...options,
      ];
    }
    return options;
  };

  const classOptions = classInfo.map(({ classNo, totalStudents }) => ({
    key: classNo,
    value: classNo,
    text: `ห้อง ${classNo} (${totalStudents} คน)`,
  }));

  const addAllClassNosOption = options => {
    if (options && options.length >= 1) {
      return [
        { key: 'all-class-selected', value: 'all-class-selected', text: 'ทุกห้อง' },
        ...options,
      ];
    }
    return options;
  };

  const subjectOptions = subjInfo.map(({ id, name }) => ({
    key: id,
    value: id,
    text: name,
  }));

  const syllabusOptions = syllabusInfo.map(({ courseCode, courseName }) => ({
    key: courseCode,
    value: courseCode,
    text: `${courseName} (${courseCode})`,
  }));

  const lessonOptions = lessonInfo.map(({ sectionCode, sectionName }) => ({
    key: sectionCode,
    value: sectionCode,
    text: sectionName,
  }));

  const addAllLessonsOption = options => {
    if (options && options.length >= 1) {
      return [
        { key: 'all-lesson-selected', value: 'all-lesson-selected', text: 'บทเรียนทั้งหมด' },
        ...options,
      ];
    }
    return options;
  };

  return (
    <Flex flexDirection={'column'} w={1}>
      <HorizontalSelect
        title={translation('SEMESTER')}
        selectedValue={term}
        ListOptionValue={termOptions}
        onChange={term => {
          updateValue({
            ...value,
            term,
          });
        }}
      />
      <HorizontalSelect
        title={translation('GRADE')}
        selectedValue={grade}
        ListOptionValue={isAddGradesOption ? addAllGradesOption(gradeOptions) : gradeOptions}
        onChange={grade => {
          updateValue({
            ...value,
            grade,
          });
        }}
      />
      {isShowClassFilter && (
        <HorizontalSelect
          title={translation('CLASS')}
          selectedValue={classNo}
          ListOptionValue={isAddClassNosOption ? addAllClassNosOption(classOptions) : classOptions}
          onChange={classNo => {
            updateValue({
              ...value,
              classNo,
            });
          }}
        />
      )}
      <HorizontalSelect
        title={translation('SUBJECT')}
        selectedValue={subject}
        ListOptionValue={subjectOptions}
        onChange={subject => {
          updateValue({
            ...value,
            subject,
          });
        }}
      />
      {isShowSyllabusFilter && (
        <HorizontalSelect
          title={translation('SYLLABUS')}
          selectedValue={syllabus}
          ListOptionValue={syllabusOptions}
          onChange={syllabus => {
            updateValue({
              ...value,
              syllabus,
            });
          }}
        />
      )}
      {isShowLessonFilter && (
        <HorizontalSelect
          title={translation('LESSON')}
          selectedValue={lesson}
          ListOptionValue={isAddLessonsOption ? addAllLessonsOption(lessonOptions) : lessonOptions}
          onChange={lesson => {
            updateValue({ ...value, lesson });
          }}
        />
      )}
      <Flex justifyContent={'flex-end'} mt={5}>
        <ButtonIcon
          variant={'primaryOutlined'}
          lineHeight={'0.5'}
          height={'40px'}
          marginRight={'8px'}
          message={translation('RESET')}
          disabled={isDisableResetFilter}
          onClick={onResetFilter}
        />
        <ButtonIcon
          variant={'primary'}
          lineHeight={'0.5'}
          height={'40px'}
          message={translation('SUBMIT')}
          disabled={isInvalidFilter}
          onClick={onFilterApply}
        />
      </Flex>
    </Flex>
  );
};

export default AttendanceFilter;
