import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, LabelList } from 'recharts';

const LSPBarChart = ({
  data,
  xAxisKey,
  barKeys,
  barColors,
  legendType,
  showLabel,
  customLabel,
  showGrid,
  showTooltip,
  ...restProps
}) => {
  return (
    <BarChart width={135 * data.length} height={170} data={data} barSize={90}>
      {/* Width has a limitation by library */}
      {showGrid && <CartesianGrid strokeDasharray="3 3" />}
      <XAxis dataKey={xAxisKey} axisLine={false} tickLine={false} />
      <YAxis hide={true} />
      {showTooltip && <Tooltip cursor={false} />}
      {barKeys.map((val, index) => {
        return (
          <Bar
            key={index}
            {...(typeof val === 'string'
              ? { dataKey: val }
              : { dataKey: val.key, name: val.label })}
            fill={barColors[index]}
            stackId={'subject'}
            legendType={legendType}
            minPointSize="0"
          >
            {showLabel && (
              <LabelList
                {...(typeof val === 'string' ? { dataKey: val } : { dataKey: val.key })}
                position="center"
                formatter={customLabel}
                style={{ fill: 'white' }}
              />
            )}
          </Bar>
        );
      })}
    </BarChart>
  );
};

LSPBarChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.array.isRequired,
  xAxisKey: PropTypes.string.isRequired,
  barKeys: PropTypes.array.isRequired,
  barColors: PropTypes.array.isRequired,
  legendType: PropTypes.string,
  showLabel: PropTypes.bool,
  customLabel: PropTypes.func,
  showGrid: PropTypes.bool,
  showTooltip: PropTypes.bool,
};

LSPBarChart.defaultProps = {
  width: 500,
  height: 250,
  legendType: 'square',
  showLabel: false,
  customLabel: null,
  showGrid: false,
  showTooltip: false,
};

export default LSPBarChart;
