import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';

import { COLOR } from '../../../Theme';

import TextFieldBase from './TextFieldBase';

const DropdownBase = ({ options = [], placeholder, ...props }) => {
  return (
    <TextFieldBase select={true} SelectProps={{ displayEmpty: true }} {...props}>
      {placeholder && (
        <MenuItem value="" disabled style={{ color: COLOR.greyscale.grey3 }}>
          <span style={{ color: COLOR.greyscale.grey3 }}> {placeholder}</span>
        </MenuItem>
      )}
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.text}
        </MenuItem>
      ))}
    </TextFieldBase>
  );
};

const FormikDropdown = ({ name, ...props }) => (
  <Fragment>
    <Field name={name} component={DropdownBase} {...props} />
  </Fragment>
);

const Dropdown = ({ isFormik, ...props }) => {
  return isFormik === false ? (
    <DropdownBase isFormik={isFormik} {...props} />
  ) : (
    <FormikDropdown {...props} />
  );
};

DropdownBase.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  placeholder: PropTypes.string,
};

Dropdown.defaultProps = {
  isFormik: true,
};

export default Dropdown;
