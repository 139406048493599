import gql from 'graphql-tag';

export const getSchoolTerms = gql`
  query foSchoolTerms($schoolCode: String!) {
    foSchoolTerms(schoolCode: $schoolCode) {
      total
      schoolTerms {
        id
        name
        startDate
        endDate
      }
    }
  }
`;

export const getGrades = gql`
  query foGrades($schoolTermId: ID!, $schoolCode: String!) {
    foGrades(search: { schoolTermId: $schoolTermId }, schoolCode: $schoolCode) {
      grades {
        id
        code
        name
      }
    }
  }
`;

export const getClassNos = gql`
  query foClassNos($schoolTermId: ID!, $schoolGradeId: ID, $schoolCode: String!) {
    foClassNos(
      search: { schoolTermId: $schoolTermId, schoolGradeId: $schoolGradeId }
      schoolCode: $schoolCode
    ) {
      classNos {
        classNo
        totalStudents
      }
    }
  }
`;

export const getSubjects = gql`
  query foSubjects($schoolTermId: ID!, $schoolGradeId: ID, $classNo: String, $schoolCode: String!) {
    foSubjects(
      search: { schoolTermId: $schoolTermId, schoolGradeId: $schoolGradeId, classNo: $classNo }
      schoolCode: $schoolCode
    ) {
      subjects {
        id
        name
        code
      }
    }
  }
`;

export const getSyllabuses = gql`
  query foCourses(
    $schoolTermId: ID!
    $schoolGradeId: ID
    $classNo: String
    $subjectId: ID!
    $schoolCode: String!
  ) {
    foCourses(
      search: {
        schoolTermId: $schoolTermId
        schoolGradeId: $schoolGradeId
        classNo: $classNo
        subjectId: $subjectId
      }
      schoolCode: $schoolCode
    ) {
      courses {
        courseCode
        courseName
      }
    }
  }
`;

export const getLessons = gql`
  query foLessons($courseCode: String!) {
    foLessons(search: { courseCode: $courseCode }) {
      lessons {
        sectionCode
        sectionName
      }
    }
  }
`;
