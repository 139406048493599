import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { compose, branch, renderComponent } from 'recompose';
import { useTranslation } from 'react-i18next';
import { withAnalytic } from '@skooldio/analytics-provider/dist/Analytics/hoc';

import { withAuth, AUTH_STATE } from 'Libs/Auth';
import {
  Text,
  Button,
  Box,
  Image,
  Flex,
  Icon,
  Form as BaseForm,
  Link as BaseLink,
} from 'Components/Base';
import { useAnalytics } from 'Libs/Analytics';

const { FormContainer, Form, TextField, FormValidationRegEx } = BaseForm;

const FormControl = styled(Form)`
  width: 100%;

  & .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    color: ${({ theme }) => theme.colors.primary};
    margin-left: 22px;
  }

  & input {
    border-radius: 100px;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};

    &:placeholder {
      color: ${({ theme }) => theme.colors.primary};
    }

    &.MuiOutlinedInput-input:-webkit-autofill {
      border-radius: 100px;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  & .MuiOutlinedInput-root.Mui-focused {
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }

  & .MuiOutlinedInput-root:hover {
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const Link = styled(BaseLink)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:active {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const LoginView = props => {
  const { onLogAction } = props;
  const { send } = useAnalytics();
  const { t } = useTranslation('login');
  const [loginError, setLoginError] = useState(null);

  const handleSubmit = (values, actions) => {
    onLogAction({
      category: 'Login',
      action: 'Click',
      label: 'Login Admin',
    });
    send('login', { method: 'credential' });
    const { email, password } = values;
    actions.setSubmitting(false);
    props.auth.login(email, password, error => {
      setLoginError(error.response.data.error);
    });
  };

  const handleInputValidation = values => {
    const { email, password } = values;
    const errors = {};
    if (email.length === 0 || !FormValidationRegEx.EMAIL.test(email)) {
      errors.email = t('EMAIL_REQUIRED');
    }
    if (password.length === 0) {
      errors.password = t('PASSWORD_REQUIRED');
    }
    return errors;
  };

  const getLoginFailMessage = error => {
    const { name, statusCode, message } = error;
    if (statusCode === 401) {
      if (name === 'UnauthorizedError') {
        if (message === 'Email has not been verified') {
          return t('ERROR_NOT_VERIFY');
        } else if (message === 'User not found') {
          return t('ERROR_USER_NOT_FOUND');
        }
      }
      return t('ERROR_INCORRECT_AUTHENTICATION');
    } else if (statusCode >= 500) {
      return t('ERROR_SERVER_ERROR');
    }
    return t('ERROR_DEFAULT');
  };

  return (
    <Flex p={{ _: 3, md: 5, lg: 8 }} flexDirection={'column'}>
      <Box alignSelf={'center'} mb={{ _: 5, md: 8 }}>
        <Image
          src="/assets/components/AppLoginLayout/logo-le.png"
          srcSet="/assets/components/AAppLoginLayoutppLayout/logo-le.png 1x, /assets/components/AppLoginLayout/logo-le@2x.png 2x, /assets/components/AppLoginLayout/logo-le@3x.png 3x"
          width="170"
        />
      </Box>
      <FormContainer
        initialValues={{ email: '', password: '' }}
        isInitialValid={false}
        validate={handleInputValidation}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange, handleBlur, isValid }) => (
          <FormControl>
            <Flex flexDirection={'column'} width={1}>
              {loginError && (
                <Box pb={2}>
                  <Text color="danger" textAlign="center">
                    <Icon name={Icon.ICON_LIST.WARNING} /> {getLoginFailMessage(loginError)}
                  </Text>
                </Box>
              )}
              <TextField.Input
                name="email"
                type="email"
                autoComplete="username"
                maxLength={100}
                label={t('EMAIL')}
                placeholder={t('EMAIL_PLACEHOLDER')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <Box p={4} />
              <TextField.Input
                name="password"
                type="password"
                autoComplete="current-password"
                maxLength={100}
                label={t('PASSWORD')}
                placeholder={t('PASSWORD_PLACEHOLDER')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              <Box p={2} />
              <Link href={'/forget-password'} alignSelf={'flex-end'}>
                {t('FORGET_PASSWORD')}
              </Link>
              <Box p={4} />
              <Button
                id="signin-email"
                variant="primary"
                type="submit"
                data-testid="login-form-submit-login"
                disabled={!isValid}
                width={'100%'}
              >
                {t('SIGNIN')}
              </Button>
            </Flex>
          </FormControl>
        )}
      </FormContainer>
    </Flex>
  );
};

export { LoginView };
export default compose(
  withAuth,
  withAnalytic,
  branch(
    props => props.auth.authState === AUTH_STATE.AUTHENTICATED,
    renderComponent(props => {
      const { from } = props.location.state || { from: { pathname: '/' } };
      return <Redirect to={from} />;
    }),
  ),
)(LoginView);
