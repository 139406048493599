import React from 'react';
import {
  MdRemoveRedEye,
  MdDone,
  MdArrowUpward,
  MdArrowDownward,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdMenu,
  MdClose,
  MdSearch,
  MdError,
  MdEdit,
  MdLanguage,
  MdLockOutline,
  MdLocalPhone,
  MdLoop,
  MdRemove,
} from 'react-icons/md';
import { FaFilter, FaRegCircle, FaCircle, FaUserAlt, FaLine, FaSignOutAlt } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { IoMdArrowRoundBack } from 'react-icons/io';

const ICON_LIST = {
  CHECK: 'MdDone',
  SOLID_CIRCLE: 'FaCircle',
  EYE: 'MdRemoveRedEye',
  MENU_EXPAND_MORE: 'MdKeyboardArrowDown',
  MENU_EXPAND_LESS: 'MdKeyboardArrowUp',
  BACK: 'IoMdArrowRoundBack',
  ARROW_UPWARD: 'MdArrowUpward',
  ARROW_DOWNWARD: 'MdArrowDownward',
  ARROW_LEFT: 'MdKeyboardArrowLeft',
  ARROW_RIGHT: 'MdKeyboardArrowRight',
  FILTER: 'MdFilterList',
  MENU_BURGER: 'MdMenu',
  CLOSE: 'MdClose',
  WARNING: 'MdError',
  SEARCH: 'MdSearch',
  EXPORT: 'FiDownload',
  EDIT: 'MdEdit',
  LOCK: 'MdLockOutline',
  USER: 'FaUserAlt',
  PHONE: 'MdLocalPhone',
  REFRESH: 'MdLoop',
  LINE: 'FaLine',
  LOGOUT: 'FaSignOutAlt',
  REMOVE: 'MdRemove',
};

const getIconComponent = name => {
  switch (name) {
    case ICON_LIST.BACK:
      return IoMdArrowRoundBack;
    case ICON_LIST.CHECK:
      return MdDone;
    case ICON_LIST.SOLID_CIRCLE:
      return FaCircle;
    case ICON_LIST.BORDER_CIRCLE:
      return FaRegCircle;
    case ICON_LIST.EYE:
      return MdRemoveRedEye;
    case ICON_LIST.MENU_EXPAND_MORE:
      return MdKeyboardArrowDown;
    case ICON_LIST.MENU_EXPAND_LESS:
      return MdKeyboardArrowUp;
    case ICON_LIST.ARROW_UPWARD:
      return MdArrowUpward;
    case ICON_LIST.ARROW_DOWNWARD:
      return MdArrowDownward;
    case ICON_LIST.ARROW_LEFT:
      return MdKeyboardArrowLeft;
    case ICON_LIST.ARROW_RIGHT:
      return MdKeyboardArrowRight;
    case ICON_LIST.FILTER:
      return FaFilter;
    case ICON_LIST.MENU_BURGER:
      return MdMenu;
    case ICON_LIST.CLOSE:
      return MdClose;
    case ICON_LIST.WARNING:
      return MdError;
    case ICON_LIST.EXPORT:
      return FiDownload;
    case ICON_LIST.SEARCH:
      return MdSearch;
    case ICON_LIST.EDIT:
      return MdEdit;
    case ICON_LIST.GLOBAL:
      return MdLanguage;
    case ICON_LIST.LOCK:
      return MdLockOutline;
    case ICON_LIST.USER:
      return FaUserAlt;
    case ICON_LIST.PHONE:
      return MdLocalPhone;
    case ICON_LIST.REFRESH:
      return MdLoop;
    case ICON_LIST.LINE:
      return FaLine;
    case ICON_LIST.LOGOUT:
      return FaSignOutAlt;
    case ICON_LIST.REMOVE:
      return MdRemove;
    default:
      return null;
  }
};

const Icon = ({ name, ...props }) => {
  const IconComponent = getIconComponent(name);
  if (!IconComponent) return null;
  return <IconComponent {...props} />;
};

Icon.ICON_LIST = ICON_LIST;

export { ICON_LIST };
export default Icon;
