import React from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';

import {
  NoMatchPage,
  StudentSummaryPage,
  SchoolSummaryPage,
  LoginPage,
  LoginLEPage,
  ForgetPasswordPage,
  ResetPasswordPage,
  SchoolListPage,
  CourseUnitPage,
  IndividualUnitPage,
  CourseGradePage,
} from 'Pages';
import { PrivateRoute, PublicRoute, LayoutRoute } from 'Util/Route';
import AppLayout from 'Containers/AppLayout';
import AppLoginLayout from 'Containers/AppLoginLayout';
import AppReportLayout from 'Containers/AppReportLayout';
import USER_ROLE from 'Domains/userRole';
import useUserTracking from 'Domains/Analytics/useUserTracking';

const RedirectByRole = ({ auth: { myRoles } }) => {
  const role = myRoles[0];
  let pathname = '';
  switch (role) {
    case USER_ROLE.PROJECTOR_TEACHER:
    case USER_ROLE.ADMINISTRATOR:
      pathname = '/schools';
      break;
    case USER_ROLE.SUBJECT_TEACHER:
      pathname = '/report';
      break;
    case USER_ROLE.ADVISOR_TEACHER:
      pathname = '/adv-teacher';
      break;
    case USER_ROLE.STUDENT:
      pathname = '/student';
      break;
    case USER_ROLE.PARENT:
      pathname = '/parent';
      break;
    default:
      console.error(`Incorrect User Role: ${role}`);
      pathname = '/'; // Should never be here
  }

  return (
    <Redirect
      to={{
        pathname,
        state: { from: '/' },
      }}
    />
  );
};

const RedirectToSchool = ({ auth: { myProfile, myRoles } }) => {
  const role = myRoles[0];
  const { additional7: schoolCode } = myProfile;
  let pathname = '';
  switch (role) {
    case USER_ROLE.PROJECTOR_TEACHER:
    case USER_ROLE.ADMINISTRATOR:
      pathname = '/schools';
      break;
    case USER_ROLE.SUBJECT_TEACHER:
      pathname = `/report/${schoolCode}/course-code`;
      break;
    default:
      pathname = '/';
  }
  return (
    <Redirect
      to={{
        pathname,
        state: { from: '/' },
      }}
    />
  );
};

const RouteApp = () => {
  useUserTracking();
  return (
    <Router>
      <Switch>
        <LayoutRoute path={['/', '/schools', '/report']} exact component={AppLayout}>
          <Switch>
            <PrivateRoute
              path="/"
              roles={[
                USER_ROLE.ADMINISTRATOR,
                USER_ROLE.SUBJECT_TEACHER,
                USER_ROLE.ADVISOR_TEACHER,
                USER_ROLE.STUDENT,
                USER_ROLE.PARENT,
                USER_ROLE.PROJECTOR_TEACHER,
              ]}
              exact
              component={RedirectByRole}
            />
            <PrivateRoute
              path="/schools"
              roles={[USER_ROLE.ADMINISTRATOR, USER_ROLE.PROJECTOR_TEACHER]}
              exact
              component={SchoolListPage}
            />
            <PrivateRoute
              path="/report"
              roles={[
                USER_ROLE.ADMINISTRATOR,
                USER_ROLE.SUBJECT_TEACHER,
                USER_ROLE.PROJECTOR_TEACHER,
              ]}
              exact
              component={RedirectToSchool}
            />
          </Switch>
        </LayoutRoute>

        <LayoutRoute
          path={[
            '/report/:schoolCode',
            '/report/:schoolCode/course-code',
            '/report/:schoolCode/section',
            '/report/:schoolCode/course-unit',
            '/report/:schoolCode/course-unit/:username',
            '/report/:schoolCode/course-grade',
          ]}
          exact
          component={AppReportLayout}
        >
          <Switch>
            <PrivateRoute
              path="/report/:schoolCode/course-code"
              roles={[
                USER_ROLE.ADMINISTRATOR,
                USER_ROLE.SUBJECT_TEACHER,
                USER_ROLE.PROJECTOR_TEACHER,
              ]}
              exact
              component={SchoolSummaryPage}
            />
            <PrivateRoute
              path="/report/:schoolCode/section"
              roles={[
                USER_ROLE.ADMINISTRATOR,
                USER_ROLE.SUBJECT_TEACHER,
                USER_ROLE.PROJECTOR_TEACHER,
              ]}
              exact
              component={StudentSummaryPage}
            />
            <PrivateRoute
              path="/report/:schoolCode/course-unit"
              roles={[
                USER_ROLE.ADMINISTRATOR,
                USER_ROLE.SUBJECT_TEACHER,
                USER_ROLE.PROJECTOR_TEACHER,
              ]}
              exact
              component={CourseUnitPage}
            />
            <PrivateRoute
              path="/report/:schoolCode/course-unit/:username"
              roles={[
                USER_ROLE.ADMINISTRATOR,
                USER_ROLE.SUBJECT_TEACHER,
                USER_ROLE.PROJECTOR_TEACHER,
              ]}
              exact
              component={IndividualUnitPage}
            />
            <PrivateRoute
              path="/report/:schoolCode/course-grade"
              roles={[
                USER_ROLE.ADMINISTRATOR,
                USER_ROLE.SUBJECT_TEACHER,
                USER_ROLE.PROJECTOR_TEACHER,
              ]}
              exact
              component={CourseGradePage}
            />
            <Redirect exact from="/report/:schoolCode" to="/report/:schoolCode/course-code" />
          </Switch>
        </LayoutRoute>
        <LayoutRoute
          path={['/login', '/login-admin', '/forget-password', '/reset-password']}
          exact
          component={AppLoginLayout}
        >
          {/* for different layout */}
          <Switch>
            <PublicRoute path="/login" exact component={LoginLEPage} />
            <PublicRoute path="/login-admin" exact component={LoginPage} />
            <PublicRoute path="/forget-password" exact component={ForgetPasswordPage} />
            <PublicRoute path="/reset-password" exact component={ResetPasswordPage} />
          </Switch>
        </LayoutRoute>

        <LayoutRoute component={AppLayout}>
          {/* for nomatch */}
          <Switch>
            <PublicRoute component={NoMatchPage} />
          </Switch>
        </LayoutRoute>
      </Switch>
    </Router>
  );
};

export default RouteApp;
