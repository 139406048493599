import { useApolloClient } from '@apollo/react-hooks';
import XLSX from 'xlsx';
import _ from 'lodash';

import ExportCourseSectionReportData from 'Domains/ExportCourseSectionReportData';

import { COURSE_GRADE_TYPE } from '../CourseGradeReportData';
import { getCourseGradeReport } from '../../GraphQL/queries/report.query';
import { formatSchoolTerm } from '../utils';

const SHEET_NAME = {
  attendanceRate: 'ภาพรวมการเข้าเรียน',
  examinationRate: 'ภาพรวมการเข้าสอบ',
  examinationAverageResult: 'ภาพรวมคะแนน',
  growthRate: '% พัฒนาการ',
};

const SHEETS = [
  COURSE_GRADE_TYPE.EXAMINATION_AVERAGE_RESULT,
  COURSE_GRADE_TYPE.ATTENDANCE_RATE,
  COURSE_GRADE_TYPE.EXAMINATION_RATE,
  COURSE_GRADE_TYPE.GROWTH_RATE,
];

export const convertCourseGradeReportToAOA = (courseGradeReport, sheetType) => {
  const {
    schoolGrade: { shortName: schoolGrade },
    classes = [],
  } = courseGradeReport;
  return classes.map(klass => [
    `${schoolGrade}/${klass.classNo}`,
    ...klass.courses.map(course => {
      if (sheetType === COURSE_GRADE_TYPE.GROWTH_RATE) {
        const formatValue = `${_.round(course[sheetType], 2)}%`;
        return course[sheetType] > 0 ? `+${formatValue}` : formatValue;
      }
      return course[sheetType];
    }),
  ]);
};

const formatReportToAOAHeader = courseGradeReport => {
  const {
    subject: { name: subject },
    courses = [],
  } = courseGradeReport;

  const courseNames = courses.map(course => course.courseName);
  return [
    ['', ...Array(courses.length).fill(subject)],
    ['ห้อง', ...courseNames],
  ];
};

const createAndFormatWorksheet = formattedReportAOA => {
  const worksheet = XLSX.utils.aoa_to_sheet(formattedReportAOA);
  const cols = [
    { wch: 10 }, // ห้อง
    ...Array(formattedReportAOA[0].length - 1).fill({ wch: 15 }), // หน่วยการเรียนรู้
  ];
  worksheet['!merges'] = [
    { s: { r: 0, c: 1 }, e: { r: 0, c: cols.length - 1 } }, // ชื่อวิชา
  ];
  worksheet['!cols'] = cols;
  return worksheet;
};

const ExportCourseGradeReportData = props => {
  const { schoolTermId, schoolGradeId, subjectId, schoolCode, children } = props;

  const client = useApolloClient();

  const fetchCourseGradeReportData = async () => {
    try {
      const { data } = await client.query({
        query: getCourseGradeReport,
        variables: {
          schoolTermId,
          schoolGradeId,
          subjectId,
          schoolCode,
        },
      });
      return { data };
    } catch (error) {
      return { error };
    }
  };

  const exportCourseGradeReport = async ({ report: courseGradeReport, type }) => {
    const {
      schoolGrade: { shortName: schoolGrade } = {},
      schoolTerm: { shortName: schoolTerm } = {},
      subject: { name: subject } = {},
    } = courseGradeReport;

    const formattedReportAOAHeader = formatReportToAOAHeader(courseGradeReport);
    const workbook = XLSX.utils.book_new();
    SHEETS.forEach(sheetType => {
      const formattedReportAOAData = convertCourseGradeReportToAOA(courseGradeReport, sheetType);
      const worksheet = createAndFormatWorksheet([
        ...formattedReportAOAHeader,
        ...formattedReportAOAData,
      ]);
      XLSX.utils.book_append_sheet(workbook, worksheet, SHEET_NAME[sheetType]);
    });

    XLSX.writeFile(
      workbook,
      `ภาพรวมระดับชั้น_${formatSchoolTerm(schoolTerm)}_${schoolGrade}-ทุกห้อง_${subject}.` +
        (type || 'xlsx'),
    );
  };

  const { fetchCourseSectionReportData, exportCourseSectionReport } = ExportCourseSectionReportData(
    props,
  );

  return children({
    fetchCourseGradeReportData,
    exportCourseGradeReport,
    fetchCourseSectionReportData,
    exportCourseSectionReport,
  });
};

export default ExportCourseGradeReportData;
