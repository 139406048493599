import { useEffect } from 'react';
import { useAnalytic as useUniversalAnalytics } from '@skooldio/analytics-provider';

import { useAnalytics } from 'Libs/Analytics';
import { useConfig } from 'Libs/Config';
import { AUTH_STATE, useAuth } from 'Libs/Auth';

export const getDimensionConfig = gaConfig =>
  gaConfig?.dimensions ?? {
    userId: 'dimension2',
    firstName: 'dimension3',
    // gender: 'dimension4',
    // level: 'dimension5',
    // academy: 'dimension6',
    // address: 'dimension7',
  };

function useUserTracking() {
  const { ga } = useUniversalAnalytics();
  const { set } = useAnalytics();
  const { googleAnalytics } = useConfig();
  const { myProfile, authState } = useAuth();
  const dimensionsConfig = getDimensionConfig(googleAnalytics);

  useEffect(() => {
    if (myProfile && authState === AUTH_STATE.AUTHENTICATED) {
      const dimensions = { userId: myProfile.id ?? '' };

      if (dimensionsConfig.userId) {
        dimensions[dimensionsConfig.userId] = myProfile.id ?? '';
      }

      if (dimensionsConfig.firstName) {
        dimensions[dimensionsConfig.firstName] = myProfile.firstname ?? '';
      }

      if (dimensionsConfig.gender) {
        dimensions[dimensionsConfig.gender] = myProfile.additional1 ?? '';
      }

      if (dimensionsConfig.level) {
        dimensions[dimensionsConfig.level] = myProfile.additional7 ?? '';
      }

      if (dimensionsConfig.academy) {
        dimensions[dimensionsConfig.academy] = myProfile.additional8 ?? '';
      }

      if (dimensionsConfig.address) {
        dimensions[dimensionsConfig.address] = myProfile.additional9 ?? '';
      }
      ga.set(dimensions);

      const userProperties = { userId: myProfile.id ?? '', email: myProfile?.email ?? '' };
      set('user_id', myProfile.id ?? '');
      set('user_properties', userProperties);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myProfile, authState, ga]);

  return null;
}

export default useUserTracking;
