import React, { useEffect, useState, useCallback, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import * as mixpanel from 'config/mixpanel';

import { AuthContext } from './context';
import { AUTH_STATE } from './utils';

axios.defaults.withCredentials = true;

const AuthProvider = ({ userApi, children }) => {
  const [myProfile, setMyProfile] = useState({});
  const [myRoles, setMyRoles] = useState([]);
  const [authState, setAuthState] = useState(AUTH_STATE.NONE);

  const trackMixpanelEventLogin = useCallback(data => {
    if (!window.sessionStorage.getItem('session')) {
      mixpanel.trackEvent('User Logs In Click', {
        distinct_id: data?.id,
        Username: data?.username,
        Name: `${data?.firstname} ${data?.lastname}`,
        'School Prefix': data?.additional7,
      });
      window.sessionStorage.setItem('session', true);
    }
  }, []);

  const fetchUserInfo = useCallback(() => {
    setAuthState(AUTH_STATE.PENDING);
    return axios
      .get(`${userApi}/me`)
      .then(data => {
        setMyProfile(data.data);
        setMyRoles(data.data.roles);
        setAuthState(AUTH_STATE.AUTHENTICATED);
        mixpanel.setPeople(data.data.id, data.data);
        trackMixpanelEventLogin(data.data);
      })
      .catch(error => {
        setMyProfile({});
        setMyRoles([]);
        setAuthState(AUTH_STATE.NOT_AUTHENTICATE);
      });
  }, [userApi, trackMixpanelEventLogin]);

  const login = useCallback(
    (username, password, errorCallback = () => {}) => {
      return axios
        .post(`${userApi}/login`, {
          email: username,
          password,
        })
        .then(fetchUserInfo)
        .catch(errorCallback);
    },
    [userApi, fetchUserInfo],
  );

  const logout = useCallback(() => {
    return axios.post(`${userApi}/logout`).then(fetchUserInfo);
  }, [userApi, fetchUserInfo]);

  const requestResetPassword = useCallback(
    email => {
      return axios.post(`${userApi}/reset`, {
        email,
      });
    },
    [userApi],
  );

  const changePassword = useCallback(
    (oldPassword, newPassword) => {
      return axios.patch(`${userApi}/me`, {
        oldPassword,
        newPassword,
        newPasswordVerify: newPassword,
      });
    },
    [userApi],
  );

  const resetPassword = useCallback(
    (password, token) => {
      return axios.post(`${userApi}/resetpassword/${token}`, {
        password,
      });
    },
    [userApi],
  );

  useEffect(() => {
    if (userApi) {
      fetchUserInfo();
    }
  }, [userApi, fetchUserInfo]);

  if (authState === AUTH_STATE.NONE) return null; // Prevent rerendering

  return (
    <AuthContext.Provider
      value={{
        authState,
        myProfile,
        myRoles,
        userApi,
        fetchUserInfo,
        login,
        logout,
        requestResetPassword,
        resetPassword,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  userApi: PropTypes.string.isRequired,
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export { useAuth };
export default AuthProvider;
