import React, { memo } from 'react';

import UserItemComponent from 'Components/UserItem';
import LSPUserData from 'Domains/LSPUserData';

const UserItem = memo(function UserItem({ userId }) {
  return (
    <LSPUserData userId={userId}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return null;
        const {
          foProfile: { code, firstname, lastname, nickname, title, avatar },
        } = data;
        const primaryText = `${nickname} , ${code}`;
        const secondaryText = `${title}${firstname} ${lastname}`;
        return <UserItemComponent imgSrc={avatar} title={primaryText} subtitle={secondaryText} />;
      }}
    </LSPUserData>
  );
});

export default UserItem;
