import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonOutlinedIcon from 'Components/ButtonOutlinedIcon';
import { Box, Flex, Icon, ICON_LIST } from 'Components/Base';

const Wrapper = styled(Box)`
  .MuiInputBase-root {
    width: 100%;
    border-radius: 20px;

    .MuiSelect-root:focus {
      background-color: initial;
    }
  }
`;

const MenuWrapper = styled(Flex)`
  position: absolute;
  flex-direction: column;
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.greyscale.grey4}`};
  background-color: ${({ theme }) => theme.colors.white};
  min-width: 240px;
  margin-top: 4px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;

  > :last-child {
    border-bottom: none;
  }
`;

const Menu = styled(Flex)`
  height: 60px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.greyscale.grey4}`};
  align-items: center;
  padding-left: 16px;
  cursor: pointer;

  &[disabled] {
    cursor: default;
    color: ${({ theme }) => `${theme.colors.greyscale.grey4}`};
  }
`;

const DropdownButton = props => {
  const { title, list, onChange, width, height, menuHeight, align = 'left', ...restProps } = props;
  const [show, setShow] = useState(false);

  const handleChange = value => {
    onChange(value);
    setShow(false);
  };

  return (
    <Wrapper style={{ position: 'relative' }}>
      <ButtonOutlinedIcon
        lineHeight={'1'}
        textProp={{
          fontWeight: 'semibold',
        }}
        message={title}
        rightIcon={<Icon name={ICON_LIST.MENU_EXPAND_MORE} size={20} />}
        onClick={() => setShow(!show)}
        width={width}
        height={height}
        {...restProps}
      />
      {show && (
        <MenuWrapper style={align === 'right' ? { left: 'auto', right: 0 } : {}}>
          {list.map(element => (
            <Menu
              height={menuHeight}
              key={element.key}
              onClick={() => !element.disabled && handleChange(element.value)}
              disabled={element.disabled}
            >
              {element.text}
            </Menu>
          ))}
        </MenuWrapper>
      )}
    </Wrapper>
  );
};

DropdownButton.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      text: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  menuHeight: PropTypes.number,
  align: PropTypes.oneOf(['left', 'right']),
};

DropdownButton.defaultProps = {
  onChange: () => {},
};

export default DropdownButton;
