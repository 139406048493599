// colors that are very specific to some parts/components in the app such as navbar, app, ...
const customColors = {
  backgroundPage: '#F0F2F7',
  tableRowBg: '#F7FBFD',
  tableRowBgAlt: '#EBF5FA',
  navbarIcon: '#66AED9',
  timeUpdate: '#A6A6A6',
  itemDisabled: '#AEB0B2',
  buttonText: '#3C3C3C',
  progressBarPassBg: '#5BC89C',
  progressBarFailBg: '#ED857E',
  passText: '#4BA48A',
  failText: '#EE7B70',
};

const colors = {
  primary: '#0079C1',
  secondary: '#CCE4F3',
  greyscale: {
    grey1: '#3E3E3E',
    grey2: '#4C4C4C',
    grey3: '#7F7F7F',
    grey4: '#E5E5E5',
    grey5: '#F8F8F8',
    grey6: '#F2F2F2',
  },
  white: '#FFFFFF',
  black: '#000000',
  success: '#69D657',
  inprogress: '#66AFDA',
  info: '#1976D2', // Derived from MUI
  warning: '#FFE040',
  danger: '#E0142D',
  dangerAlt: '#FF736A',
  ...customColors,
};

export default colors;
