import React from 'react';
import styled from 'styled-components';
import { width, height } from 'styled-system';

import { Card, Box, Flex, Text, Image } from 'Components/Base';
const Avatar = styled(Image)`
  ${width}
  ${height}
`;

const UserItem = props => {
  const { imgSrc, title, subtitle } = props;
  return (
    <Box px={{ _: 3, md: 4, lg: 8 }} pt={3}>
      <Flex flexDirection="row" alignItems="center">
        <Avatar src={imgSrc} width={53} height={53} borderRadius={53 / 2} />
        <Box ml={3}>
          <Flex flexDirection="column" justifyContent="center">
            <Box>
              <Text textStyle="normal">{title}</Text>
            </Box>
            <Box>
              <Text textStyle="small">{subtitle}</Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default UserItem;
