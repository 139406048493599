import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { withAnalytic } from '@skooldio/analytics-provider/dist/Analytics/hoc';

import { BackdropProgress } from 'Components/Base';
import ButtonIcon from 'Components/ButtonIcon';
import ExportSectionReportData from 'Domains/ExportSectionReportData';
import { useAnalytics } from 'Libs/Analytics';
import DropdownButton from 'Components/DropdownButton';
import * as mixpanel from 'config/mixpanel';

const StudentSummaryExporter = ({
  disabled,
  appliedFilter,
  schoolCode,
  onLogAction,
  myProfileId,
}) => {
  const { send } = useAnalytics();
  const { t } = useTranslation('studentSummary');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    term: { id: schoolTermId } = {},
    grade: { id: schoolGradeId } = {},
    classNo: { classNo } = {},
    subject: { id: subjectId, name: subjectName } = {},
    syllabus: { courseCode } = {},
    lesson: { sectionCode } = {},
  } = appliedFilter;

  const handleClose = () => {
    setOpen(false);
  };

  const handleExportSectionReport = async (fetchSectionReportData, exportSectionReport) => {
    onLogAction({
      category: 'Export',
      action: `Click`,
      label: `Click on section page`,
    });
    send('export', { on: 'section_page' });
    mixpanel.trackEvent('Section Export File 1st Exam Click', {
      distinct_id: myProfileId,
    });
    setLoading(true);
    const { data, error } = await fetchSectionReportData();
    setLoading(false);
    if (!error) {
      const { reports } = data.foExportSectionReport;
      if (reports && reports.length >= 1) {
        await exportSectionReport({
          data: data.foExportSectionReport,
          type: 'xlsx',
        });
      } else {
        setOpen(true);
      }
    }
  };

  const handleExportSectionReportAllExam = async (
    fetchSectionReportAllExamExportData,
    exportSectionReport,
  ) => {
    onLogAction({
      category: 'Export',
      action: `Click`,
      label: `Click on section page`,
    });
    send('export', { on: 'section_page' });
    mixpanel.trackEvent('Section Export File 5 Exam Click', {
      distinct_id: myProfileId,
    });
    setLoading(true);
    const { data, error } = await fetchSectionReportAllExamExportData();
    setLoading(false);
    if (!error) {
      const { reports } = data.foSectionReportAllExamExport;
      if (reports && reports.length >= 1) {
        await exportSectionReport({
          data: data.foSectionReportAllExamExport,
          type: 'xlsx',
          isAllExam: true,
        });
      } else {
        setOpen(true);
      }
    }
  };

  const itemList = [
    {
      key: 1,
      value: 1,
      text: t('FILE_WITH_FIRST_EXAM'),
    },
    {
      key: 2,
      value: 2,
      text: t('FILE_WITH_ALL_EXAM'),
    },
  ];

  return (
    <ExportSectionReportData
      schoolTermId={schoolTermId}
      schoolGradeId={appliedFilter.grade !== `all-grade-selected` ? schoolGradeId : null}
      classNo={appliedFilter.classNo !== `all-class-selected` ? classNo : null}
      subjectId={subjectId}
      courseCode={courseCode}
      sectionCode={sectionCode}
      schoolCode={schoolCode}
    >
      {({ fetchSectionReportData, fetchSectionReportAllExamExportData, exportSectionReport }) => {
        return (
          <Fragment>
            <BackdropProgress open={loading} />
            <DropdownButton
              title={t('EXPORT')}
              list={itemList}
              onChange={value => {
                if (value === 1) {
                  handleExportSectionReport(fetchSectionReportData, exportSectionReport);
                } else if (value === 2) {
                  handleExportSectionReportAllExam(
                    fetchSectionReportAllExamExportData,
                    exportSectionReport,
                  );
                }
              }}
              height={'40px'}
              menuHeight={'40px'}
              disabled={disabled || loading}
            />
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{t('EXPORT_ERROR_TITLE')}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t('EXPORT_ERROR_TEXT', { subjectName })}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ButtonIcon
                  onClick={handleClose}
                  variant={'primary'}
                  lineHeight={'1'}
                  height={'40px'}
                  message={t('SUBMIT')}
                />
              </DialogActions>
            </Dialog>
          </Fragment>
        );
      }}
    </ExportSectionReportData>
  );
};

export default withAnalytic(StudentSummaryExporter);
