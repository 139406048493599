import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Table from 'Components/Table';
import { Text, Box, Icon, ICON_LIST } from 'Components/Base';
import Button from 'Components/ButtonIcon';
import { MODULE_STATUS, RANK_STATUS } from 'Domains/module';

const TextWrapper = styled(Box)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

const AttendanceTableStyle = styled(Table)`
  & .MuiTableCell-sizeSmall {
    padding: 5px 10px;
    border-right: 1px solid ${({ theme }) => theme.colors.white};
    border-bottom: none;

    &:nth-last-child(1) {
      border-right: none;
    }
  }

  & .MuiTableRow-head:nth-child(1) {
    & .MuiTableCell-head {
      &:nth-child(n + 5) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.white};
      }
    }
  }

  & .MuiTableRow-head:nth-child(2) {
    & .MuiTableCell-head {
      &:nth-child(1) {
        min-width: 100px;
        width: 100px;
      }
    }
  }

  // FIXME: [LE-54] Background color on last table cell.
  & .MuiTableRow-root {
    .MuiTableCell-body:nth-last-child(1) {
      padding: 0;
    }
    .MuiTableCell-body {
      height: 62px;
    }
  }
`;

const getBackgroundColor = rank => {
  if (rank === RANK_STATUS.GOOD) {
    return 'success';
  } else if (rank === RANK_STATUS.NORMAL) {
    return 'warning';
  } else if (rank === RANK_STATUS.NEED_IMPROVEMENT) {
    return 'dangerAlt';
  }
};

const TableHead = props => <Text fontWeight={600} {...props} />;
const HeaderColSpanText = styled(TableHead)`
  /* border-bottom: 1px solid ${({ theme }) => theme.colors.white}; */
  white-space: nowrap;
`;

const PAGE_SIZE = 50;

// maxHeight is not styled system
export const AttendanceTable = ({ data, maxHeight, translation, ...props }) => {
  const { t } = useTranslation('studentSummary');
  const { sectionInfos = {}, reports = [] } = data ?? {};
  const [currentPage, setCurrentPage] = useState(1);

  const columns = React.useMemo(() => {
    let subsectionIdx = -1;
    return [
      {
        id: 'classNo',
        Header: <TableHead>{t('CLASS')}</TableHead>,
        accessor: properties => properties.schoolGrade.shortName + '/' + properties.classNo,
        width: 100,
        Cell: ({ cell: { row } }) => (
          <TextWrapper minWidth={'100px'}>
            {row?.original?.schoolGrade.shortName}/{row?.original?.classNo}
          </TextWrapper>
        ),
      },
      {
        id: 'number',
        Header: <TableHead>{t('NUMBER')}</TableHead>,
        accessor: 'studentNo',
        width: 100,
        Cell: ({ cell: { value } }) => (
          <Box>
            <TextWrapper>{value}</TextWrapper>
          </Box>
        ),
      },
      {
        id: 'username',
        Header: <TableHead>{t('USERNAME')}</TableHead>,
        accessor: 'classroomUsername',
        width: 140,
        Cell: ({ cell: { value } }) => (
          <Box>
            <TextWrapper minWidth={'140px'}>{value}</TextWrapper>
          </Box>
        ),
      },
      {
        id: 'fullname',
        Header: <HeaderColSpanText>{t('FULLNAME')}</HeaderColSpanText>,
        accessor: properties => properties.firstName + ' ' + properties.lastName,
        Cell: ({ cell: { value } }) => (
          <Box>
            <TextWrapper style={{ textAlign: 'left' }}>{value}</TextWrapper>
          </Box>
        ),
      },
      ...(_.isEmpty(reports)
        ? [
            {
              id: 'topic',
              Header: (
                <HeaderColSpanText width={'100px'} mx={'auto'}>
                  {t('TOPIC')}
                </HeaderColSpanText>
              ),
              columns: [
                {
                  id: 'subsection',
                  Header: <TableHead>{t('SUBSECTION')}</TableHead>,
                },
              ],
            },
          ]
        : sectionInfos.map(sectionInfo => {
            return {
              id: sectionInfo.sectionCode,
              Header: (
                <HeaderColSpanText>
                  {reports.length > 0 ? `${t('TOPIC')}: ${sectionInfo.sectionName}` : t('TOPIC')}
                </HeaderColSpanText>
              ),
              columns:
                reports.length > 0
                  ? sectionInfo.subsectionInfos.map(subsection => {
                      const { contentType, subsectionName } = subsection;
                      subsectionIdx += 1;
                      if (contentType === 'Video') {
                        return {
                          id: `subsection${subsectionIdx}`,
                          Header: (
                            <TableHead>
                              {t('SUBSECTION')}: {subsectionName}
                            </TableHead>
                          ),
                          disableSortBy: true,
                          accessor: `subsections[${subsectionIdx}]`,
                          Cell: ({ cell: { value } }) => {
                            const { status } = value;
                            return (
                              <Box display={'flex'} justifyContent={'center'}>
                                <Text
                                  color={status === MODULE_STATUS.COMPLETED ? 'primary' : 'danger'}
                                  display={'flex'}
                                  alignItems={'center'}
                                >
                                  <Icon
                                    name={
                                      status === MODULE_STATUS.COMPLETED
                                        ? ICON_LIST.CHECK
                                        : ICON_LIST.CLOSE
                                    }
                                    style={{ marginRight: '10px' }}
                                  />
                                </Text>
                              </Box>
                            );
                          },
                        };
                      } else if (contentType === 'Quiz') {
                        return {
                          id: `subsection${subsectionIdx}`,
                          Header: <TableHead>Q: {subsectionName}</TableHead>,
                          accessor: `subsections[${subsectionIdx}]`,
                          disableSortBy: true,
                          Cell: ({ cell: { value } }) => {
                            const { status } = value;
                            if (status === MODULE_STATUS.INCOMPLETED) {
                              return (
                                <Text display={'flex'}>
                                  <Icon
                                    name={ICON_LIST.REMOVE}
                                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                                  />
                                </Text>
                              );
                            } else {
                              const { score, rank } = value?.quizResult ?? {};
                              return (
                                <Box bg={getBackgroundColor(rank)} px={'10px'} py={'5px'}>
                                  <TextWrapper>{score}</TextWrapper>
                                </Box>
                              );
                            }
                          },
                        };
                      }
                    })
                  : [
                      {
                        id: `subsection`,
                        disableSortBy: true,
                        Header: <TableHead>{t('SUBSECTION')}</TableHead>,
                      },
                    ],
            };
          })),
    ];
  }, [reports]);

  const AttendanceTableBlock = React.useMemo(
    () => (
      <AttendanceTableStyle
        dense
        stickyHeader
        columns={columns}
        stickyColumnIds={['classNo', 'number', 'username', 'fullname']}
        data={data?.reports ? data.reports.slice(0, currentPage * PAGE_SIZE) : []}
        style={{ maxHeight }}
        {...props}
      />
    ),
    [data, currentPage],
  );

  const onLoadMore = React.useCallback(() => {
    setCurrentPage(currentPage + 1);
  });

  const isShowLoadMoreButton =
    data && data.reports && data.reports.length > currentPage * PAGE_SIZE;

  return (
    <Box>
      {AttendanceTableBlock}
      {isShowLoadMoreButton && (
        <Button
          flex="0 0 auto"
          variant={'primary'}
          textProp={{ fontWeight: 'semibold' }}
          lineHeight={'0.5'}
          height={'40px'}
          marginTop={2}
          onClick={onLoadMore}
          message={t('LOAD_MORE')}
        />
      )}
    </Box>
  );
};

AttendanceTable.propTypes = {
  data: PropTypes.array.isRequired,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default AttendanceTable;
