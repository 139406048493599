import gql from 'graphql-tag';

export const getCourseUnitReport = gql`
  query foCourseUnitReport(
    $schoolTermId: ID!
    $schoolGradeId: ID
    $classNo: String
    $subjectId: ID!
    $courseCode: String!
    $schoolCode: String!
  ) {
    foCourseUnitReport(
      search: {
        schoolTermId: $schoolTermId
        schoolGradeId: $schoolGradeId
        classNo: $classNo
        subjectId: $subjectId
        courseCode: $courseCode
      }
      schoolCode: $schoolCode
    ) {
      schoolTerm {
        name
        shortName
      }
      subject {
        name
        shortName
      }
      courseCode
      courseName
      reports {
        studentNo
        classroomUsername
        firstName
        lastName
        schoolGrade {
          id
          name
          shortName
        }
        classNo
        attendanceRate {
          total
          actual
        }
        examinationRate {
          total
          actual
        }
        growthRate
      }
    }
  }
`;

export const getIndividualUnitReport = gql`
  query foIndividualUnitReport(
    $schoolTermId: ID!
    $schoolGradeId: ID!
    $classNo: String!
    $subjectId: ID!
    $courseCode: String!
    $schoolCode: String!
    $classroomUsername: String!
  ) {
    foIndividualUnitReport(
      schoolCode: $schoolCode
      search: {
        schoolTermId: $schoolTermId
        schoolGradeId: $schoolGradeId
        subjectId: $subjectId
        courseCode: $courseCode
        classNo: $classNo
        classroomUsername: $classroomUsername
      }
    ) {
      schoolTerm {
        name
        shortName
      }
      schoolGrade {
        name
        shortName
      }
      subject {
        name
        shortName
      }
      classNo
      studentNo
      classroomUsername
      firstName
      lastName

      courseCode
      courseName
      reports {
        ... on FOIndividualUnitVideoSubsection {
          sectionCode
          sectionName
          subsectionCode
          subsectionName
          contentType
          status
          __typename
        }
        ... on FOIndividualUnitQuizSubsection {
          sectionCode
          sectionName
          subsectionCode
          subsectionName
          contentType
          status
          quizResult {
            score
            fullScore
            rank
          }
          __typename
        }
      }
    }
  }
`;

export const getCourseCodeReport = gql`
  query foCourseReport(
    $schoolTermId: ID!
    $schoolGradeId: ID!
    $classNo: String!
    $subjectId: ID!
    $courseCode: String!
    $sectionCode: String
    $schoolCode: String!
  ) {
    foCourseReport(
      search: {
        schoolTermId: $schoolTermId
        schoolGradeId: $schoolGradeId
        classNo: $classNo
        subjectId: $subjectId
        courseCode: $courseCode
        sectionCode: $sectionCode
      }
      schoolCode: $schoolCode
    ) {
      reports {
        ... on FOCourseReportQuizResult {
          schoolTerm {
            name
            shortName
          }
          schoolGrade {
            name
            shortName
          }
          classNo
          subject {
            name
            shortName
          }
          courseCode
          courseName
          sectionCode
          sectionName
          subsectionCode
          subsectionName
          contentType
          totalAttemptedStudents
          status
          quizResult {
            good
            normal
            needImprovement
          }
          __typename
        }
        ... on FOCourseReportVideoResult {
          schoolTerm {
            name
            shortName
          }
          schoolGrade {
            name
            shortName
          }
          classNo
          subject {
            name
            shortName
          }
          courseCode
          courseName
          sectionCode
          sectionName
          subsectionCode
          subsectionName
          contentType
          totalAttemptedStudents
          status
          __typename
        }
      }
    }
  }
`;

export const getSectionReport = gql`
  query foSectionReport(
    $schoolTermId: ID!
    $schoolGradeId: ID
    $classNo: String
    $subjectId: ID!
    $courseCode: String!
    $sectionCode: String
    $schoolCode: String!
    $quizFilter: String!
  ) {
    foSectionReport(
      search: {
        schoolTermId: $schoolTermId
        schoolGradeId: $schoolGradeId
        classNo: $classNo
        subjectId: $subjectId
        courseCode: $courseCode
        sectionCode: $sectionCode
        quizFilter: $quizFilter
      }
      schoolCode: $schoolCode
    ) {
      sectionInfos {
        sectionCode
        sectionName
        subsectionInfos {
          subsectionCode
          subsectionName
          contentType
        }
      }
      reports {
        studentNo
        classroomUsername
        firstName
        lastName
        schoolGrade {
          name
          shortName
        }
        classNo
        subsections {
          ... on FOSectionReportQuizResult {
            status
            contentType
            quizResult {
              fullScore
              score
              rank
            }
            __typename
          }
          ... on FOSectionReportVideoResult {
            status
            contentType
            __typename
          }
        }
      }
    }
  }
`;

export const getSectionReportForExport = gql`
  query foExportSectionReport(
    $schoolTermId: ID!
    $schoolGradeId: ID
    $classNo: String
    $subjectId: ID!
    $schoolCode: String!
    $courseCode: String!
  ) {
    foExportSectionReport(
      search: {
        schoolTermId: $schoolTermId
        schoolGradeId: $schoolGradeId
        classNo: $classNo
        subjectId: $subjectId
        courseCode: $courseCode
      }
      schoolCode: $schoolCode
    ) {
      schoolTerm {
        name
        shortName
      }
      subject {
        shortName
        name
      }
      sectionInfos {
        sectionCode
        sectionName
        subsectionInfos {
          subsectionCode
          subsectionName
          contentType
          courseName
          courseCode
          sectionName
          sectionCode
        }
      }
      reports {
        schoolGrade {
          name
          shortName
        }
        classNo
        studentNo
        classroomUsername
        firstName
        lastName
        subsections {
          subsectionCode
          subsectionName
          contentType
          status
          score
        }
      }
    }
  }
`;

export const getSectionReportAllExamForExport = gql`
  query foSectionReportAllExamExport(
    $schoolTermId: ID!
    $schoolGradeId: ID
    $classNo: String
    $subjectId: ID!
    $schoolCode: String!
    $courseCode: String!
    $sectionCode: String
  ) {
    foSectionReportAllExamExport(
      schoolCode: $schoolCode
      search: {
        schoolTermId: $schoolTermId
        schoolGradeId: $schoolGradeId
        classNo: $classNo
        subjectId: $subjectId
        courseCode: $courseCode
        sectionCode: $sectionCode
      }
    ) {
      schoolTerm {
        name
        shortName
      }
      subject {
        shortName
        name
      }
      sectionInfos {
        sectionCode
        sectionName
        subsectionInfos {
          subsectionCode
          subsectionName
          contentType
          courseName
          courseCode
          sectionName
          sectionCode
        }
      }
      reports {
        studentNo
        classroomUsername
        firstName
        lastName
        schoolGrade {
          name
          shortName
        }
        classNo
        subsections {
          subsectionCode
          subsectionName
          contentType
          status
          quizResultList {
            ... on FOSectionReportQuizResultDetail {
              __typename
              rank
              score
              fullScore
              round
            }
          }
        }
      }
    }
  }
`;

export const getCourseGradeReport = gql`
  query foCourseGradeReport(
    $schoolTermId: ID!
    $schoolGradeId: ID!
    $subjectId: ID!
    $schoolCode: String!
  ) {
    foCourseGradeReport(
      search: { schoolTermId: $schoolTermId, schoolGradeId: $schoolGradeId, subjectId: $subjectId }
      schoolCode: $schoolCode
    ) {
      schoolTerm {
        name
        shortName
      }
      schoolGrade {
        name
        shortName
      }
      subject {
        name
        shortName
      }
      classes {
        classNo
        courses {
          syllabusId
          attendanceRate
          examinationRate
          examinationAverageResult
          growthRate
        }
      }
      courses {
        syllabusId
        courseName
        courseCode
        attendanceRate
        examinationRate
        examinationAverageResult
      }
      asOfDate
    }
  }
`;

export const getCourseSectionReport = gql`
  query foCourseSectionReport(
    $schoolTermId: ID!
    $schoolGradeId: ID!
    $subjectId: ID!
    $schoolCode: String!
    $courseCode: ID!
  ) {
    foCourseSectionReport(
      search: {
        schoolTermId: $schoolTermId
        schoolGradeId: $schoolGradeId
        subjectId: $subjectId
        courseCode: $courseCode
      }
      schoolCode: $schoolCode
    ) {
      schoolTerm {
        name
        shortName
      }
      schoolGrade {
        name
        shortName
      }
      subject {
        name
        shortName
      }
      course {
        courseCode
        courseTitle
      }
      classes {
        classNo
        courseSections {
          courseSectionId
          courseSectionTitle
          courseSectionOrder
          syllabusId
          contentTypes
          attendanceRate
          examinationRate
          examinationAverageResult
        }
      }
      courseSections {
        courseSectionId
        courseSectionTitle
        courseSectionOrder
        attendanceRate
        examinationRate
        examinationAverageResult
      }
      asOfDate
    }
  }
`;
