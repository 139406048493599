const fillArray = (arr, arrLength, fillItem = null) => {
  const newArr = [...arr];
  if (newArr.length !== arrLength) {
    newArr.length = arrLength;
    newArr.fill(fillItem, arr.length);
  }
  return newArr;
};

export default fillArray;
