import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom'; // @TODO: See if we can use Base Link
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from 'Components/Base';

const Wrapper = styled(Box)``;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 31px 16px;

  /* override fill color */
  & svg {
    width: 25px;
    path {
      fill: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.navbarIcon)};
    }
  }

  & > * {
    color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.navbarIcon)};
  }

  /* hover navbar */
  &:hover {
    & svg path {
      fill: ${({ theme }) => theme.colors.white};
    }

    & > * {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const NavMenuItem = ({ children, to, activeOnlyWhenExact, ...props }) => {
  const match = useRouteMatch({
    path: to?.split('?')?.[0],
    exact: activeOnlyWhenExact,
  });

  return (
    <StyledLink active={match ? 1 : 0} to={to} {...props}>
      {children}
    </StyledLink>
  );
};

NavMenuItem.propTypes = {
  children: PropTypes.element.isRequired,
  to: PropTypes.string.isRequired,
  activeOnlyWhenExact: PropTypes.bool,
};

NavMenuItem.defaultProps = {
  activeOnlyWhenExact: false,
};

export default NavMenuItem;
