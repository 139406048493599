import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { Collapse, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { withAnalytic } from '@skooldio/analytics-provider/dist/Analytics/hoc';

import { Flex, Box, Icon, ICON_LIST, Text, Image } from 'Components/Base';
import Breadcrumb from 'Components/Breadcrumb';
import ButtonIcon from 'Components/ButtonIcon';
import Loading from 'Components/Loading';
import Dropdown from 'Components/Dropdown';
import AttendanceFilter, { getFilterData, convertToBreadCrumb } from 'Containers/AttendanceFilter';
import AttendanceFilterData from 'Domains/AttendanceFilterData';
import CourseGradeReportData, {
  COURSE_GRADE_TYPE,
  COURSE_GRADE_TYPE_REVERSE,
} from 'Domains/CourseGradeReportData';
import CourseSectionReportData from 'Domains/CourseSectionReportData';
import { useAnalytics } from 'Libs/Analytics';
import Criteria from 'Components/Criteria';
import * as mixpanel from 'config/mixpanel';

import { updatedFilter } from '../AttendanceFilter/utils';

import CourseGradeTable from './CourseGradeTable';
import CourseSectionTable from './CourseSectionTable';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    top: '50px',
    zIndex: 5,
    minWidth: '350px',
    width: '100%',
    maxWidth: '700px',
  },
});

const FilterBlock = styled(Box)`
  border-radius: 15px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  width: 100%;
  padding: 28px;
  max-width: 700px;
  background-color: white;
`;

const emptyData = {
  classes: [],
  courses: [{ courseCode: 'emptyId', courseName: '' }],
};

const useQueryString = () => {
  return new URLSearchParams(useLocation().search);
};

const CourseGradeTableView = ({
  currentFilter,
  setSyllabusId,
  data,
  translation,
  setDisabledFetch,
  type,
  myProfileId,
}) => {
  if (!_.isEmpty(currentFilter)) {
    setDisabledFetch(false);
    if (!_.isEmpty(data) && !_.isEmpty(data?.foCourseGradeReport?.classes)) {
      return (
        <CourseGradeTable
          data={data.foCourseGradeReport}
          type={type}
          setSyllabusId={setSyllabusId}
          myProfileId={myProfileId}
        />
      );
    } else {
      return (
        <CourseGradeTable
          data={{
            ...emptyData,
            subject: currentFilter.subject,
          }}
          renderEmpty={({ colSpan }) => (
            <tr>
              <td colSpan={colSpan}>
                <Box bg={'tableRowBg'} py={9}>
                  <Text style={{ textAlign: 'center' }}>{translation('NOT_FOUND')}</Text>
                </Box>
              </td>
            </tr>
          )}
          type={type}
        />
      );
    }
  } else {
    return (
      <CourseGradeTable
        data={emptyData}
        renderEmpty={({ colSpan }) => (
          <tr>
            <td colSpan={colSpan}>
              <Box bg={'tableRowBg'}>
                <Flex flexDirection={'column'} py={9}>
                  <Flex mb={3} mx={'auto'}>
                    <Text>{translation('SELECT_BUTTON')}</Text>
                  </Flex>
                  <Flex mb={3} mx={'auto'}>
                    <Image
                      maxHeight={'40px'}
                      src={'/assets/components/Icons/filter-button-icon.png'}
                      srcSet={
                        '/assets/components/Icons/filter-button-icon.png 1x, /assets/components/Icons/filter-button-icon@2x.png 2x, /assets/components/Icons/filter-button-icon@3x.png 3x'
                      }
                    />
                  </Flex>
                  <Flex mb={3} mx={'auto'}>
                    <Text>{translation('TO_DISPLAYED')}</Text>
                  </Flex>
                </Flex>
              </Box>
            </td>
          </tr>
        )}
        type={type}
      />
    );
  }
};

const CourseSectionTableView = ({ currentFilter, data, translation, setDisabledFetch, type }) => {
  if (!_.isEmpty(currentFilter)) {
    setDisabledFetch(false);
    if (!_.isEmpty(data)) {
      return <CourseSectionTable data={data.foCourseSectionReport} type={type} />;
    } else {
      return (
        <CourseSectionTable
          data={{ reports: [], subject: currentFilter.subject }}
          renderEmpty={({ colSpan }) => (
            <tr>
              <td colSpan={colSpan}>
                <Box bg={'tableRowBg'} py={9}>
                  <Text style={{ textAlign: 'center' }}>{translation('NOT_FOUND')}</Text>
                </Box>
              </td>
            </tr>
          )}
          type={type}
        />
      );
    }
  } else {
    return null;
  }
};

const CourseGrade = ({
  isFetchData,
  setIsFetchData,
  disabledFetch,
  setDisabledFetch,
  onFilterApplied,
  onLogAction,
  schoolCode,
  myProfileId,
}) => {
  const { send } = useAnalytics();
  const { t } = useTranslation('courseGrade');
  const queryString = useQueryString();
  const currentPath = useLocation().pathname;
  const history = useHistory();
  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({});
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [type, setType] = useState(COURSE_GRADE_TYPE.ATTENDANCE_RATE);

  const filterValue = {
    term: currentFilter?.term?.id ?? queryString.get('term') ?? null,
    grade:
      currentFilter?.grade?.id ??
      queryString.get('gradeFilter') ??
      queryString.get('grade') ??
      null,
    classNo: 'all-class-selected',
    subject: currentFilter?.subject?.id ?? queryString.get('subj') ?? null,
    syllabus: currentFilter?.syllabus,
  };

  const handleChange = () => {
    setOpenFilter(prev => !prev);
  };

  const itemList = [
    {
      key: 1,
      value: COURSE_GRADE_TYPE.ATTENDANCE_RATE,
      text: t(COURSE_GRADE_TYPE_REVERSE.attendanceRate),
    },
    {
      key: 2,
      value: COURSE_GRADE_TYPE.EXAMINATION_RATE,
      text: t(COURSE_GRADE_TYPE_REVERSE.examinationRate),
    },
    {
      key: 3,
      value: COURSE_GRADE_TYPE.EXAMINATION_AVERAGE_RESULT,
      text: t(COURSE_GRADE_TYPE_REVERSE.examinationAverageResult),
    },
    {
      key: 4,
      value: COURSE_GRADE_TYPE.GROWTH_RATE,
      text: t(COURSE_GRADE_TYPE_REVERSE.growthRate),
    },
  ];

  const handleFilter = ({ action, filterData, queryString }) => {
    onLogAction({
      category: 'Filter',
      action: `Click`,
      label: `Click ${action} filter on course grade page`,
    });
    send(`${action}_filter`, { on: 'course_grade_TYPE_page' });
    onFilterApplied(filterData);
    setCurrentFilter(filterData);
    setOpenFilter(false);
    if (action === 'submit') {
      mixpanel.trackEvent('Course Grade Filter Submit Click', {
        distinct_id: myProfileId,
        'Year Term': filterData?.term?.name,
        Grade: filterData?.grade?.name,
        Subject: filterData?.subject?.name,
      });
    }
    history.push(`${currentPath}${queryString}`);
  };

  const getCriteriaItems = () => {
    switch (type) {
      case COURSE_GRADE_TYPE.EXAMINATION_AVERAGE_RESULT: {
        return [
          { text: t('GOOD'), color: 'progressBarPassBg' },
          { text: t('NORMAL'), color: 'warning' },
          { text: t('BAD'), color: 'progressBarFailBg' },
        ];
      }
      case COURSE_GRADE_TYPE.GROWTH_RATE: {
        return [
          { text: t('IMPROVE'), color: 'passText' },
          { text: t('NOT_IMPROVE'), color: 'failText' },
        ];
      }
      default: {
        return [
          { text: t('PASS'), color: 'progressBarPassBg' },
          { text: t('FAIL'), color: 'progressBarFailBg' },
        ];
      }
    }
  };

  const onSetSyllabusId = syllabusId => {
    setCurrentFilter({ ...currentFilter, syllabus: syllabusId });
    onFilterApplied({ ...currentFilter, syllabus: syllabusId });
  };

  return (
    <CourseGradeReportData value={filterValue} schoolCode={schoolCode}>
      {({ loading, error, data, refetch }) => {
        const courseGradeTableView = () => {
          if (loading) return <Loading />;
          if (error) return null;
          if (isFetchData) {
            refetch();
            setIsFetchData(false);
          }
          return (
            <CourseGradeTableView
              currentFilter={currentFilter}
              setSyllabusId={onSetSyllabusId}
              data={data}
              translation={t}
              type={type}
              setDisabledFetch={setDisabledFetch}
              myProfileId={myProfileId}
            />
          );
        };

        return (
          <>
            <Box>
              <Flex
                mt={-3}
                mb={4}
                justifyContent={'flex-end'}
                height={'16px'}
                visibility={data?.foCourseGradeReport?.asOfDate ? 'visible' : 'hidden'}
              >
                <Text.Hint
                  color={'timeUpdate'}
                  textAlign={'right'}
                  display={disabledFetch ? 'none' : 'block'}
                >
                  {t('UPDATE_DESCRIPTION_TIME')}{' '}
                  {moment(data?.foCourseGradeReport?.asOfDate)
                    .utc()
                    .format('DD/MM/YYYY HH:mm')}
                </Text.Hint>
              </Flex>
              <AttendanceFilterData
                initialValue={filterValue}
                schoolCode={schoolCode}
                isShowClassFilter={false}
              >
                {({ list, value, updateValue }) => {
                  const { term, grade, subject } = value;
                  const { termList, gradeList, subjectList } = list;
                  if (queryString.toString() !== '' && isFirstTime) {
                    if (termList && gradeList && subjectList) {
                      if (term && grade && subject) {
                        const filterData = getFilterData(list, value);
                        onFilterApplied(filterData);
                        setCurrentFilter(filterData);
                      }
                      setIsFirstTime(false);
                    }
                  } else {
                    updatedFilter(list, value, updateValue, {
                      ignoreClass: true,
                      ignoreLesson: true,
                    });
                  }

                  const handleDisableFilterButton = action => {
                    const values = Object.values(_.pick(value, ['term', 'grade', 'subject']));
                    return action === 'submit' ? values.some(v => !v) : values.every(v => !v);
                  };

                  return (
                    <Flex alignItems="center" style={{ position: 'relative' }}>
                      <ButtonIcon
                        flex="0 0 auto"
                        variant={'primary'}
                        textProp={{
                          fontWeight: 'semibold',
                        }}
                        lineHeight={'0.5'}
                        height={'40px'}
                        leftIcon={<Icon name={ICON_LIST.FILTER} />}
                        message={t('FILTER')}
                        onClick={handleChange}
                      />
                      <Collapse in={openFilter} className={classes.container}>
                        <FilterBlock>
                          <AttendanceFilter
                            isShowClassFilter={false}
                            isShowSyllabusFilter={false}
                            isShowLessonFilter={false}
                            isAddGradesOption={false}
                            isAddClassNosOption={true}
                            list={list}
                            updateValue={updateValue}
                            value={value}
                            isInvalidFilter={handleDisableFilterButton('submit')}
                            onFilterApply={() =>
                              handleFilter({
                                action: 'submit',
                                filterData: getFilterData(list, value),
                                queryString: `?term=${term}&grade=${grade}&subj=${subject}&class=all-class-selected`,
                              })
                            }
                            onResetFilter={() =>
                              handleFilter({
                                action: 'reset',
                                filterData: {},
                                queryString: '',
                              })
                            }
                            isDisableResetFilter={handleDisableFilterButton('reset')}
                            translation={t}
                          />
                        </FilterBlock>
                      </Collapse>
                      <Breadcrumb
                        maxItemsPerRow={3}
                        items={convertToBreadCrumb(
                          !_.isEmpty(currentFilter)
                            ? { ...currentFilter, classNo: null, syllabus: null }
                            : {},
                        )}
                        p={0}
                        ml={4}
                      />
                    </Flex>
                  );
                }}
              </AttendanceFilterData>
              <Flex mt={5} alignItems={'center'}>
                <Criteria
                  title={t(`${COURSE_GRADE_TYPE_REVERSE[type]}_CRITERIA`)}
                  values={getCriteriaItems()}
                />
                <Flex ml={'auto'} width={'200px'}>
                  <Dropdown
                    list={itemList}
                    defaultValue={COURSE_GRADE_TYPE.ATTENDANCE_RATE}
                    onChange={value => {
                      mixpanel.trackEvent('Metric Type Click', {
                        distinct_id: myProfileId,
                        Type: t(COURSE_GRADE_TYPE_REVERSE[type]),
                      });
                      if (value === COURSE_GRADE_TYPE.GROWTH_RATE) {
                        onSetSyllabusId(null);
                      }
                      setType(value);
                    }}
                  />
                </Flex>
              </Flex>
            </Box>
            <Box style={{ overflow: 'hidden' }}>
              <Box style={{ overflow: 'auto', height: '100%' }}>
                <Box>{courseGradeTableView()}</Box>
                <Box style={{ marginTop: '16px' }}>
                  <CourseSectionReportData value={filterValue} schoolCode={schoolCode}>
                    {({ loading, error, data, refetch }) => {
                      if (loading) return <Loading />;
                      if (isFetchData) {
                        refetch();
                        setIsFetchData(false);
                      }
                      if (error || !data) return null;
                      return (
                        <CourseSectionTableView
                          currentFilter={currentFilter}
                          data={data}
                          translation={t}
                          setDisabledFetch={setDisabledFetch}
                          type={type}
                        />
                      );
                    }}
                  </CourseSectionReportData>
                </Box>
              </Box>
            </Box>
          </>
        );
      }}
    </CourseGradeReportData>
  );
};

CourseGrade.defaultProps = {
  onFilterApplied: {},
};

export default withAnalytic(CourseGrade);
