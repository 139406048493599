import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'use-media';
import { ThemeContext } from 'styled-components';

import StudentRIASECData from 'Domains/StudentRIASECData';
import { KEY_COLOR, TYPE, TYPE_COLOR } from 'Domains/riasec';
import RadarChart from 'Components/RadarChart';
import { Text, Flex } from 'Components/Base';
import Radio from 'Components/Radio';
import Section from 'Components/Section';

import ElementList from './ElementList';

const StudentRIASEC = ({ userId }) => {
  const { t } = useTranslation('dashboard');
  const [type, setType] = useState(TYPE.TEACHER);
  const theme = useContext(ThemeContext);
  const isBreakpointsMd = useMedia({ minWidth: theme.breakpoints.md });
  const radioData = [
    { value: TYPE.TEACHER, label: t('TEACHER') },
    { value: TYPE.STUDENT, label: t('STUDENT') },
  ];
  const dataKeys = [
    {
      name: t('TEACHER'),
      key: TYPE.TEACHER,
      stroke: TYPE_COLOR.TEACHER,
      fill: TYPE_COLOR.TEACHER,
      fillOpacity: 0.2,
    },
    {
      name: t('STUDENT'),
      key: TYPE.STUDENT,
      stroke: TYPE_COLOR.STUDENT,
      fill: TYPE_COLOR.STUDENT,
      fillOpacity: 0.2,
    },
  ];
  return (
    <StudentRIASECData userId={userId}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return null;
        const elementData = data.map(({ code, teacher, student, max }) => {
          const score = type === TYPE.TEACHER ? teacher : student;
          return {
            alphabet: code,
            color: KEY_COLOR[code],
            percent: Math.round((score * 100) / max),
            name: t(`RIASEC_${code}`),
          };
        });
        return (
          <Section
            mt={5}
            title={<Text>{t('RIASEC')}</Text>}
            detail={
              <Flex
                justifyContent="space-between"
                flexDirection={{ _: 'column', md: 'row' }}
                alignItems={'center'}
              >
                <Flex flexDirection={{ _: 'row', md: 'column' }} justifyContent="center">
                  <Radio
                    data={radioData}
                    tilte="type"
                    value={type}
                    onChange={event => setType(event.target.value)}
                    style={isBreakpointsMd ? { flexDirection: 'column' } : { flexDirection: 'row' }}
                  />
                </Flex>
                <Flex mt={{ _: 2, md: 0 }} justifyContent="center" width={{ _: 1, md: 'unset' }}>
                  <ElementList data={elementData} />
                </Flex>
                <RadarChart data={data} axisKey="code" axisColors={KEY_COLOR} dataKeys={dataKeys} />
              </Flex>
            }
          />
        );
      }}
    </StudentRIASECData>
  );
};

export default StudentRIASEC;
