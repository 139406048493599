import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Box, Text, Flex, ToolbarButton, ICON_LIST } from 'Components/Base';
import Dropdown from 'Components/Dropdown';
import SearchInput from 'Components/SearchInput';

const PracQuizFilter = ({
  yearMonth,
  monthOptions,
  setYearMonth,
  selectedDate,
  mondayOptions,
  setSelectedDate,
  onDateFilter,
  onSearchText,
}) => {
  useEffect(() => {
    setYearMonth(monthOptions[0].value);
  }, []);

  useEffect(() => {
    setSelectedDate(mondayOptions[0].value);
    onDateFilter(mondayOptions[0].value);
  }, [yearMonth]);

  const { t } = useTranslation('module');
  const year = yearMonth ? Number(yearMonth.split('-')[0]) : '';
  const month = yearMonth ? Number(yearMonth.split('-')[1]) : '';
  const endDate = new Date(selectedDate);
  endDate.setDate(endDate.getDate() + 6);
  const week = _.findIndex(mondayOptions, ({ key }) => key === selectedDate);

  return (
    <Box>
      <Flex mb={4} flexDirection={{ _: 'column', md: 'row' }}>
        <Flex flex="1" pr={{ md: 3 }}>
          <Dropdown
            defaultValue={yearMonth}
            title={t('FILTER_MONTH')}
            list={monthOptions}
            onChange={setYearMonth}
          />
        </Flex>
        <Flex flex="2" pt={{ _: 2, md: 0 }}>
          <SearchInput placeholder={t('SEARCH')} onChange={text => onSearchText(text)} />
        </Flex>
      </Flex>
      <Flex alignItems="center" mb={4}>
        <Flex flex="1">
          <Text>{t('MONTH', { date: new Date(year, month, 1) })}</Text>
        </Flex>
        <Flex flex="1" justifyContent="center" alignItems="center" style={{ minWidth: '150px' }}>
          <ToolbarButton
            startIcon={ICON_LIST.ARROW_LEFT}
            iconSize={28}
            color="primary"
            onClick={
              week > 0 &&
              (() => {
                setSelectedDate(mondayOptions[week - 1].value);
                onDateFilter(mondayOptions[week - 1].value);
              })
            }
            disabled={week === 0}
          />
          {week >= 0 && <Text>{mondayOptions[week].text}</Text>}
          <ToolbarButton
            startIcon={ICON_LIST.ARROW_RIGHT}
            iconSize={28}
            color="primary"
            onClick={
              week < mondayOptions.length - 1 &&
              (() => {
                setSelectedDate(mondayOptions[week + 1].value);
                onDateFilter(mondayOptions[week + 1].value);
              })
            }
            disabled={week === mondayOptions.length - 1}
          />
        </Flex>
        <Flex flex="1" justifyContent="flex-end">
          <Text>
            {t('PERIOD', {
              startDate: selectedDate,
              endDate: endDate,
            })}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

PracQuizFilter.propTypes = {
  yearMonth: PropTypes.string.isRequired,
  monthOptions: PropTypes.array.isRequired,
  setYearMonth: PropTypes.func.isRequired,
  selectedDate: PropTypes.object.isRequired,
  mondayOptions: PropTypes.array.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  onDateFilter: PropTypes.func.isRequired,
  onSearchText: PropTypes.func.isRequired,
};

export default PracQuizFilter;
