import { useQuery } from '@apollo/react-hooks';

import { getCourseCodeReport } from 'GraphQL/queries/report.query';

const CourseCodeData = ({ value, schoolCode, children }) => {
  const { term, grade, classNo, subject, syllabus, lesson } = value;

  const { loading, error, data, refetch } = useQuery(getCourseCodeReport, {
    variables: {
      schoolTermId: term,
      schoolGradeId: grade,
      classNo,
      subjectId: subject,
      courseCode: syllabus,
      sectionCode: lesson !== `all-lesson-selected` ? lesson : null,
      schoolCode,
    },
    skip: !term || !grade || !classNo || !subject || !syllabus || !lesson || !schoolCode,
  });

  return children({ loading, error, data, refetch });
};

export default CourseCodeData;
