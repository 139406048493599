import defaults from 'lodash/defaults';
import log from 'loglevel';

import theme from 'Components/Theme';

let config = null;

const defaultConfig = {
  log: 'error',
  userApi: 'http://localhost:5000/api/users',
  graphApi: 'http://localhost:4000/graphql',
  gaId: '',
  theme,
  oauth: {
    loginURL: 'https://api.le-report.staging.skooldio.app/user/api/login/learned',
  },
};

function getConfig() {
  if (config === null) {
    /**
     * This will merge only 1 level deep, it will not recursivly merge values.
     * Previously it used defaultsDeep, which is ok except for array value, it will append array elements
     */
    config = defaults({}, window.appConfig, defaultConfig);
    log.setLevel(config.log);
    log.debug(config);
  }
  return config;
}

export const getConfigContext = props => {
  const { configContext } = props;
  return configContext;
};

export default getConfig;
