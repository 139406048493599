import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { compose, branch, renderComponent } from 'recompose';
import { useTranslation } from 'react-i18next';
import { withAnalytic } from '@skooldio/analytics-provider/dist/Analytics/hoc';

import { withAuth, AUTH_STATE } from 'Libs/Auth';
import { withConfig } from 'Libs/Config';
import { Button, Box, Image, Flex } from 'Components/Base';
import Loading from 'Components/Loading';
import { useAnalytics } from 'Libs/Analytics';

const LoginLEView = props => {
  const { onLogAction } = props;
  const { send } = useAnalytics();
  const { t } = useTranslation('login');

  const handleLogin = () => {
    onLogAction({
      category: 'Login',
      action: 'Click',
      label: 'Login LE',
    });
    send('login', { method: 'LE' });
    window.location = props.appConfig.oauth.loginURL;
  };

  useEffect(() => {
    if (props.auth.authState === AUTH_STATE.NOT_AUTHENTICATE) {
      handleLogin();
    }
    return () => {};
  }, [props.auth.authState, handleLogin]);

  return (
    <Flex p={{ _: 3, md: 5, lg: 8 }} flexDirection={'column'}>
      <Box alignSelf={'center'} mb={{ _: 5, md: 8 }}>
        <Image
          src="/assets/components/AppLoginLayout/logo-le.png"
          srcSet="/assets/components/AppLoginLayout/logo-le.png 1x, /assets/components/AppLoginLayout/logo-le@2x.png 2x, /assets/components/AppLoginLayout/logo-le@3x.png 3x"
          width="170"
        />
      </Box>

      <Flex flexDirection={'column'} width={1}>
        {props.auth.authState === AUTH_STATE.PENDING && <Loading />}
        {props.auth.authState === AUTH_STATE.NOT_AUTHENTICATE && (
          <Button
            id="signin-email"
            variant="primary"
            type="submit"
            data-testid="login-form-submit-login"
            width={'100%'}
            onClick={handleLogin}
          >
            {t('SIGNIN')}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export { LoginLEView };
export default compose(
  withAuth,
  withConfig,
  withAnalytic,
  branch(
    props => props.auth.authState === AUTH_STATE.AUTHENTICATED,
    renderComponent(props => {
      const { from } = props.location.state || { from: { pathname: '/' } };
      return <Redirect to={from} />;
    }),
  ),
)(LoginLEView);
