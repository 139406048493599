import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex } from '../../../Base';
import { theme } from '../../../Theme';
import fillArray from '../Util/fillArray';

const createCss = (cols, columnSpacing) => {
  return `
    width: ${`calc((100% - (${columnSpacing} * ${cols - 1})) / ${cols})`};
    &:nth-child(n) {
      margin-right: ${columnSpacing};
    }

    &:nth-child(${cols + 'n'}) {
      margin-right: 0;
    }

    &:nth-last-child(-n + ${cols}) {
      margin-bottom: 0;
    }
  `;
};

const media = (columns, columnSpacing) => {
  const css = theme.breakpoints.reduce((acc, currValue, index) => {
    return (
      acc +
      `@media (min-width: ${theme.breakpoints[index]}) {
          ${createCss(columns[index + 1], columnSpacing)};
        }
      `
    );
  }, createCss(columns[0], columnSpacing));
  return css;
};

const HorizontalList = styled(({ columns, columnSpacing, rowSpacing, ...props }) => (
  <Flex {...props} />
)).attrs(props => {
  const array = !Array.isArray(props.columns) ? [props.columns] : props.columns;
  return { columns: fillArray(array, theme.breakpoints.length + 1, array[array.length - 1]) };
})`
  && {
    display: flex;
    flex-wrap: wrap;

    > * {
      margin-right: ${props => props.columnSpacing};
      margin-bottom: ${props => props.rowSpacing};

      /* width and margin of component in different medias. */
      ${props => media(props.columns, props.columnSpacing)}
    }
  }
`;

HorizontalList.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  columnSpacing: PropTypes.string,
  rowSpacing: PropTypes.string,
};

HorizontalList.defaultProps = {
  columns: [1],
  columnSpacing: '32px',
  rowSpacing: '24px',
};

export { HorizontalList };
export default HorizontalList;
