import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex, Text } from '../Base';
import Dropdown from '../Dropdown';

const FilterSelect = styled(Flex)`
  margin-top: 8px;

  &:first-child {
    margin-top: 0px;
  }
`;

const HorizontalSelect = ({ title, selectedValue, ListOptionValue, onChange }) => {
  return (
    <FilterSelect alignItems={'center'}>
      <Flex flex={'0 0 160px'}>
        <Text>{title}</Text>
      </Flex>
      <Dropdown
        defaultValue={selectedValue || ''}
        title={title}
        list={ListOptionValue}
        onChange={onChange}
      />
    </FilterSelect>
  );
};

HorizontalSelect.propTypes = {
  title: PropTypes.string,
  selectedValue: PropTypes.string,
  ListOptionValue: PropTypes.array,
};

export default HorizontalSelect;
