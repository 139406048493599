import { useQuery } from '@apollo/react-hooks';

import { getCourseUnitReport } from 'GraphQL/queries/report.query';

const CourseUnitReportData = ({ value, schoolCode, children }) => {
  const { term, grade, classNo, subject, syllabus } = value;

  const { loading, error, data, refetch } = useQuery(getCourseUnitReport, {
    variables: {
      schoolTermId: term,
      schoolGradeId: grade !== `all-grade-selected` ? grade : null,
      classNo: classNo !== `all-class-selected` ? classNo : null,
      subjectId: subject,
      courseCode: syllabus,
      schoolCode,
    },
    skip: !term || !grade || !classNo || !subject || !syllabus || !schoolCode,
  });

  return children({ loading, error, data, refetch });
};

export default CourseUnitReportData;
