import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Text } from 'Components/Base';
import Dropdown from 'Components/Dropdown';
import SearchInput from 'Components/SearchInput';
import ProfileList from 'Components/ProfileList';
import ToolbarContainer from 'Components/ToolbarContainer';
import TeacherStudentsData from 'Domains/TeacherStudentsData';
import TeacherGradeData from 'Domains/TeacherGradeData';

const FilterRow = ({ options, defaultValue, onSelect, onSearch }) => {
  const { t } = useTranslation('module');
  useEffect(() => {
    onSelect(defaultValue);
  }, []);
  return (
    <Flex flexDirection={{ _: 'column', md: 'row' }}>
      <Flex flex="1" pr={{ md: 3 }}>
        <Dropdown
          defaultValue={defaultValue}
          title={t('GRADE')}
          list={options}
          onChange={onSelect}
        />
      </Flex>
      <Flex flex="3" pt={{ _: 2, md: 0 }}>
        <SearchInput placeholder={t('SEARCH')} onChange={onSearch} />
      </Flex>
    </Flex>
  );
};

FilterRow.propTypes = {
  options: PropTypes.array,
  defaultValue: PropTypes.any,
  onSelect: PropTypes.func,
  onSearch: PropTypes.func,
};

FilterRow.defaultProps = {
  options: [],
  defaultValue: null,
  onSelect: () => {},
  onSearch: () => {},
};

const Content = ({ gradeName, profiles, total }) => {
  const { t } = useTranslation('module');
  return (
    <Box>
      <Flex justifyContent="space-between">
        <Text>{gradeName}</Text>
        <Text>{`${profiles.length} ${t('FROM_LENGTH')} ${total}`}</Text>
      </Flex>
      <Flex mt={4}>
        <ProfileList
          dataList={profiles}
          profileProps={{
            titleColor: 'greyscale.grey1',
            backgroundColor: 'transparent',
            style: {
              cursor: 'pointer',
            },
          }}
        />
      </Flex>
    </Box>
  );
};

Content.propTypes = {
  profiles: PropTypes.array,
  gradeName: PropTypes.string,
  total: PropTypes.number,
};

Content.defaultProps = {
  profiles: [],
  gradeName: '',
  total: 0,
};

const TeacherStudents = ({ userId, path }) => {
  const [gradeId, setGradeId] = useState('');
  const [searchText, setSearchText] = useState('');
  const history = useHistory();

  return (
    <TeacherGradeData userId={userId}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return null;
        const { foStudentGrades: grades } = data;
        const gradeOptions = grades.map(({ id, name }) => ({
          key: id,
          value: id,
          text: name,
        }));
        return (
          <ToolbarContainer
            topToolbar={
              <FilterRow
                options={gradeOptions}
                defaultValue={gradeOptions[0].value}
                onSelect={grade => setGradeId(grade)}
                onSearch={text => setSearchText(text)}
              />
            }
            topToolbarProps={{ px: { _: 3, md: 4, lg: 8 } }}
            content={
              <TeacherStudentsData userId={userId} gradeId={gradeId} searchText={searchText}>
                {({ loading, error, data }) => {
                  if (loading) return null;
                  if (error) return null;
                  const {
                    foStudentProfiles: { profiles: students, total },
                  } = data;
                  const gradeName = gradeId ? _.find(grades, { id: gradeId }).name : '';
                  const profiles = students.map(
                    ({ id, nickname, title, firstname, lastname, avatar, code }) => ({
                      imageSrc: avatar,
                      name: `${nickname}, ${code}`,
                      description: `${title}${firstname} ${lastname}`,
                      onClick: () => history.push(`/teacher/${path}/${id}`),
                    }),
                  );
                  return <Content gradeName={gradeName} total={total} profiles={profiles} />;
                }}
              </TeacherStudentsData>
            }
            contentProps={{ px: { _: 3, md: 4, lg: 8 } }}
          />
        );
      }}
    </TeacherGradeData>
  );
};

TeacherStudents.propTypes = {
  userId: PropTypes.string,
  path: PropTypes.string,
};

TeacherStudents.defaultProps = {
  userId: '',
  path: '',
};

export default TeacherStudents;
