import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { compose, branch, renderComponent } from 'recompose';
import { useTranslation } from 'react-i18next';

import { COLOR } from 'Components/Theme';
import { withAuth, AUTH_STATE } from 'Libs/Auth';
import {
  Text,
  Button,
  Box,
  Image,
  Flex,
  Icon,
  ICON_LIST,
  Form as BaseForm,
  Link as BaseLink,
} from 'Components/Base';

const { FormContainer, Form, TextField, FormValidationRegEx } = BaseForm;

const FormControl = styled(Form)`
  width: 100%;

  & .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    color: ${({ theme }) => theme.colors.primary};
    margin-left: 22px;
  }

  & input {
    border-radius: 100px;
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.primary};

    &:placeholder {
      color: ${({ theme }) => theme.colors.primary};
    }

    &.MuiOutlinedInput-input:-webkit-autofill {
      border-radius: 100px;
    }
  }
`;

const Link = styled(BaseLink)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:active {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const ChangePasswordView = props => {
  const { t } = useTranslation('login');
  const [apiError, setApiError] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);

  const handleSubmit = (values, actions) => {
    const { oldPassword, newPassword } = values;
    actions.setSubmitting(false);
    props.auth
      .changePassword(oldPassword, newPassword)
      .then(() => {
        setIsCompleted(true);
      })
      .catch(error => {
        setApiError(error.response.data.error);
      });
  };

  const handleInputValidation = values => {
    const { oldPassword, newPassword, confirmNewPassword } = values;
    const errors = {};
    if (oldPassword.length === 0) {
      errors.oldPassword = t('PASSWORD_REQUIRED');
    }
    if (newPassword.length === 0) {
      errors.newPassword = t('PASSWORD_REQUIRED');
    }
    if (newPassword.length < 6) {
      errors.newPassword = t('PASSWORD_LENGTH_REQUIRED');
    }
    if (confirmNewPassword.length === 0) {
      errors.confirmNewPassword = t('VERIFY_PASSWORD_REQUIRED');
    }
    if (oldPassword === newPassword) {
      errors.newPassword = t('NEW_PASSWORD_REQUIRED');
    }
    if (newPassword !== confirmNewPassword) {
      errors.confirmNewPassword = t('VERIFY_PASSWORD_REQUIRED');
    }
    return errors;
  };

  const getFailMessage = error => {
    const { name, statusCode, message } = error;
    if (statusCode === 401) {
      if (name === 'UnauthorizedError') {
        if (message === 'Email has not been verified') {
          return t('ERROR_NOT_VERIFY');
        } else if (message === 'User not found') {
          return t('ERROR_USER_NOT_FOUND');
        }
      }
      return t('ERROR_INCORRECT_AUTHENTICATION');
    } else if (statusCode >= 500) {
      return t('ERROR_SERVER_ERROR');
    }
    return t('ERROR_DEFAULT');
  };

  if (isCompleted) {
    return (
      <Flex height={'100%'} justifyContent="center" alignItems="center">
        <Box alignSelf={'center'} width={{ _: 1, sm: 1, md: 1 / 2 }} bg="black">
          <Flex p={{ _: 3, md: 5, lg: 8 }} flexDirection={'column'}>
            <Box alignSelf={'center'} mb={4}>
              <Icon name={ICON_LIST.CHECK} size={33} color={COLOR.primary} />
            </Box>

            <Box alignSelf={'center'} mb={6}>
              <Text.SubTitle color="white">{t('SUCCESSFULLY_CHANGE_PASSWORD')}</Text.SubTitle>
            </Box>
            <Button variant="primary" width={'100%'} onClick={props.handleCancel}>
              <Text>{t('CONFIRM')}</Text>
            </Button>
          </Flex>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex height={'100%'} justifyContent="center" alignItems="center">
      <Box alignSelf={'center'} width={{ _: 1, sm: 1, md: 1 / 2 }} bg="black">
        <Flex p={8} flexDirection={'column'}>
          <Box alignSelf={'center'} mb={8}>
            <Text.SubTitle color="white">{t('CHANGE_PASSWORD_TITLE')}</Text.SubTitle>
          </Box>
          <FormContainer
            initialValues={{ oldPassword: '', newPassword: '', confirmNewPassword: '' }}
            isInitialValid={false}
            validate={handleInputValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, handleChange, handleBlur, isValid }) => (
              <FormControl>
                <Flex flexDirection={'column'} width={1}>
                  {apiError && (
                    <Box pb={2}>
                      <Text color="danger" textAlign="center">
                        <Icon name={Icon.ICON_LIST.WARNING} /> {getFailMessage(apiError)}
                      </Text>
                    </Box>
                  )}
                  <TextField.Input
                    name="oldPassword"
                    type="password"
                    maxLength={100}
                    label={t('OLD_PASSWORD')}
                    placeholder={t('PASSWORD_PLACEHOLDER')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.oldPassword}
                  />
                  <Box p={4} />
                  <TextField.Input
                    name="newPassword"
                    type="password"
                    maxLength={100}
                    label={t('NEW_PASSWORD')}
                    placeholder={t('PASSWORD_PLACEHOLDER')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                  />
                  <Box p={4} />
                  <TextField.Input
                    name="confirmNewPassword"
                    type="password"
                    maxLength={100}
                    label={t('VERIFY_PASSWORD')}
                    placeholder={t('VERIFY_PASSWORD_PLACEHOLDER')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmNewPassword}
                  />
                  <Box p={7} />
                  <Flex justifyContent="space-between">
                    <Button color="white" width={'100%'} mr={1} onClick={props.handleCancel}>
                      <Text color="black">{t('CANCEL')}</Text>
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={!isValid}
                      width={'100%'}
                      ml={1}
                    >
                      {t('CONFIRM')}
                    </Button>
                  </Flex>
                </Flex>
              </FormControl>
            )}
          </FormContainer>
        </Flex>
      </Box>
    </Flex>
  );
};

export default withAuth(ChangePasswordView);
