import React from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { withAnalytic } from '@skooldio/analytics-provider/dist/Analytics/hoc';

import { withAuth } from 'Libs/Auth';
import { withConfig } from 'Libs/Config';
import { Box, Text, Flex, ICON_LIST, Icon as BaseIcon } from 'Components/Base';
import Profile from 'Components/Profile';
import LESchoolData from 'Domains/LESchoolData';
import { useAnalytics } from 'Libs/Analytics';

const Icon = styled(BaseIcon)`
  text-decoration: none;

  width: 25px;
  & > path {
    fill: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.navbarIcon)};
  }

  color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.navbarIcon)};

  /* hover icon */
  &:hover {
    & > path {
      fill: ${({ theme }) => theme.colors.white};
    }
    & > * {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const AccountInfoView = ({ auth, onLogAction, schoolCode }) => {
  const { send } = useAnalytics();
  const { t } = useTranslation('dashboard');

  const {
    myProfile: { firstname, lastname },
    logout,
  } = auth;
  const name = `${t('NAME_TITLE')}${firstname} ${lastname}`;

  return (
    <Box style={{ cursor: 'default' }}>
      <LESchoolData schoolCode={schoolCode}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return null;
          const foSchoolInfo = data?.foSchoolInfo ?? {};
          const { code, name } = foSchoolInfo;
          return <Text.Title color={'white'}>{name}</Text.Title>;
        }}
      </LESchoolData>
      <Flex>
        <Profile name={name} />
        <Icon
          name={ICON_LIST.LOGOUT}
          style={{ height: 'auto', cursor: 'pointer', marginLeft: '12px' }}
          onClick={() => {
            onLogAction({
              category: 'Logout',
              action: `Click`,
              label: `Logout`,
            });
            send('logout');
            logout();
          }}
        />
      </Flex>
    </Box>
  );
};

export default compose(withAuth, withConfig, withAnalytic)(AccountInfoView);
