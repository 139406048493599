import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';
import styled, { ThemeContext } from 'styled-components';

import { Box, Flex, Text, Icon, ICON_LIST } from 'Components/Base';

/*
Use styled-component instead of makeStyles for Wrapper
because .MuiInputBase-root cannot be access by Select Component
*/
const Wrapper = styled(Box)`
  .MuiInputBase-root {
    width: 100%;
    border-radius: 20px;

    .MuiSelect-root:focus {
      background-color: initial;
    }
  }
`;

const useStyles = makeStyles({
  root: ({ theme, width }) => ({
    backgroundColor: 'transparent',
    width: width,
    height: '40px',
    fontSize: '15px',
    fontFamily: theme.fonts.sans,
    color: theme.colors.primary,

    '&:hover': {
      backgroundColor: theme.colors.secondary,
    },
    '&:focus': {
      backgroundColor: 'none',
    },
    '& div': {
      color: theme.colors.primary,
      textAlign: 'center',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.secondary,
    },
    '& .MuiOutlinedInput-notchedOutline:hover': {
      borderColor: theme.colors.secondary,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.primary,
    },
  }),
});

const Dropdown = props => {
  const { TitleComponent, title, list, onChange, width, defaultValue, ...restProps } = props;
  const [value, setValue] = useState(defaultValue ?? title);
  const theme = useContext(ThemeContext);
  const classes = useStyles({ theme, width });

  const handleChange = e => {
    setValue(e.target.value);
    onChange(e.target.value, setValue);
  };

  useEffect(() => {
    setValue(defaultValue ?? title);
  }, [defaultValue]);

  return (
    <Wrapper width={1}>
      <Select
        className={classes.root}
        value={value}
        width={width}
        variant="outlined"
        onChange={handleChange}
        IconComponent={props => (
          <Flex alignItems={'center'} mr={3} {...props}>
            <Text fontSize={5}>
              <Icon name={ICON_LIST.MENU_EXPAND_MORE} color={theme.colors.primary} />
            </Text>
          </Flex>
        )}
        {...restProps}
      >
        <MenuItem key="" value={title} disabled>
          <Text>{title}</Text>
        </MenuItem>
        {list.map(element => (
          <MenuItem key={element.key} value={element.value} disabled={element.disabled}>
            <Text>{element.text}</Text>
          </MenuItem>
        ))}
      </Select>
    </Wrapper>
  );
};

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      text: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  TitleComponent: PropTypes.element,
};

Dropdown.defaultProps = {
  onChange: () => {},
  width: 300,
};

export default Dropdown;
