import React from 'react';
import PropTypes from 'prop-types';

import { BorderBox, Box, Flex } from 'Components/Base';

const Layout = ({
  left,
  leftProps,
  top,
  topProps,
  main,
  mainProps,
  bottom,
  bottomProps,
  right,
  rightProps,
  ...props
}) => {
  return (
    <Flex width={1} {...props}>
      {left && (
        <BorderBox flex={'0 0 auto'} {...leftProps}>
          {left}
        </BorderBox>
      )}
      <Flex flex={'1 1 auto'} flexDirection={'column'}>
        {top && <BorderBox {...topProps}>{top}</BorderBox>}
        {main && <BorderBox {...mainProps}>{main}</BorderBox>}
        {bottom && <BorderBox {...bottomProps}>{bottom}</BorderBox>}
      </Flex>
      {right && (
        <BorderBox flex={'0 0 auto'} {...rightProps}>
          {right}
        </BorderBox>
      )}
    </Flex>
  );
};

Layout.propTypes = {
  left: PropTypes.element,
  leftProps: PropTypes.object,
  top: PropTypes.element,
  topProps: PropTypes.object,
  main: PropTypes.element,
  mainProps: PropTypes.object,
  bottom: PropTypes.element,
  bottomProps: PropTypes.object,
  right: PropTypes.element,
  rightProps: PropTypes.object,
};

export default Layout;
