import { handleActions } from 'redux-actions';
import { showAuth, hideAuth } from './actions';

const defaultState = { authVisibility: true };

const menu = handleActions(
  {
    [showAuth]: state => {
      return { ...state, authVisibility: true };
    },
    [hideAuth]: state => {
      return { ...state, authVisibility: false };
    },
  },
  defaultState
);

export default menu;
