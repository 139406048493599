import { useQuery } from '@apollo/react-hooks';

import { getStudentProfilesByTeacherId } from 'GraphQL/queries/modules.query';

const TeacherStudentsData = props => {
  const { userId, gradeId, searchText, children } = props;
  const { loading, error, data } = useQuery(getStudentProfilesByTeacherId, {
    variables: {
      search: {
        advisorTeacherId: userId,
        schoolGradeId: gradeId,
        searchText,
      },
    },
  });
  return children({ loading, error, data });
};

export default TeacherStudentsData;
