import React, { useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Text, Icon, ICON_LIST } from 'Components/Base';
import { COLOR } from 'Components/Theme';
import Section from 'Components/Section';
import SchoolTermData from 'Domains/SchoolTermData';
import PracQuizTableData from 'Domains/PracQuizTableData';
import getNextMonday from 'Domains/getNextMonday';

import PracQuizFilter from './PracQuizFilter';
import PracQuizTable from './PracQuizTable';

const ClickableFlex = styled(Flex)`
  :hover {
    cursor: pointer;
  }
`;

const StudentPracQuiz = ({ userId, onBack }) => {
  const { t } = useTranslation('dashboard');
  const [yearMonth, setYearMonth] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [dateFilter, setDateFilter] = useState(new Date());

  const year = yearMonth ? Number(yearMonth.split('-')[0]) : '';
  const month = yearMonth ? Number(yearMonth.split('-')[1]) : '';

  return (
    <Section
      title={
        <ClickableFlex alignItems="center" onClick={onBack}>
          <Icon name={ICON_LIST.ARROW_LEFT} size={20} color={COLOR.primary} />
          <Text>{t('BACK')}</Text>
        </ClickableFlex>
      }
      detail={
        <Box>
          <SchoolTermData>
            {({ loading, error, data }) => {
              if (loading) return null;
              if (error) return null;
              const {
                foCurrentSchoolTerm: { yearMonths },
              } = data;
              const monthOptions = yearMonths.map(({ year, month }) => ({
                key: `${year}-${month}`,
                value: `${year}-${month}`,
                text: t('MONTH_YEAR', { date: new Date(year, month, 1) }),
              }));
              const selectedYearMonth =
                year && month
                  ? _.find(yearMonths, ({ year: y, month: m }) => year === y && month === m)
                  : yearMonths[0];
              const mondayOptions = selectedYearMonth.weeks.map(({ date, number }) => ({
                key: date,
                value: date,
                text: t('WEEK', { number }),
              }));
              return (
                <PracQuizFilter
                  yearMonth={yearMonth}
                  monthOptions={monthOptions}
                  setYearMonth={setYearMonth}
                  selectedDate={selectedDate}
                  mondayOptions={mondayOptions}
                  setSelectedDate={setSelectedDate}
                  onSearchText={text => setSearchText(text)}
                  onDateFilter={date => setDateFilter(date)}
                />
              );
            }}
          </SchoolTermData>
          <PracQuizTableData
            userId={userId}
            startDate={dateFilter}
            endDate={getNextMonday(dateFilter)}
            searchText={searchText}
          >
            {({ loading, error, data }) => {
              if (loading) return null;
              if (error) return null;
              return <PracQuizTable data={data.foStudentPracQuizDetails} />;
            }}
          </PracQuizTableData>
        </Box>
      }
    />
  );
};

export default StudentPracQuiz;
