import React from 'react';
import { Text } from 'Components/Base';

const LoadingView = ({ dimmer, loadingText, loaderProps }) => <Text>{loadingText}</Text>;

LoadingView.defaultProps = {
  dimmer: true,
  loadingText: 'กำลังโหลด...',
  loaderProps: {},
};

export default LoadingView;
