import { useApolloClient } from '@apollo/react-hooks';
import XLSX from 'xlsx';
import _ from 'lodash';

import { formatFullSchoolTerm, formatSchoolTerm } from '../utils';
import { MODULE_STATUS, RAW_CONTENT_TYPE } from '../module';
import {
  getSectionReportForExport,
  getSectionReportAllExamForExport,
} from '../../GraphQL/queries/report.query.js';

export const convertStudentReportToAOA = grouppedReports => {
  const quizFullScores = [];

  const studentQuizResult = [
    ...Object.values(grouppedReports).map(grouppedReport => {
      const {
        schoolGrade: { shortName: schoolGrade },
        classNo,
        studentNo,
        classroomUsername,
        firstName,
        lastName,
      } = grouppedReport[0];

      return [
        `${schoolGrade}/${classNo}`,
        studentNo,
        classroomUsername,
        `${firstName} ${lastName}`,
        ..._.flatMap(grouppedReport, report => {
          const {
            courseName,
            sectionName,
            subsectionName,
            quizResult: { fullScore, score, rank },
          } = report;

          if (rank !== 'UNRANK') {
            quizFullScores.push({
              id: `${courseName},${sectionName},${subsectionName}`,
              courseName,
              sectionName,
              subsectionName,
              fullScore,
            });
          }
          // คะแนนเฉลี่ย, คะแนนดีที่สุด, คะแนนทั้งหมด
          return [score, score, score];
        }),
      ];
    }),
  ];
  const uniqQuizFullScores = _.uniqBy(quizFullScores, 'id');
  return { studentQuizResult, uniqQuizFullScores };
};

export const convertOverAllReportToAOA = (reports, isAllExam) => {
  const overAllReport = [];

  reports.forEach(report => {
    const {
      schoolGrade,
      classNo,
      studentNo,
      classroomUsername,
      firstName,
      lastName,
      subsections,
    } = report;

    const rowDetail = [
      `${schoolGrade.shortName}/${classNo}`,
      studentNo,
      classroomUsername,
      `${firstName} ${lastName}`,
    ];

    const filteredSubsections = subsections.filter(
      ({ contentType }) => contentType === RAW_CONTENT_TYPE.QUIZ,
    );
    if (isAllExam) {
      const MAX_QUIZ_TIMES = 5;

      filteredSubsections.forEach(({ quizResultList }) => {
        let sumScore = 0;
        let bestScore = 0;

        quizResultList.forEach(({ score }) => {
          sumScore += score;
          bestScore = _.max([bestScore, score]);
        });
        rowDetail.push(quizResultList[0].fullScore, sumScore / quizResultList.length, bestScore);
        for (let i = 0; i < MAX_QUIZ_TIMES; i++) {
          if (i < quizResultList.length) {
            rowDetail.push(quizResultList[i].score);
          } else {
            rowDetail.push('');
          }
        }
      });
    } else {
      filteredSubsections.forEach(({ score }) => {
        rowDetail.push(score, score, score);
      });
    }

    overAllReport.push(rowDetail);
  });

  return overAllReport;
};

export const convertProgressReportToAOA = (reports, reportContentType) => {
  const aoa = [];

  reports.forEach(report => {
    const {
      schoolGrade,
      classNo,
      studentNo,
      classroomUsername,
      firstName,
      lastName,
      subsections,
    } = report;

    const rowDetail = [
      `${schoolGrade.shortName}/${classNo}`,
      studentNo,
      classroomUsername,
      `${firstName} ${lastName}`,
    ];

    const filteredSubsections = subsections.filter(
      ({ contentType }) => contentType === reportContentType,
    );

    const totalSubsections = filteredSubsections.length;

    const totalCompleted = filteredSubsections.filter(
      ({ status }) => status === MODULE_STATUS.COMPLETED,
    ).length;

    const progressPercentage =
      totalSubsections === 0 ? 0 : (totalCompleted / totalSubsections) * 100;

    rowDetail.push(progressPercentage.toFixed(0));

    filteredSubsections.forEach(({ contentType, status }) => {
      let statusTh = '';
      if (contentType === RAW_CONTENT_TYPE.VDO) {
        statusTh = status === MODULE_STATUS.COMPLETED ? 'เรียนจบแล้ว' : 'ยังเรียนไม่จบ';
      } else {
        statusTh = status === MODULE_STATUS.COMPLETED ? 'สอบแล้ว' : 'ยังไม่สอบ';
      }
      rowDetail.push(statusTh);
    });

    aoa.push(rowDetail);
  });

  return aoa;
};

const formatQuizOverAllToAOA = (data, isAllExam) => {
  const { sectionInfos, reports } = data;

  const filteredSectionInfos = sectionInfos
    .map(sectionInfo => ({
      sectionCode: sectionInfo.sectionCode,
      sectionName: sectionInfo.sectionName,
      subsectionInfos: sectionInfo.subsectionInfos.filter(
        ({ contentType }) => contentType === RAW_CONTENT_TYPE.QUIZ,
      ),
    }))
    .filter(sectionInfo => sectionInfo.subsectionInfos.length > 0);

  const formattedReportAOA = [
    ['', '', '', ''],
    ['', '', '', ''],
    ['', '', '', ''],
    ['ห้อง', 'เลขที่', 'รหัสผู้ใช้', 'ชื่อ-นามสกุล'],
  ];

  formattedReportAOA[0].push(filteredSectionInfos[0]?.subsectionInfos[0]?.courseName);

  filteredSectionInfos.forEach(({ sectionName, subsectionInfos }) => {
    subsectionInfos.forEach(({ subsectionName }, index) => {
      if (index === 0) {
        if (isAllExam) {
          formattedReportAOA[1].push(sectionName, '', '', '', '', '', '', '');
        } else {
          formattedReportAOA[1].push(sectionName, '', '');
        }
      } else if (isAllExam) {
        formattedReportAOA[1].push('', '', '', '', '', '', '', '');
      } else {
        formattedReportAOA[1].push('', '', '');
      }
      if (isAllExam) {
        formattedReportAOA[2].push(subsectionName, '', '', '', '', '', '', '');
        formattedReportAOA[3].push(
          'คะแนนเต็ม',
          'คะแนนเฉลี่ย',
          'คะแนนดีที่สุด',
          'คะแนนครั้งที่ 1',
          'คะแนนครั้งที่ 2',
          'คะแนนครั้งที่ 3',
          'คะแนนครั้งที่ 4',
          'คะแนนครั้งที่ 5',
        );
      } else {
        formattedReportAOA[2].push(subsectionName, '', '');
        formattedReportAOA[3].push('คะแนนเฉลี่ย', 'คะแนนดีที่สุด', 'คะแนนทั้งหมด');
      }
    });
  });

  const overAllReport = convertOverAllReportToAOA(reports, isAllExam);

  formattedReportAOA.push(...overAllReport);

  return { overAllAOA: formattedReportAOA, overAllHeader: sectionInfos };
};

const formatProgressToAOA = (data, progressType) => {
  const { sectionInfos, reports } = data;

  const filteredSectionInfos = sectionInfos
    .map(sectionInfo => ({
      sectionCode: sectionInfo.sectionCode,
      sectionName: sectionInfo.sectionName,
      subsectionInfos: sectionInfo.subsectionInfos.filter(
        ({ contentType }) => contentType === progressType,
      ),
    }))
    .filter(sectionInfo => sectionInfo.subsectionInfos.length > 0);

  const progressLabel =
    progressType === RAW_CONTENT_TYPE.VDO ? 'ความคืบหน้าเฉลี่ย' : 'การเข้าสอบเฉลี่ย';

  const formattedReportAOA = [
    ['', '', '', '', ''],
    ['', '', '', '', ''],
    ['ห้อง', 'เลขที่', 'รหัสผู้ใช้', 'ชื่อ-นามสกุล', `${progressLabel} (เปอร์เซ็นต์)`],
  ];

  formattedReportAOA[0].push(filteredSectionInfos[0]?.subsectionInfos[0].courseName);

  filteredSectionInfos.forEach(({ sectionName, subsectionInfos }) => {
    subsectionInfos.forEach(({ subsectionName }, index) => {
      if (index === 0) {
        formattedReportAOA[1].push(sectionName);
      } else {
        formattedReportAOA[1].push('');
      }

      formattedReportAOA[2].push(subsectionName);
    });
  });

  const aoa = convertProgressReportToAOA(reports, progressType);

  formattedReportAOA.push(...aoa);

  return { formattedReportAOA, sectionInfos: filteredSectionInfos };
};

const createOverAllsheet = (overAllAOA, overAllHeader, isAllExam) => {
  let sectionStart = 4;
  let sectionEnd = 0;
  let subsectionStart = 4;
  let subsectionEnd = 0;
  let colWidth = isAllExam ? 8 : 3;
  const defaultCols = isAllExam
    ? [{ wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }]
    : [{ wch: 15 }, { wch: 15 }, { wch: 15 }];

  const worksheet = XLSX.utils.aoa_to_sheet(overAllAOA);

  worksheet['!cols'] = [
    { wch: 10 }, //ห้อง
    { wch: 10 }, //เลขที่
    { wch: 15 }, //รหัสผู้ใช้
    { wch: 25 }, //ชื่อ-นามสกุล
  ];

  worksheet['!merges'] = [];

  overAllHeader.forEach(({ subsectionInfos }) => {
    subsectionInfos.forEach(() => {
      subsectionEnd = subsectionStart + (isAllExam ? 7 : 2);
      worksheet['!merges'].push({ s: { r: 2, c: subsectionStart }, e: { r: 2, c: subsectionEnd } });
      subsectionStart = subsectionEnd + 1;
      worksheet['!cols'].push(...defaultCols);
    });
    sectionEnd = sectionStart + subsectionInfos.length * colWidth - 1;
    worksheet['!merges'].push({ s: { r: 1, c: sectionStart }, e: { r: 1, c: sectionEnd } });
    sectionStart = sectionEnd + 1;
  });

  worksheet['!merges'].push({ s: { r: 0, c: 4 }, e: { r: 0, c: subsectionEnd } });

  return worksheet;
};

const createProgressSheet = (aoa, header) => {
  let sectionStart = 5;
  let sectionEnd = 0;

  const worksheet = XLSX.utils.aoa_to_sheet(aoa);

  worksheet['!cols'] = [
    { wch: 10 }, //ห้อง
    { wch: 10 }, //เลขที่
    { wch: 15 }, //รหัสผู้ใช้
    { wch: 25 }, //ชื่อ-นามสกุล
  ];

  worksheet['!merges'] = [];

  header.forEach(({ subsectionInfos }) => {
    subsectionInfos.forEach(() => {
      worksheet['!cols'].push({ wch: 15 });
    });
    sectionEnd = sectionStart + subsectionInfos.length - 1;
    worksheet['!merges'].push({ s: { r: 1, c: sectionStart }, e: { r: 1, c: sectionEnd } });
    sectionStart = sectionEnd + 1;
  });

  worksheet['!merges'].push({ s: { r: 0, c: 5 }, e: { r: 0, c: sectionEnd } });

  return worksheet;
};

const ExportSectionReportData = props => {
  const {
    schoolTermId,
    schoolGradeId,
    classNo,
    subjectId,
    courseCode,
    schoolCode,
    sectionCode,
    children,
  } = props;
  const client = useApolloClient();

  const fetchSectionReportData = async () => {
    try {
      const { data } = await client.query({
        query: getSectionReportForExport,
        variables: {
          schoolTermId,
          schoolGradeId,
          classNo,
          subjectId,
          schoolCode,
          courseCode,
        },
      });
      return { data };
    } catch (error) {
      return { error };
    }
  };

  const fetchSectionReportAllExamExportData = async () => {
    try {
      const { data } = await client.query({
        query: getSectionReportAllExamForExport,
        variables: {
          schoolTermId,
          schoolGradeId,
          classNo,
          subjectId,
          schoolCode,
          courseCode,
          sectionCode,
        },
      });
      return { data };
    } catch (error) {
      return { error };
    }
  };

  const exportSectionReport = async ({ data, type, isAllExam = false }) => {
    const { reports, subject: subjectObj = {}, schoolTerm: { shortName: schoolTerm } = {} } = data;

    const { name: subject } = subjectObj;

    const headerClassNo = classNo ?? `ทุกห้อง`;

    const { schoolGrade: { shortName: schoolGrade } = {} } = reports[0];

    const headerSchoolGrade = schoolGradeId ? schoolGrade : `ทุกระดับชั้น`;

    const { overAllAOA, overAllHeader } = formatQuizOverAllToAOA(data, isAllExam);
    const overAllSheet = createOverAllsheet(overAllAOA, overAllHeader, isAllExam);

    const {
      formattedReportAOA: videoProgressAOA,
      sectionInfos: videoProgressHeader,
    } = formatProgressToAOA(data, RAW_CONTENT_TYPE.VDO);

    const videoProgressSheet = createProgressSheet(videoProgressAOA, videoProgressHeader);

    const {
      formattedReportAOA: quizProgressAOA,
      sectionInfos: quizProgressHeader,
    } = formatProgressToAOA(data, RAW_CONTENT_TYPE.QUIZ);

    const quizProgressSheet = createProgressSheet(quizProgressAOA, quizProgressHeader);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, overAllSheet, 'ภาพรวมคะแนน');
    XLSX.utils.book_append_sheet(workbook, videoProgressSheet, 'ภาพรวมการเข้าเรียน');
    XLSX.utils.book_append_sheet(workbook, quizProgressSheet, 'ภาพรวมการเข้าสอบ');

    const filename = isAllExam
      ? `ข้อมูลรายบท_${formatSchoolTerm(
          schoolTerm,
        )}_${headerSchoolGrade}-${headerClassNo}_${subject}_full`
      : `ข้อมูลรายบท_${formatSchoolTerm(
          schoolTerm,
        )}_${headerSchoolGrade}-${headerClassNo}_${subject}`;
    XLSX.writeFile(workbook, `${filename}.${type || 'xlsx'}`);
  };

  return children({
    fetchSectionReportData,
    fetchSectionReportAllExamExportData,
    exportSectionReport,
  });
};

export default ExportSectionReportData;
