import { useQuery } from '@apollo/react-hooks';

import { getStudentGradesByTeacherId } from 'GraphQL/queries/modules.query';

const TeacherGradeData = props => {
  const { userId, children } = props;
  const { loading, error, data } = useQuery(getStudentGradesByTeacherId, {
    variables: {
      advisorTeacherId: userId,
    },
  });
  return children({ loading, error, data });
};

export default TeacherGradeData;
