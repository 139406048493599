import React, { memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Flex } from 'Components/Base';
import Breadcrumb from 'Components/Breadcrumb';
import BreadcrumbLayout from 'Components/BreadcrumbLayout';
import LSPUserData from 'Domains/LSPUserData';
import StudentModuleProgress from 'Containers/StudentModuleProgress';
import StudentPracQuizProgress from 'Containers/StudentPracQuizProgress';
import StudentRIASEC from 'Containers/StudentRIASEC';
import StudentProfileBox from 'Containers/StudentProfileBox';
import TabContent from 'Components/TabContent';

const BreadcrumbComponent = memo(function BreadcrumbComponent({ userId, userCode }) {
  const items = [
    {
      key: 'dashboard',
      title: 'Dashboard',
      path: '/teacher/dashboard',
    },
    {
      key: userId,
      title: userCode,
      path: `/teacher/dashboard/${userId}`,
    },
  ];
  return <Breadcrumb items={items} variant={'dark'} />;
});

const TeacherStudentDashboardPage = props => {
  const history = useHistory();
  const { studentId: userId } = useParams();

  return (
    <LSPUserData userId={userId}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return null;
        const {
          foProfile: { code },
        } = data;
        return (
          <BreadcrumbLayout
            breadcrumbComponent={() => <BreadcrumbComponent userId={userId} userCode={code} />}
          >
            <TabContent
              content={
                <Flex flexDirection={'column'}>
                  <StudentModuleProgress userId={userId} />
                  <StudentPracQuizProgress
                    userId={userId}
                    onViewAll={() => history.push(`/teacher/dashboard/${userId}/pracquiz`)}
                  />
                  <StudentRIASEC userId={userId} />
                </Flex>
              }
              sidebar={<StudentProfileBox userId={userId} />}
            />
          </BreadcrumbLayout>
        );
      }}
    </LSPUserData>
  );
};

export default memo(TeacherStudentDashboardPage);
