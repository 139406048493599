import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose, mapProps } from 'recompose';
import { withAnalytic } from '@skooldio/analytics-provider/dist/Analytics/hoc';
import styled from 'styled-components';

import { Text, Box, Flex } from 'Components/Base';
import { getSchoolCode } from 'Domains/LESchoolData/Routing';
import CourseGrade from 'Containers/CourseGrade';
import CourseGradeExporter from 'Containers/CourseGradeExporter';

const CourseGradePageWrapper = styled(Box)`
  border-radius: 10px;
  flex: 1 0;
  display: grid;
  grid: auto auto 1fr / 100%;
  row-gap: 16px;
  overflow: hidden;
  max-height: calc(100vh - 96px);
`;

const CourseGradePage = ({ schoolCode, onLogAction, auth: { myProfile }, ...props }) => {
  const { t } = useTranslation('courseGrade');
  const [isFetchData, setIsFetchData] = useState(false);
  const [disabledFetch, setDisabledFetch] = useState(true);
  const [appliedFilter, setAppliedFilter] = useState({});

  const handleFilterApplied = filters => setAppliedFilter(filters);

  return (
    <CourseGradePageWrapper px={[0, null, 2, 7]} py={[0, null, 3, 5]} bg={'white'}>
      <Box>
        <Flex alignItems={'center'} justifyContent={'space-between'}>
          <Text.Title>{t('TITLE')}</Text.Title>
          <Flex>
            <CourseGradeExporter
              disabledCourseGrade={disabledFetch}
              disabledCourseSection={disabledFetch || !appliedFilter.syllabus}
              appliedFilter={appliedFilter}
              schoolCode={schoolCode}
              myProfileId={myProfile.id}
            />
          </Flex>
        </Flex>
      </Box>
      <CourseGrade
        isFetchData={isFetchData}
        disabledFetch={disabledFetch}
        setIsFetchData={setIsFetchData}
        setDisabledFetch={setDisabledFetch}
        onFilterApplied={handleFilterApplied}
        schoolCode={schoolCode}
        myProfileId={myProfile.id}
      />
    </CourseGradePageWrapper>
  );
};

export default compose(mapProps(getSchoolCode), withAnalytic)(CourseGradePage);
