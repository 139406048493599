export const COURSE_GRADE_TYPE = {
  ATTENDANCE_RATE: 'attendanceRate',
  EXAMINATION_RATE: 'examinationRate',
  EXAMINATION_AVERAGE_RESULT: 'examinationAverageResult',
  GROWTH_RATE: 'growthRate',
};

export const COURSE_GRADE_TYPE_REVERSE = {
  attendanceRate: 'ATTENDANCE_RATE',
  examinationRate: 'EXAMINATION_RATE',
  examinationAverageResult: 'EXAMINATION_AVERAGE_RESULT',
  growthRate: 'GROWTH_RATE',
};
