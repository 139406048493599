import React from 'react';
import { connect } from 'react-redux';
import { compose, withProps, mapProps, onlyUpdateForKeys } from 'recompose';
import { withRouter } from 'react-router-dom';

import { authVisibility } from 'Redux/menu/selectors';
import NavMenu from 'Components/NavMenu';
import { withAuth, AUTH_STATE } from 'Libs/Auth';

import AuthButton from '../AuthButton';

const isPublicMenu = ({ private: privateMenu }) => !privateMenu;
const isShownMenu = ({ hideMenu }) => !hideMenu;
const isAuthorizedRole = (menu, myRoles) => {
  const { roles: menuRoles } = menu;
  return (
    menuRoles &&
    (menuRoles.length === 0 ||
      (menuRoles.length > 0 && menuRoles.some(role => myRoles.includes(role))))
  );
};

const filterMenu = (menus = [], isLogin = false, myRoles = []) => {
  const visibleMenus = menus.filter(
    menu => isShownMenu(menu) && (isPublicMenu(menu) || isAuthorizedRole(menu, myRoles)),
  );
  return visibleMenus;
};

const MapperHOC = compose(
  withRouter,
  withAuth,
  connect(state => ({
    authVisibility: authVisibility(state),
  })),
  withProps(({ menus, auth }) => {
    const { authState, myRoles } = auth;
    return {
      rendermenus: filterMenu(menus, authState === AUTH_STATE.AUTHENTICATED, myRoles) || [],
      authComponent: <AuthButton />,
    };
  }),
  mapProps(({ rendermenus, showAuth, authVisibility, ...restProps }) => ({
    ...restProps,
    showAuth: showAuth && authVisibility,
    menus: rendermenus,
  })),
  onlyUpdateForKeys(['menus', 'logoUrl', 'auth', 'showAuth']),
);

export default MapperHOC(NavMenu);
