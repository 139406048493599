import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Text } from 'Components/Base';
import Table from 'Components/Table';

import PracQuizBox from '../PracQuizBox';

const TextWrapper = styled(Box)`
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const QuizTableStyle = styled(Table)`
  & .MuiTableCell-sizeSmall {
    padding: 5px 15px;
  }
`;

const PracQuizTable = ({ data }) => {
  const { t } = useTranslation('dashboard');
  const columns = [
    {
      Header: <Text fontWeight="bold">{t('SUBJECT')}</Text>,
      accessor: 'subject.shortname',
      Cell: ({ cell: { value } }) => <TextWrapper>{value}</TextWrapper>,
    },
    {
      Header: (
        <Text fontWeight="bold" pl="56px">
          {t('PRACQUIZ')}
        </Text>
      ),
      accessor: 'pracQuizzes',
      Cell: ({ cell: { value } }) => (
        <Flex alignItems="center">
          {value.map(({ topicName, subsectionName, done, total, status }, idx) => (
            <PracQuizBox
              key={idx}
              topicName={topicName}
              subsectionName={subsectionName}
              done={done}
              total={total}
              status={status}
            />
          ))}
        </Flex>
      ),
    },
  ];
  return <QuizTableStyle dense stickyHeader columns={columns} data={data} />;
};

PracQuizTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default PracQuizTable;
